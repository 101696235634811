import React, { useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';

function PeopleFilters(props) {
    const [category, setCategory] = useState('');
    const [title, setTitle] = useState('');
    const [country, setCountry] = useState('')
    const [linkedin, setLinkedin] = useState(false);
    const [phone, setPhone] = useState(false);
    const [email, setEmail] = useState(false);
    const [clearAllVisible, setClearAllVisible] = useState(false);

    function handleCategoryChange(event) {
        setCategory(event.target.value);
    };

    function handleTitleChange(event) {
        setTitle(event.target.value);
    };

    function handleCountryChange(event) {
        setCountry(event.target.value);
    };

    function handleLinkedinChange(event) {
        setLinkedin(event.target.checked);
    };

    function handlePhoneChange(event) {
        setPhone(event.target.checked);
    };

    function handleEmailChange(event) {
        setEmail(event.target.checked);
    };

    function resetFilterModel() {
        if (category !== '') setCategory('');
        if (title !== '') setTitle('');
        if (country !== '') setCountry('');
        if (linkedin) setLinkedin(false);
        if (phone) setPhone(false);
        if (email) setEmail(false);
        setClearAllVisible(false);
    }

    useEffect(() => {
        var filterModel = {};
        if (category !== '') {
            filterModel.ucategory = {};
            filterModel.ucategory.filterType = "text";
            filterModel.ucategory.type = "contains";
            filterModel.ucategory.filter = category;
        };
        if (title !== '') {
            filterModel.p_role = {};
            filterModel.p_role.filterType = "text";
            filterModel.p_role.type = "contains";
            filterModel.p_role.filter = title;
        };
        if (country !== '') {
            filterModel.countryname = {};
            filterModel.countryname.filterType = "text";
            filterModel.countryname.type = "contains";
            filterModel.countryname.filter = country;
        };
        if (linkedin) {
            filterModel.p_linkedin = {};
            filterModel.p_linkedin.filterType = "text";
            filterModel.p_linkedin.type = "notBlank";
        };
        if (phone) {
            filterModel.p_phone = {};
            filterModel.p_phone.filterType = "text";
            filterModel.p_phone.type = "notBlank";
        };
        if (email) {
            filterModel.p_email = {};
            filterModel.p_email.filterType = "text";
            filterModel.p_email.type = "notBlank";
        };
        props.ppf(filterModel);
        setClearAllVisible(Object.keys(filterModel).length > 0);
    }, [category, title, country, linkedin, phone, email])

    return (
        <div className="p-2 border border-gray shadow fs-6">
            <div className={`w-100 justify-content-end d-flex ${clearAllVisible ? 'd-flex' : 'd-none'}`} style={{color: "#ff3f6c", fontWeight: "700", fontSize: "13px", cursor: "pointer"}} onClick={resetFilterModel}>CLEAR ALL</div>
            <Form id="frmPeople">
                <p>
                    <Form.Label id="lblCategory" for="ctlCategory">Category</Form.Label>
                    <Form.Control id="ctlCategory" className="border border-primary" type="text" value={category} onChange={handleCategoryChange}></Form.Control>
                </p>
                <p>
                    <Form.Label id="lblTitle" for="ctlTitle">Title</Form.Label>
                    <Form.Control id="ctlTitle" className="border border-primary" type="text" value={title} onChange={handleTitleChange}></Form.Control>
                </p>
                <p>
                    <Form.Label id="lblCountry" for="ctlCountry">Country</Form.Label>
                    <Form.Control id="ctlCountry" className="border border-primary" type="text" value={country} onChange={handleCountryChange}></Form.Control>
                </p>
                <p>
                    <Form.Check>
                        <Form.Check.Input id="ctlLinkedin" className="border border-primary" type="checkbox" checked={linkedin} onChange={handleLinkedinChange} />
                        <Form.Check.Label id="lblLinkedin" for="ctlLinkedin">LinkedIn</Form.Check.Label>
                    </Form.Check>
                </p>
                <p>
                    <Form.Check>
                        <Form.Check.Input id="ctlPhone" className="border border-primary" type="checkbox" checked={phone} onChange={handlePhoneChange} />
                        <Form.Check.Label id="lblPhone" for="ctlPhone">Phone</Form.Check.Label>
                    </Form.Check>
                </p>
                <p>
                    <Form.Check>
                        <Form.Check.Input id="ctlEmail" className="border border-primary" type="checkbox" checked={email} onChange={handleEmailChange} />
                        <Form.Check.Label id="lblEmail" for="ctlEmail">Email</Form.Check.Label>
                    </Form.Check>
                </p>
            </Form>
        </div>
    )
}

export default PeopleFilters;