import React, { Component } from 'react';
import axios from 'axios';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import Modal from 'react-bootstrap/Modal';
import Chart from "react-apexcharts";
import SplitPane from 'split-pane-react';
import 'split-pane-react/esm/themes/default.css';

import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import "flag-icons/css/flag-icons.min.css";
import './leads.css';

import JobDetails from './jobdetails';
import * as consts from '../consts';
import ShareJob from './sharejob';
import LeafletMaps from './maps';

const defaultColDef = {
  filter: true,
  sortable: true,
  resizable: true,
  enableRowGroup: true,
};

const autoGroupColumnDef = {
  pinned: 'left', // Pin the group column to the left
}

const defaultSideBar = {
toolPanels: [
  {
    id: 'filters',
    labelDefault: 'Filters',
    labelKey: 'filters',
    iconKey: 'filter',
    toolPanel: 'agFiltersToolPanel',
    toolPanelParams: {
      suppressExpandAll: true,
      suppressFilterSearch: true,
    }
  },
  {
    id: 'columns',
    labelDefault: 'Columns',
    labelKey: 'columns',
    iconKey: 'column',
    toolPanel: 'agColumnsToolPanel',
    toolPanelParams: {
      suppressExpandAll: true,
      suppressFilterSearch: true,
      suppressPivotMode: true
    },
  },
  
],
};
const rowSelection = 'single';
const rowGroupPanelShow = 'onlyWhenGrouping';

const rowModelType = 'serverSide';
const cacheBlockSize = 50;
class Jobs extends Component {
  constructor(props) {
    super(props);

    this.state = {
        jobs: [],
        selectedJob: 0,
        showJobDetails: false,
        isShareJobsModalOpen : false,
        showMap: false,
        isGridView: false,
        gsplit: [`0%`, 'auto'],
        updatingRowData: false,
        columnDefs: [],
        gridApi: null,
        /* aggFuncs: {
          top10Count: (params) => {
            let count = 0;
            params.values.forEach((value) => {
                count += 1;
            })
            // if (count < 100) {count = 0}
            return count;
          },
        }, */
        lastVisibleCard: 0,
        showPauseStop: false,
      };

    this.jobSelectionChanged = this.jobSelectionChanged.bind(this);
    this.fetchData = this.fetchData.bind(this);
    this.getStatus = this.getStatus.bind(this);
    this.getStatusBg = this.getStatusBg.bind(this);
    this.setSelectedJob = this.setSelectedJob.bind(this);
    this.switchToList = this.switchToList.bind(this);
    this.resumeCrawl = this.resumeCrawl.bind(this);
    this.pauseCrawl = this.pauseCrawl.bind(this);
    this.abortCrawl = this.abortCrawl.bind(this);
    this.showJobDetails = this.showJobDetails.bind(this);
    this.hideJobDetails = this.hideJobDetails.bind(this);
    this.openShareJobModal = this.openShareJobModal.bind(this);
    this.closeShareJobModal = this.closeShareJobModal.bind(this);
    this.getTreemapOptions = this.getTreemapOptions.bind(this);
    this.getChartOptions = this.getChartOptions.bind(this);
    this.getChartTotal = this.getChartTotal.bind(this);
    this.getPercentage = this.getPercentage.bind(this);
    this.deleteJob = this.deleteJob.bind(this);
    this.showMap = this.showMap.bind(this);
    this.hideMap = this.hideMap.bind(this);
    this.unlockAllClicked=this.unlockAllClicked.bind(this);
    this.unlockAllLeads=this.unlockAllLeads.bind(this);
    this.setJobDetails = this.setJobDetails.bind(this);
    this.stopCrawl = this.stopCrawl.bind(this);
    this.hidePauseStop = this.hidePauseStop.bind(this);

    this.onGridReady = this.onGridReady.bind(this);
    this.onGridRefresh = this.onGridRefresh.bind(this);
    this.onSelectionChanged = this.onSelectionChanged.bind(this);
    this.onRowDataUpdated = this.onRowDataUpdated.bind(this);
    this.onModelUpdated = this.onModelUpdated.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.onStoreRefreshed = this.onStoreRefreshed.bind(this);
    this.getChildCount = this.getChildCount.bind(this);
    this.isServerSideGroupOpenByDefault = this.isServerSideGroupOpenByDefault.bind(this);
    // this.getRowId = this.getRowId.bind(this);
    this.getRows = this.getRows.bind(this);
    this.toggleView = this.toggleView.bind(this);
    this.refreshCards = this.refreshCards.bind(this);
    this.handleCardsScroll = this.handleCardsScroll.bind(this);
    this.agRequestRef = React.createRef();
  };

  onGridReady(params) {
    this.setState({
        gridApi: params.api,
    })
    params.api.setServerSideDatasource({getRows: this.getRows});
  }

  onGridRefresh(params) {
    // this.state.gridApi.setServerSideDatasource({getRows: this.getRows});
  }

  isServerSideGroupOpenByDefault(params) {
    var rowNode = params.rowNode;

    return (rowNode.group && rowNode.uiLevel === 0)
  }

  // getRowId(params) {
  //   return params.data.id;
  // }

  getChildCount(data) {
    return data ? data.childCount : undefined
  }
  
  onSelectionChanged() {
    if (this.state.isGridView) {
      const data = this.state.gridApi.getSelectedRows();
      const jobId = data ? data[0].id : 0;

      const index = Math.max(this.state.jobs.findIndex(row => (row.id === jobId)), 0);
      if (this.state.selectedJob !== jobId)
        this.setState({ selectedJob: jobId });

      this.jobSelectionChanged(index);
    }
  }

  onModelUpdated = (params) => {
    this.setState({ updatingRowData: false });
    this.props.ujd(false);
    this.refreshCards();
  }

  onStoreRefreshed = (params) => {
    this.setState({ updatingRowData: false });
    this.props.ujd(false);
  }

  onRowDataUpdated = (params) => {
    // onRowDataUpdated works only with client side data model
    // this.refreshCards();
  }

  onFirstDataRendered = (params) => {
    var cols = [
      { field: 'id', headerName: 'ID', filter: 'agNumberColumnFilter', width: 100, cellStyle: {textAlign: 'center'}, pinned: 'left' },
      { field: 'name', headerName: 'Name', filter: 'agTextColumnFilter', pinned: 'left' },
      { field: 'status_message', headerName: 'Status', filter: 'agTextColumnFilter', hide: false, width: 150, cellStyle: {textAlign: 'center'} },
      { field: 'catnames', headerName: 'Categories', filter: 'agTextColumnFilter' },
      { field: 'keywords', headerName: 'Keywords', filter: 'agTextColumnFilter' },
      { field: 'locgroupname', headerName: 'Location Group', filter: 'agTextColumnFilter' },
      { field: 'countryname', headerName: 'Country', filter: 'agTextColumnFilter' },
      { field: 'isstateall', headerName: 'All States', filter: 'agTextColumnFilter', cellStyle: {textAlign: 'center'} },
      { field: 'statename', headerName: 'State', filter: 'agTextColumnFilter' },
      { field: 'iscountyall', headerName: 'All Counties', filter: 'agTextColumnFilter', cellStyle: {textAlign: 'center'} },
      { field: 'countyname', headerName: 'County', filter: 'agTextColumnFilter' },
      { field: 'ispcodeall', headerName: 'All Postal Codes', filter: 'agTextColumnFilter', cellStyle: {textAlign: 'center'} },
      { field: 'pcodenames', headerName: 'Postal Codes', filter: 'agTextColumnFilter' },
      { field: 'pcodecount', headerName: 'Crawl Search Scope', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'center'} },
      { field: 'pcodescrawled', headerName: 'Combinations Crawled', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'center'} },
      { field: 'leadsfound', headerName: 'Leads Found', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'center'} },
      { field: 'leadscrawled', headerName: 'Websites Scanned', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'center'} },
      { field: 'lockedcount', headerName: 'Leads Locked', filter: 'agNumberColumnFilter', cellStyle: {textAlign: 'center'} },
    ];
    this.setState({columnDefs: cols})
  }

  getRows(params) {
    const serverURL = `/api/getagjobs/${this.props.uid}`;
    this.agRequestRef.current = params.request;

    this.setState({ updatingRowData: true });
    this.props.ujd(true);
    fetch(serverURL, {
      method: 'post',
      body: JSON.stringify(params.request),
      headers: {"Content-Type": "application/json; charset=utf-8" }
    })
    .then(httpResponse => httpResponse.json())
    .then(response => {
      params.successCallback(response.rows, response.lastRow);
      this.setState({ updatingRowData: false });
      this.props.ujd(false);
    })
    .catch(error => {
      console.error(error);
      params.failCallback();
    })
  }

  toggleView(currentView) {
    this.setState({
      isGridView: currentView,
      gsplit: currentView ? ["100%", 'auto'] : ["0%", 'auto']
    })
    this.refreshCards();
  }

  refreshCards() {
    var cards = [];
    // forEachNodeAfterFilter works only with client side data model
    // this.state.gridApi && this.state.gridApi.forEachNodeAfterFilter((node, index) => {if (!node.group) cards.push(node.data)});
    this.state.gridApi && this.state.gridApi.forEachNode((node, index) => {
      if ((!node.group) && (node.data)) {cards.push(node.data)};
      if (index === 0) {node.setSelected(true)};
    });
    if (cards.length > 0) {
      const index = Math.max(cards.findIndex(row => (row.id === this.state.selectedJob)), 0);
      const jobId = cards[index].id;
      if (this.state.selectedJob !== jobId)
        this.setState({ selectedJob: jobId, lastVisibleCard: jobId });

      this.setState({jobs: cards}, () => {
        this.jobSelectionChanged(index);

        if (this.props.ajid > 0) {
          this.state.gridApi?.ensureIndexVisible(0, 'top')
        }
        const card = document.getElementById(`job-${this.state.lastVisibleCard}`);
        card?.scrollIntoView(false);
      })
    }
  }
 
  handleCardsScroll(e) {
    const cardsList = e.currentTarget;
    const top = (cardsList.scrollTop === 0);
    const bottom = (Math.abs(cardsList.scrollHeight - (cardsList.scrollTop + cardsList.clientHeight)) <= 5);
    if (top) {
      // this.state.gridApi?.ensureIndexVisible(0, 'top')
    } else if (bottom) {
      const index = this.state.jobs.length;
      this.state.gridApi?.ensureIndexVisible(index, 'bottom');
      const lastVisibleIndex = Math.max(0, index - 1);
      const lastVisibleCard = this.state.jobs[lastVisibleIndex].id;
      this.setState({ lastVisibleCard: lastVisibleCard });
    } else {
      this.setState({ lastVisibleCard: 0 });
    }
  }

  setJobDetails(row) {
    const jid = row ? row.id : 0;
    const jname = row ? row.name : '';
    const jstatus = row ? row.status : 0;
    const jowner = row ? (row.ownerid === row.userid) : false;
    this.setState({ selectedJob: jid, lastVisibleCard: jid });
    this.props.sju(jid);
    this.props.ssjn(jname);
    this.props.ujs(jstatus);
    this.props.ujo(jowner);
  };

  async fetchData() {
    // this.state.gridApi?.setServerSideDatasource({getRows: this.getRows});
    this.state.gridApi?.refreshServerSide([]);
  }

  async jobSelectionChanged(index) {
    const row = this.state.jobs[index];
    this.setJobDetails(row);

    this.props.setFileName(row.name)

    if ((row.status !== consts.CRAWL_IN_PROGRESS) && (row.lockedcount > 0))
      this.props.setUnlockAllLeadsBtnEnabled(true)
    else 
      this.props.setUnlockAllLeadsBtnEnabled(false)
  }

  componentDidMount() {
    this.fetchData();
  }

  getStatus(status) {
    const statusTxt = [consts.CRAWL_TXT_NOT_STARTED, consts.CRAWL_TXT_IN_PROGRESS, consts.CRAWL_TXT_COMPLETED, consts.CRAWL_TXT_ABORTED, consts.CRAWL_TXT_PAUSED];
    return statusTxt[status]
  }

  getStatusBg(id, status) {
    const statusId = (id === this.props.ajid) ? 1 : status;
    const statusBg = ['primary', 'primary', 'success', 'danger', 'warning'];
    return statusBg[statusId]
  }

  setSelectedJob(jid) {
    this.setState({ selectedJob: jid })
  }

  switchToList() {
    this.props.stl();
  }

  resumeCrawl(index) {
    this.jobSelectionChanged(index)
    .then ( 
      this.props.urc(true)
    )
  }

  pauseCrawl() {
    this.hidePauseStop();
    this.props.upc(true)
  }

  abortCrawl(index) {
    this.hidePauseStop();
    this.props.uac(true) 
  }
    
  hidePauseStop() {
    this.setState({showPauseStop: false});
  }

  stopCrawl(index) {
    this.jobSelectionChanged(index)
    .then (
      this.setState({showPauseStop: true})
    )
  }

  showJobDetails() {
    this.setState({ showJobDetails: true });
  }

  hideJobDetails() {
    this.setState({ showJobDetails: false })
  }
  
  openShareJobModal() {
    this.setState({ isShareJobsModalOpen: true })
  }

  closeShareJobModal() {
    this.setState({ isShareJobsModalOpen: false })
  }

  getTreemapOptions(index) {
    let chartOptions = {
      chart: {
        type: 'treemap',
        toolbar: {
          show: false,
        },
      },
      title: {
        text: `Leads Found: ${this.getChartTotal(index)}`,
        align: 'center',
        margin: 20,
        floating: true,
        style: {
          fontSize: '16px',
          fontWeight: 500,
          fontFamily: 'var(--font-family-sans-serif)',
          color: 'var(--bs-primary)',
        }
      },
      legend: {
        show: false,
        position: 'bottom',
        horizontalAlign: 'center',
      },
      // colors: ['#3B93A5','#F7B844','#ADD8C7','#EC3C65','#CDD7B6','#C1F666','#D43F97','#1E5D8C','#421243','#7F94B0','#EF6537','#C0ADDB'],
      dataLabels: {
        enabled: true,
        formatter: function (text, op) {
          return [text, op.value]
        },
      },
      plotOptions: {
        treemap: {
          distributed: true,
          enableShades: false,
        }
      },
      tooltip: {
        enabled: true,
      },
    }

    return chartOptions
  }

  getChartOptions(index) {
    let chartOptions = {
      chart: {
        type: "radialBar",
      },
      plotOptions: {
        radialBar: {
          offsetY: 0,
          startAngle: 0,
          endAngle: 270,
          hollow: {
            margin: 5,
            size: "40%",
            background: "var(--list-card-bg)",
          },
          track: {
            show: true,
            background: 'var(--list-card-s0)',
            margin: 3,
          },
          dataLabels: {
            name: {
              show: false,
              offsetY: 0,
              fontSize: '12px',
              color: "black",
            },
            value: {
              show: true,
              offsetY: 6,
              fontSize: '20px',
              color: "black",
              // formatter: function(val) {
              //   const row = this.state.jobs[index];
              //   return `${row.leadscrawled} / ${row.leadsfound}`
              // }.bind(this),
            },
            total: {
              show: true,
              label: '',
              formatter: function (w) {
                return this.getChartTotal(index)
              }.bind(this),
            }
          }
        },
      },
      colors: ['var(--list-card-s1)', 'var(--list-card-s2)', 'var(--list-card-s3)'],
      labels: ['Crawl Scope', `${this.props.ajsp}`, 'Site Scan'],
      legend: {
        show: true,
        floating: true,
        fontSize: '11px',
        position: 'left',
        offsetX: -35,
        offsetY: -3,
        labels: {
          useSeriesColors: true,
        },
        itemMargin: {
          vertical: -1
        },
        markers: {
          width: 0,
          height: 0,
          size:0
        },
        formatter: function (seriesName, opts) {
          const placeNameMaxWidth = 8;
          const seriesIndex = opts.seriesIndex;
          const seriesValue = opts.w.globals.series[opts.seriesIndex];
          const displayValue = (seriesValue > 0) ? `(${seriesValue}%)` : ``;
          const displayName = ((seriesIndex === 1) && (seriesName.length > placeNameMaxWidth)) ? seriesName.substring(0, (placeNameMaxWidth - 2)).concat('...') : seriesName;
          return ((seriesName === 'Completed') || (seriesValue < 0)) ? "" : `<span title="${seriesName}">${displayName} ${displayValue}</span`;
        },
        onItemClick: {
          toggleDataSeries: false
        },
      },
    };

    return chartOptions
  }

  getChartTotal(index) {
    const row = this.state.jobs[index];
    return (row ? (row.id === this.props.ajid) ? this.props.ajlu : row.leadsfound : 0)
  }

  getPercentage(value, target) {
    const result = value * 100 / Math.max(target, 1);
    
    return ((result > 0) ? ((result < 1) ? Math.round(result * 100) / 100 : Math.round(result)) : 0)
  }

  async deleteJob(e) {
    const index = e.currentTarget.dataset.id;
    const row = this.state.jobs[index];
    const jobId = row ? row.id : 0;

    if (window.confirm('Are you sure you want to delete this list?')) {
      await axios.get(`/deletejob/${jobId}`)
        .then(res => {
          var newJobs = this.state.jobs;
          newJobs.splice(index, 1);
          this.setState({ jobs: newJobs });
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  showMap() {
    this.setState({ showMap: true });
  }

  hideMap() {
    this.setState({ showMap: false })
  }

  async unlockAllClicked(jobId,lockedLeadCount){
    try{
        const request={url:`/usercredits/${this.props.uid}`,method:'GET'}
        const usercredits=(await axios(request)).data.noofcredits

          if(usercredits>=lockedLeadCount)
          {
            const res=window.confirm(`Are you sure you want to unlock ${lockedLeadCount} leads in selected list?`)
            if(res)
            {
              this.unlockAllLeads(jobId,this.props.uid)
              .then(()=>{
                const elem_jobLock=document.getElementById(`btn-lock-${jobId}`)
                elem_jobLock.className += ' d-none';
                
              })
              .catch((err)=>{
                console.log(err)
              })
            }
          }
          else alert(`Selected list has ${lockedLeadCount} locked leads. And you have ${usercredits} credits. Required ${lockedLeadCount-usercredits} more credits to unlock this list`)
        
    }
    catch(exc)
    {
      console.log(exc)
    }
  }

  async unlockAllLeads(jobId,userId){
    const request={
      url:'/unlockallleads',
      method:'POST',
      data:{jobId,userId},
    }
    return axios(request)
  }

  render() {
    return (
      <React.Fragment>
        <SplitPane split="horizontal" allowResize={false} sizes={this.state.gsplit} resizerSize={5} onChange={(data) => this.setState({gsplit: data})}>
          <div className="ag-theme-balham" style={{height: '100%'}}>
            <AgGridReact 
              columnDefs={this.state.columnDefs}
              defaultColDef={defaultColDef}
              autoGroupColumnDef={autoGroupColumnDef}
              sideBar={defaultSideBar}
              animateRows={true}
              rowMultiSelectWithClick={false}
              rowSelection={rowSelection}
              rowGroupPanelShow={rowGroupPanelShow}
              onGridReady={this.onGridReady}
              onGridRefresh={this.onGridRefresh}
              onSelectionChanged={this.onSelectionChanged}
              onRowDataUpdated={this.onRowDataUpdated}
              onModelUpdated={this.onModelUpdated}
              onFirstDataRendered={this.onFirstDataRendered}
              onStoreRefreshed={this.onStoreRefreshed}
              // aggFuncs={this.state.aggFuncs}
              rowModelType={rowModelType}
              getChildCount={this.getChildCount}
              cacheBlockSize={cacheBlockSize}
              // isServerSideGroupOpenByDefault={this.isServerSideGroupOpenByDefault}
              alwaysShowHorizontalScroll={false}
              alwaysShowVerticalScroll={false}
              // getRowId={this.getRowId}
            ></AgGridReact>
          </div>
          <div className={`h-100 overflow-auto d-flex flex-wrap`} onScroll={this.handleCardsScroll}>
          {this.state.jobs.map((row, index) => (
            <Col xs={2} key={index} className='m-1' style={{width: '100%', minWidth: '199px', maxWidth: 'calc(14% - 4px'}}>
            <Card 
              id={`job-${index}`}
              key={index} 
              title={row.name}
              onClick={() => this.jobSelectionChanged(index)}
              className={`text-primary ${(row.id === this.state.selectedJob) ? `border-${this.getStatusBg(row.id, row.status)} shadow-lg` : ''}`} 
            >
              <Card.Header className={`fs-6 text-white bg-${this.getStatusBg(row.id, row.status)} p-0 align-items-center`}>
                <div className="d-flex flex-row">
                  <div className="p-2">
                    <span>{`List #${row.userid}00${row.id}`}</span>
                  </div>
                  <div className="my-auto">
                    <i className={`text-white fa fa-cog fa-spin ${(row.id === this.props.ajid) ? 'd-flex' : 'd-none'}`}></i>
                  </div>
                  <div className="ms-auto my-auto">
                    <Button id={`btn-sc-${index}`} title="Stop Crawl" size="sm" variant={`${this.getStatusBg(row.id, row.status)}`} className={`bg-gradient ${(row.id === this.props.ajid) ? 'visible' : 'd-none'}`} onClick={() => this.stopCrawl(index)}>
                      <i className="text-white bi bi-stop-fill"></i>
                    </Button>
                    <Button id={`btn-rc-${index}`} title="Resume Crawl" size="sm" variant={`${this.getStatusBg(row.id, row.status)}`} className={`bg-gradient ${((row.id !== this.props.ajid) && (row.status === consts.CRAWL_PAUSED)) ? 'visible' : 'd-none'}`} onClick={() => this.resumeCrawl(index)} disabled={(this.props.ajid > 0)}>
                      <i className="text-white bi bi-play-fill"></i>
                    </Button>
                    <Button id={`btn--${row.id}`} title="Switch to Leads" size="sm" variant={`${this.getStatusBg(row.id, row.status)}`} className={`me-1 bg-gradient`} onClick={this.switchToList}>
                      <i className="text-white bi bi-folder-symlink"></i>
                    </Button>
                  </div>
                </div>
              </Card.Header>
              <Card.Body className='p-2 d-flex align-content-end flex-column'>
                <Card.Text>
                  <Row><Col className='fs-6'>{row.name}</Col></Row>
                </Card.Text>
                <div className={`m-0 p-0 ${(row.id === this.props.ajid) || (row.leadsfound === 0) || [2,3].includes(row.status) ? '' : 'flipcard'}`}>
                  <div className={`m-0 p-0 ${(row.id === this.props.ajid) || (row.leadsfound === 0) || [2,3].includes(row.status) ? '' : 'flipcard-inner'}`}>
                    <Card.Text className={`m-0 p-0 ${(row.id === this.props.ajid) || (row.leadsfound === 0) || [2,3].includes(row.status) ? '' : `flipcard-${(row.id === this.state.selectedJob) ? 'front' : 'back'}`}`} style={{backgroundColor: "var(--list-card-bg)"}}>
                      {([2,3].includes(row.status)) ? '' : (
                      <Chart 
                        id={`rb-${row.id}`}
                        title=""
                        type="radialBar" 
                        height={"230px"} 
                        options={this.getChartOptions(index)} 
                        series={[((row.id === this.props.ajid) ? this.props.ajpp : this.getPercentage(row.pcodescrawled, row.pcodecount)), 
                                ((row.id === this.props.ajid) ? this.props.ajlp : -1), 
                                 ((row.id === this.props.ajid) ? this.props.ajls : this.getPercentage(row.leadscrawled, row.leadsfound))]} 
                      />
                      )}
                    </Card.Text>
                    {([2,3].includes(row.status)) && (row.leadsfound === 0) ? (
                    <div className="d-flex" style={{height: "210px", backgroundColor: "var(--list-card-bg)"}}>
                      <span className="mx-auto my-auto text-secondary fs-5">No Leads Found</span>
                    </div>
                    ) : ''}
                    {([2,3,4].includes(row.status) && (row.id !== this.props.ajid)) && (row.leadsfound !== 0) ? (
                    <Card.Text className={[2,3].includes(row.status) ? `` : `flipcard-${(row.id === this.state.selectedJob) ? 'back' : 'front'} m-0 p-0`} style={{backgroundColor: "var(--list-card-bg)"}}>
                      <Chart 
                        id={`tm-${row.id}`}
                        title=""
                        type="treemap" 
                        height={"195px"} 
                        options={this.getTreemapOptions(index)} 
                        series={[{data: JSON.parse(row.catcounts)?.map((c) => {return {x: c.category, y: c.count}})}]} 
                      />
                    </Card.Text>
                    ) : ''}
                  </div>
                </div>
                <Card.Text className={`m-0 p-0`}>
                  <Row className={`w-100 mx-0 my-1`}>
                    <Col className="p-0 d-flex">
                      <Button id={`btn-ch-${index}`} title="Crawl History" size="sm" variant="outline-primary" style={{borderRadius: "50%"}} className="me-1 border border-gray" onClick={this.showJobDetails}><i className="bi bi-clock-history"></i></Button>
                      {(row.ownerid === row.userid) ? (
                      <Button id={`btn-sl-${index}`} title="Share List" size="sm" variant="outline-primary" style={{borderRadius: "50%"}} className="me-1 border border-gray" onClick={this.openShareJobModal}><i className="bi bi-share"></i></Button>
                      ) : ''}
                      <Button id={`btn-mp-${index}`} title="Map" size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={this.showMap} > <i className="bi bi-globe-americas"></i></Button>
                      <Button id={`btn-lock-${row.id}`} title="Unlock List" size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className={`me-1 border border-gray ${(row.status !== consts.CRAWL_IN_PROGRESS&&row.lockedcount>0)?'visible':'d-none'}`} onClick={()=>this.unlockAllClicked(row.id,row.lockedcount)} > <i className="bi bi-unlock"></i></Button>
                      {(row.ownerid === row.userid) ? (
                      <Button id={`btn-dl-${index}`} data-id={index} title="Delete List" size="sm" variant="outline-primary" style={{borderRadius: "50%"}} className="ms-auto border border-gray" onClick={this.deleteJob} disabled={(row.id === this.props.ajid)}><i className="bi bi-trash"></i></Button>
                      ) : 
                      <Button id={`btn-dl-${index}`} data-id={index} title={`Shared by ${row.u_name_email}`} size="sm" variant="outline-primary" style={{borderRadius: "50%"}} className="ms-auto border-0"><i className="bi bi-people-fill"></i></Button>
                      }
                      </Col>
                  </Row>
                </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </div>
        </SplitPane>        
        <Modal show={this.state.showJobDetails} onHide={this.hideJobDetails} centered size="lg">
          <Modal.Header closeButton={true} >
            <div className="fs-6">List #{this.props.uid}00{this.state.selectedJob}</div>
          </Modal.Header>
          <Modal.Body className="m-1 p-0">
            <JobDetails jid={this.state.selectedJob} />
          </Modal.Body>
        </Modal>
        <div>
          <ShareJob isOpen={this.state.isShareJobsModalOpen} onClose={this.closeShareJobModal} job={this.state.selectedJob} loggedInUserId={this.props.uid} />
        </div>
        <div>
        <Modal show={this.state.showMap} onHide={this.hideMap} centered size='xl'>
          <Modal.Header closeButton={true} >
            <div className="fs-6">Leads - Geographical Distribution</div>
          </Modal.Header>
          <Modal.Body className="m-1 p-0" style={{height: "480px"}}>
            <LeafletMaps job={this.state.selectedJob} ajlu={this.props.ajlu} containerId="modalMapContainer" />
          </Modal.Body>
        </Modal>
        <Modal show={this.state.showPauseStop} onHide={this.hidePauseStop} centered>
          <Modal.Body className='p-2 shadow'>
            <div className='d-flex flex-column m-2'>
              <div className='fs-6 fw-bold mb-4'>
                Do you really want to stop the crawl?
              </div>
              <div className='p-2 d-flex justify-content-end'>
                <Button className='mx-1' variant="secondary" onClick={this.hidePauseStop}>
                  Cancel
                </Button>
                <Button className={`mx-1 ${(this.state.selectedJob === 0) ? 'disabled' : ''}  'd-flex'`} variant="warning" onClick={this.pauseCrawl}>
                  Pause
                </Button>
                <Button className={`mx-1 ${(this.state.selectedJob === 0) ? 'disabled' : ''}`} variant="danger" onClick={this.abortCrawl}>
                  Stop
                </Button>
              </div>
            </div>
          </Modal.Body>
        </Modal>
        </div>
      </React.Fragment>
    );
  }
}

export default Jobs;