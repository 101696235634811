import React, { useState } from 'react';
import SplitPane from 'split-pane-react';
import 'bootstrap/dist/css/bootstrap.min.css';
import {Button} from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';   
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import './campaigns.css';
import { BrowserRouter as Router, Routes, Route, Link, Outlet} from 'react-router-dom';
import Container from 'react-bootstrap/Container';

function CampaignsMain(props){

    const SIDE_MIN_WIDTH = '40px';
    const SIDE_MAX_WIDTH = '240px';

    const [showSidebar, setShowSidebar] = useState(true);
    const toggleSidebar = () => { 
      setShowSidebar(!showSidebar); 
      setSbsplit(showSidebar ? [SIDE_MIN_WIDTH, 'auto'] : [SIDE_MAX_WIDTH, 'auto']);
    };

    const [sbsplit, setSbsplit] = useState([SIDE_MAX_WIDTH, 'auto']);

    return <>
    <SplitPane allowResize={false} sizes={sbsplit} onChange={setSbsplit}>
        <div className='w-100 border shadow'>
          <div className='w-100 p-2 d-flex align-items-center bg-primary text-white shadow' style={{ height: '40px' }}>
            <span className={`w-100 fs-5 fw-bold`}>{`${showSidebar ? 'Campaigns' : ''}`}</span>
            <Button size="lg" className={`m-0 p-0 border border-primary`} onClick={toggleSidebar}>
              <i className={`bi ${showSidebar ? 'bi-caret-left-fill' : 'bi-caret-right-fill'}`}></i>
            </Button>
          </div>
          <div className={`w-100 p-2 side-panel overflow-auto ${showSidebar ? 'visible' : 'invisible'}`}>

          {/* <Nav defaultActiveKey="/form-filling" className="flex-column">
            <Nav.Link href="/form-filling">Form Filling</Nav.Link>
            <NavDropdown title="Email Campaign" id="nav-dropdown">
              <NavDropdown.Item eventKey="4.3">SMTP Credentials</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.1">Mail Templates</NavDropdown.Item>
              <NavDropdown.Item eventKey="4.2">Mail Campaigns</NavDropdown.Item>
            </NavDropdown>
          </Nav> */}

          <Accordion defaultActiveKey={['0','1']} alwaysOpen>
            {
              (props.loggedInUserPlan?.id==1||props.user?.isformcampaigns==1)?<Accordion.Item eventKey="0">
              <Accordion.Header >Auto Form Filling</Accordion.Header>
              <Accordion.Body style={{padding:'0px'}}>
                <Nav defaultActiveKey="form-templates" className="flex-column">
                  <Link to={'/campaigns/form-templates'} className={`nav-link active`}>Form Templates</Link>
                  <Link to={'/campaigns/form-campaigns'} className={`nav-link`}>Form Campaigns</Link>
                </Nav>
              </Accordion.Body>
            </Accordion.Item>:<></>
            }
            
            {
              (props.loggedInUserPlan?.id==1||props.user?.isemailcampaigns==1)?<Accordion.Item eventKey="1">
              <Accordion.Header>Email Campaign</Accordion.Header>
              <Accordion.Body style={{padding:'0px'}}>
                <Nav defaultActiveKey="mail-campaigns" className="flex-column">
                    <Link to={'/campaigns/smtp-credentials'} className={`nav-link`}>SMTP Credentials</Link>
                    <Link to={'/campaigns/mail-templates'} className={`nav-link`}>Email Templates</Link>
                    <Link to={'/campaigns/email-campaigns'} className={`nav-link`}>Email Campaigns</Link>
                    {/* <Nav.Link eventKey="mail-campaigns">Unsubscribed List</Nav.Link>
                    <Nav.Link eventKey="mail-campaigns">Unsubscribe History</Nav.Link> */}
                </Nav>
              </Accordion.Body>
            </Accordion.Item>:<></>
            }
            
          </Accordion>

          </div>
        </div>
        <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
          <Container fluid className="p-0 m-0">
              <div className={`wrapper main-panel`}>
                <Outlet />
              </div>
            </Container>
        </div>
    </SplitPane>
    </>
}

export default CampaignsMain;

