import React, { Component } from 'react';
import axios from 'axios';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import * as consts from '../consts';
import 'ag-grid-enterprise';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-balham.css';
import { agMenuItem } from './agMenuItem';
import { AgGridReact } from 'ag-grid-react';

const rowSelection = 'single';
const rowGroupPanelShow = 'onlyWhenGrouping';
const rowModelType = 'serverSide';
const cacheBlockSize = 50;
 
const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    floatingFilter: false,
}

class JobDetails extends Component {
    constructor (props) {
      super(props);

      this.state = {
        jobData: [],
        jobDetails: [],
        placeColumnDefs: [],
        siteColumnDefs: [],
        placeGridApi: null,
        siteGridApi: null,
      }

      this.placeColumnDefs = [
        { field: "category", headerName: "Category/Keyword", filter: 'agTextColumnFilter' },
        { field: "placename", headerName: "Place", filter: 'agTextColumnFilter' },
        { field: "leadcount", headerName: "Leads Found", filter: 'agNumberColumnFilter', width: 110, cellStyle: {textAlign: 'right'} },
        { field: "starttime", headerName: "Start Time", filter: 'agDateColumnFilter'},
        { field: "endtime", headerName: "End Time", filter: 'agDateColumnFilter'},
        { field: "country_name", headerName: "Country", filter: 'agTextColumnFilter' },
        { field: "state_name", headerName: "State", filter: 'agTextColumnFilter' },
        { field: "county_name", headerName: "County", filter: 'agTextColumnFilter', },
        { field: "city_name", headerName: "Area Name", filter: 'agTextColumnFilter', },
        { field: "code", headerName: "Postal Code", filter: 'agTextColumnFilter', },
      ];

      this.siteColumnDefs = [
        { field: 'ucategory', headerName: 'Category', filter: 'agTextColumnFilter' },
        { field: "name", headerName: "Name", filter: 'agTextColumnFilter' },
        { field: 'tld', headerName: 'Website', filter: 'agTextColumnFilter', cellRenderer: this.tldRenderer },
        { field: 'iscrawlcomplete', headerName: 'Status', filter: 'agSetColumnFilter', filterParams: {values: [1, 0], valueFormatter: (params) => {return params.value ? "Completed" : "Pending"}}, width: 75, cellStyle: {textAlign: 'center'}, cellRenderer: this.statusRenderer },
        { field: 'countrycode', headerName: 'Country', filter: 'agTextColumnFilter', width: 100, cellStyle: {textAlign: 'center'}, cellRenderer: this.flagRenderer },
        { field: 'crawleddate', headerName: 'Scan Date', filter: 'agDateColumnFilter' },
      ];

      this.getMainMenuItems = this.getMainMenuItems.bind(this);
      this.clearFilter = this.clearFilter.bind(this);
      this.getJobData = this.getJobData.bind(this);
      this.getJobStatus = this.getJobStatus.bind(this);
      this.getChildCount = this.getChildCount.bind(this);

      this.tldRenderer = this.tldRenderer.bind(this);
      this.statusRenderer = this.statusRenderer.bind(this);
      this.flagRenderer = this.flagRenderer.bind(this);
      this.checkRenderer = this.checkRenderer.bind(this);

      this.getPlaceRows = this.getPlaceRows.bind(this);
      this.onPlaceGridReady = this.onPlaceGridReady.bind(this);

      this.getSiteRows = this.getSiteRows.bind(this);
      this.onSiteGridReady = this.onSiteGridReady.bind(this);
    }

    clearFilter(params) {
        params.api.getFilterInstance(params.column.getColId(), (filterInstance) => {
            filterInstance.setModel(null);
            params.api.onFilterChanged();
          });
    }

    getMainMenuItems(params) {
        const clearFilterItem = {
            name: "Clear Filter",
            icon: `<i class="bi bi-x"></i>`,
            menuItem: agMenuItem,
            disabled: (!params.column.isFilterActive()),
            action: () => {this.clearFilter(params)},
          };

        return [
            {
              name: "Clear Filter",
              icon: `<i class="bi bi-x"></i>`,
              menuItem: agMenuItem,
              disabled: (!params.column.isFilterActive()),
              action: () => {this.clearFilter(params)},
            },
            "separator",
            ...params.defaultItems,
        ];
    }

    tldRenderer (params) {
        return ((params.value && params.value !== '') ? (
            <a rel="noreferrer" target='_blank' href={`https://${params.value}`}>{params.value}</a>
        ) : '' )
    }

    statusRenderer (params) {
        return (<div className='mx-auto' style={{width: '18px', height:'18px'}}><i title={`Site Scan ${(params.value === 1) ? 'Completed' : 'Pending'}`} className={`bi ${(params.value === 1) ? 'bi-check-circle-fill' : 'bi-circle-half'} text-dark`}></i></div>)
    }

    flagRenderer (params) {
        return ((params.value && params.value !== '') ? <div title={params.data ? params.data.countryname : ''} className={`fi fi-${params.value.toLowerCase()}`}></div> : '')
    }  

    checkRenderer (params) {
        return (<div className='d-flex justify-content-center'><i className={`${(params.value && params.value === 1) ? 'bi bi-file-check' : ''}`}></i></div>)
    };

    async getJobData() {
        await axios.get(`/getjob/${this.props.jid}`)
        .then(res => {
          this.setState({ jobData: res.data })
        })
    }
    
    getJobStatus(status) {
        const txtStatus = [consts.CRAWL_TXT_NOT_STARTED, consts.CRAWL_TXT_IN_PROGRESS, consts.CRAWL_TXT_COMPLETED, consts.CRAWL_TXT_ABORTED, consts.CRAWL_TXT_PAUSED];
        return ((status >= 0) && (status < txtStatus.length)) ? txtStatus[status] : ''
    }

    getChildCount(data) {
        return data ? data.childCount : undefined
    }

    onPlaceGridReady(params) {
        this.setState({
            placeGridApi: params.api,
        })
        params.api.setServerSideDatasource({getRows: this.getPlaceRows});
    }

    getPlaceRows(params) {
        const serverURL = `/api/getagjobdetails/${this.props.jid}`;
    
        fetch(serverURL, {
          method: 'post',
          body: JSON.stringify(params.request),
          headers: {"Content-Type": "application/json; charset=utf-8" }
        })
        .then(httpResponse => httpResponse.json())
        .then(response => {
          params.successCallback(response.rows, response.lastRow);
        })
        .catch(error => {
          console.error(error);
          params.failCallback();
        })
    }

    onSiteGridReady(params) {
        this.setState({
            siteGridApi: params.api,
        })
        params.api.setServerSideDatasource({getRows: this.getSiteRows});
    }

    getSiteRows(params) {
        const serverURL = `/api/getagleads/${this.props.jid}/0`;
    
        fetch(serverURL, {
          method: 'post',
          body: JSON.stringify(params.request),
          headers: {"Content-Type": "application/json; charset=utf-8" }
        })
        .then(httpResponse => httpResponse.json())
        .then(response => {
          params.successCallback(response.rows, response.lastRow);
        })
        .catch(error => {
          console.error(error);
          params.failCallback();
        })
    }


    async componentDidMount() {
        this.getJobData();
    }

    getDuration(date1,date2){
        date1 = new Date(date1);
        date2 = new Date(date2);

        const diffInMs = Math.abs(date1 - date2); // Ensure positive duration

        const diffInSeconds = Math.floor(diffInMs / 1000);
        const hours = Math.floor(diffInSeconds / 3600);
        const minutes = Math.floor((diffInSeconds % 3600) / 60);
        const seconds = diffInSeconds % 60;

        const formattedDiff = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')} HH:mm:ss`;
        
        return formattedDiff;
    }

    render() {
        return (
            <React.Fragment>
                <Tab.Container defaultActiveKey="placescan">
                    <Nav variant='tabs'>
                        <Nav.Item><Nav.Link eventKey="placescan">Crawl Scope</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="sitescan">Site Scan</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey="summary">Summary</Nav.Link></Nav.Item>
                    </Nav>
                    <Tab.Content className='w-100' animation="true" mountOnEnter={true} unmountOnExit={true} style={{ height: "480px" }} >
                        <Tab.Pane className='w-100 h-100' eventKey="placescan" title="Crawl Scope" >
                            <div className="ag-theme-balham" style={{height: "480px"}}>
                                <AgGridReact 
                                    id="grid-placescan"
                                    rowModelType={rowModelType}
                                    cacheBlockSize={cacheBlockSize}
                                    columnDefs={this.placeColumnDefs}
                                    defaultColDef={defaultColDef}
                                    getMainMenuItems={this.getMainMenuItems}
                                    animateRows={true}
                                    rowMultiSelectWithClick={false}
                                    rowSelection={rowSelection}
                                    rowGroupPanelShow={rowGroupPanelShow}                      
                                    getChildCount={this.getChildCount}
                                    onGridReady={this.onPlaceGridReady}
                                />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane className='w-100 h-100' eventKey="sitescan" title="Site Scan" >
                            <div className="ag-theme-balham" style={{height: "480px"}}>
                            <AgGridReact 
                                    id="grid-sitescan"
                                    rowModelType={rowModelType}
                                    cacheBlockSize={cacheBlockSize}
                                    columnDefs={this.siteColumnDefs}
                                    defaultColDef={defaultColDef}
                                    getMainMenuItems={this.getMainMenuItems}
                                    animateRows={true}
                                    rowMultiSelectWithClick={false}
                                    rowSelection={rowSelection}
                                    rowGroupPanelShow={rowGroupPanelShow}                      
                                    getChildCount={this.getChildCount}
                                    onStoreRefreshed={this.onSiteStoreRefreshed}
                                    onGridReady={this.onSiteGridReady}
                                />
                            </div>
                        </Tab.Pane>
                        <Tab.Pane className='w-100 h-100' eventKey="summary"  title="Summary"  >
                            <div className="m-1 p-1">
                                <Row className="">&nbsp;</Row>
                                <Row className="border-top"><Col xs={3}><b>Name</b></Col><Col>{this.state.jobData.name}</Col></Row>
                                <Row className="border-top">&nbsp;</Row>
                                <Row className="border-top"><Col xs={3}><b>Keywords</b></Col><Col>{this.state.jobData.keywords}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>Categories</b></Col><Col>{this.state.jobData.catnames}</Col></Row>
                                <Row className="border-top">&nbsp;</Row>
                                <Row className="border-top"><Col xs={3}><b>Location Group</b></Col><Col>{this.state.jobData.locgroupname}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>Country</b></Col><Col>{this.state.jobData.countryname}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>State</b></Col><Col>{(this.state.jobData.isstateall === 'Yes') ? 'All' : this.state.jobData.statename}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>County</b></Col><Col>{(this.state.jobData.iscountyall === 'Yes') ? 'All' : this.state.jobData.countyname}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>Postal Codes</b></Col><Col>{(this.state.jobData.ispcodeall === 'Yes') ? 'All' : this.state.jobData.pcodenames}</Col></Row>
                                <Row className="border-top">&nbsp;</Row>
                                <Row className="border-top"><Col xs={3}><b>Crawl Search Scope</b></Col><Col>{this.state.jobData.pcodecount} Places X {this.state.jobData.categorycount} Categories = {this.state.jobData.pcodecount*this.state.jobData.categorycount} Combinations</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>Combinations Crawled</b></Col><Col>{this.state.jobData.pcodescrawled}</Col></Row>
                                <Row className="border-top">&nbsp;</Row>
                                <Row className="border-top"><Col xs={3}><b>Leads Found (Unique)</b></Col><Col>{this.state.jobData.leadsfound}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>Site Scan Completed</b></Col><Col>{this.state.jobData.leadscrawled}</Col></Row>
                                <Row className="border-top">&nbsp;</Row>
                                <Row className="border-top"><Col xs={3}><b>Status</b></Col><Col>{this.getJobStatus(this.state.jobData.status)}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>Start Time</b></Col><Col>{this.state.jobData.starttime}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>End Time</b></Col><Col>{this.state.jobData.endtime}</Col></Row>
                                <Row className="border-top"><Col xs={3}><b>Duration</b></Col><Col>{this.getDuration(this.state.jobData.starttime,this.state.jobData.endtime)}</Col></Row>
                                <Row className="border-top">&nbsp;</Row>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </React.Fragment>
        )
    }
}

export default JobDetails;