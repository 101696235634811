import React, {useState, useRef, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'

function SelectPlaces(props) {
    const gridRef = useRef();
    const selectedGridRef = useRef();
    const keywordsRef = useRef();
    const [keywords, setKeywords] = useState('');
    const [selectedRows, setSelectedRows] = useState();
    const [selectedRowData, setSelectedRowData] = useState(props.selectedPlaces.map(place => {return place.row}));

    const columns = [
        { field: 'id', headerName: 'Id', hide:true, filter: 'agNumberColumnFilter', width: 50, headerClass: 'custom-header-class' },
        { field: 'code', headerName: 'Code', filter: 'agTextColumnFilter', width: 100, headerClass: 'custom-header-class', },
        { field: 'name', headerName: 'Scope', filter: 'agTextColumnFilter', width: 300, headerClass: 'custom-header-class', },
        { field: 'l1_name', headerName: 'Country', filter: 'agTextColumnFilter', width: 150, headerClass: 'custom-header-class', },
        { field: 'l2_name', headerName: 'State/Province', filter: 'agTextColumnFilter', width: 150, headerClass: 'custom-header-class', },
        { field: 'l3_name', headerName: 'County/District', filter: 'agTextColumnFilter', width: 150, headerClass: 'custom-header-class', },
        { field: 'l4_name', headerName: 'Sub District', filter: 'agTextColumnFilter', width: 150, headerClass: 'custom-header-class', },
    ];
    /*
    const sideBar = {
        toolPanels: [
            {
                id: 'columns',
                labelDefault: 'Columns',
                labelKey: 'columns',
                iconKey: 'columns',
                toolPanel: 'agColumnsToolPanel',
            },
            {
                id: 'filters',
                labelDefault: 'Filters',
                labelKey: 'filters',
                iconKey: 'filter',
                toolPanel: 'agFiltersToolPanel',
            }
            ],
            defaultToolPanel: '',
        };
    */
    const datasource = {
        getRows: function (params)  {
            const serverApiUrl = `/api/getagplaces`;
            var keywords = keywordsRef.current || '';
            if (keywords.trim() !== '') {keywords = keywords.concat(' ')}

            fetch(serverApiUrl, {
                method: 'post',
                body: JSON.stringify({...params.request, kw: keywords}),
                headers: { "Content-Type": "application/json; charset=utf-8" }
            })
            .then(httpResponse => httpResponse.json())
            .then(response => {
                params.successCallback(response.rows, response.lastRow);
            })
            .catch(error => {
                console.error(error);
                params.failCallback();
            });
        },
    };

    useEffect(() => {
        keywordsRef.current = keywords;
        gridRef.current.api?.refreshServerSide([]);
    }, [keywords])

    const gridOptions = {
        rowModelType: 'serverSide',
        columnDefs: columns,

        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: false,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
            suppressMenu: true,
        },

        rowSelection: 'multiple',
        columnHoverHighlight: true,
        suppressRowGroupHidesColumns: true,
        cacheBlockSize: 50,
    }

    const onGridReady = (params) => {
        gridOptions.api?.setServerSideDatasource(datasource);
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api?.getSelectedRows();
        setSelectedRows(selectedRows);
    };

    const onSelectedGridSelectionChanged = () => {
        const selectedRows = selectedGridRef.current.api?.getSelectedRows();
        setSelectedRows(selectedRows);
    };

    const handleAddClick = () => {
        if (selectedRows && selectedRows.length > 0) {
            const filteredRows = selectedRows.filter(row => (!selectedRowData.map(o => o.id).includes(row.id)))
            setSelectedRowData(prevselectedRowData => [...prevselectedRowData, ...filteredRows]);
        }
    }

    const handleRemoveClick = () => {
        if (selectedRows && selectedRows.length > 0) {
            const filteredRows = selectedRowData.filter(row => !selectedRows.includes(row));
            setSelectedRowData(filteredRows);
        }
    }

    const savePopupPlaces = () => {
        const places = selectedRowData.map(row => ({value: row.id, label: `${row.code} - ${row.name}, ${row.l1_name}`, row: row}));
        props.savePopupPlaces(places);
    }

    const onKeywordsChange = (e) => {
        setKeywords(e.currentTarget.value);
    }

  return (
    <React.Fragment>
        <Form.Control 
            className='m-0 mt-2 mb-3 w-100 rounded' 
            size="sm" 
            type="text" 
            autoFocus
            placeholder="Search..." 
            value={keywords}
            onChange={onKeywordsChange}
        />
        <div className="m-0 p-0 h-100 d-flex flex-row">
            <div className="border h-100 ag-theme-alpine" style={{width: "66%"}}>
                <AgGridReact
                    ref={gridRef}
                    gridOptions={gridOptions}
                    onGridReady={onGridReady}
                    onSelectionChanged={onSelectionChanged}
                    onRowDoubleClicked={handleAddClick}
                    suppressContextMenu={true}
                />
            </div>
            <div className="mx-2 h-100 d-flex flex-column justify-content-center">
                <Button className="m-1" onClick={handleAddClick}><i class="bi bi-chevron-right"></i></Button>
                <Button className="m-1" onClick={handleRemoveClick}><i class="bi bi-chevron-left"></i></Button>
            </div>
            <div className="border h-100 ag-theme-alpine" style={{width: "33%"}}>
                <AgGridReact
                    ref={selectedGridRef}
                    rowData={selectedRowData}
                    columnDefs={columns}
                    rowSelection={"multiple"}
                    onSelectionChanged={onSelectedGridSelectionChanged}
                    defaultColDef={gridOptions.defaultColDef}
                    onRowDoubleClicked={handleRemoveClick}
                    suppressContextMenu={true}
                />
            </div>
        </div>
        <div className='p-2 d-flex justify-content-end'>
            <Button className='mx-1' variant="secondary" onClick={props.hidePopupPlaces}>
                Cancel
            </Button>
            <Button className={`mx-1`} variant="primary" onClick={savePopupPlaces}>
                Proceed
            </Button>
        </div>
    </React.Fragment>
  )
}

export default SelectPlaces;