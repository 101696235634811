import React from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function MessageModal({ message, show, handleClose }) {
    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Body><h1>{message}</h1></Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default MessageModal;
