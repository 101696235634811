import React,{ useState, useEffect } from "react";
import Form from 'react-bootstrap/Form';
import CountrySelector from './countryselector';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import PhoneInput from 'react-phone-number-input';
import Payment  from './payment';
import { useLocation } from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import AdditionalCredits from './additionalcredits';
import { useNavigate } from "react-router-dom";

function AccountDetails({ loggedInUserId }){
    const [accountName, setAccountName] = useState(null);
    const [accountEmail, setAccountEmail] = useState();
    const [accountZipcode, setAccountZipcode] = useState();
    const [accountCity, setAccountCity] = useState();
    const [country, setCountry] = useState(null);
    const [ipInfo, setIpInfo] = useState(null);
    const [phone, setPhone] = useState(null);
    const [address, setAddress] = useState(null);
    const [showPaymentForm, setShowPaymentForm] = useState(false);
    const [userData, setUserData] = useState({
        loggedInUserId: loggedInUserId,
        accountid: '',
        name: '',
        email: '',
        postalcode: '',
        city: '',
        country: '',
        address: '',
        phone:'',
      });
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const planid = searchParams.get('id');
    const stype = searchParams.get('type');
    const period = searchParams.get('period');
    const [currency, setCurrency] = useState(searchParams.get('currency'));
    const [additionalCreditsdModal, setAdditionalCreditsModal] = useState(false)
    const hideadditionalCreditsdModal = () => setAdditionalCreditsModal(false);
    const [accountStatus, setAccountStatus] = useState('create')
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            try {
                
                const response = await axios.get(`/account/${loggedInUserId}`);
                if(response.status === 200){
                    setAccountName(response.data[0].name);
                    setAccountEmail(response.data[0].email);
                    setAccountZipcode(response.data[0].postalcode);
                    setAccountCity(response.data[0].city);
                    setAddress(response.data[0].address);
                    setPhone(response.data[0].phone);
                    setCountry(response.data[0].country);
                    setAccountStatus('update')
                    setUserData(prevState => ({
                        ...prevState,
                        accountid : response.data[0].id,
                        name: response.data[0].name,
                        email: response.data[0].email,
                        postalcode: response.data[0].postalcode,
                        city: response.data[0].city,
                        address: response.data[0].address,
                        country : response.data[0].country,
                        phone : response.data[0].phone
                    }));
                }else{
                    console.log("satus updation")
                    setAccountStatus('create')
                }
                
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    
    const handleCountryChange = async (selectedCountry) => {
        setCountry(selectedCountry.value);
        setCurrency(selectedCountry.value == 'IN' ? 1 : 2);
        setUserData((prevState) => ({
            ...prevState,
            country: selectedCountry.value,
          }));
        
    };

    const handleIpInfoFetched = (data) => {
        setIpInfo(data);
    };

    const handleAccountNameChange = (e) => {
        setAccountName(e.target.value);
        setUserData((prevState) => ({
            ...prevState,
            name: e.target.value,
          }));
    };

    const handleAccountEmailChange = (e) => {
        setAccountEmail(e.target.value);
        setUserData((prevState) => ({
            ...prevState,
            email: e.target.value,
          }));
    };

    const handleAccountCityChange = (e) => {
        setAccountCity(e.target.value);
        setUserData((prevState) => ({
            ...prevState,
            city: e.target.value,
          }));
    };

    const handleAccountZipcodeChange = (e) => {
        setAccountZipcode(e.target.value);
        setUserData((prevState) => ({
            ...prevState,
            postalcode: e.target.value,
          }));
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
        setUserData((prevState) => ({
            ...prevState,
            address: e.target.value,
          }));
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setUserData((prevState) => ({
          ...prevState,
          phone: value,
        }));
    };
    async function handleSubmit(e) {
        e.preventDefault();
        e.stopPropagation();

        try {
            var response
            setLoading(true)
            if(accountStatus == 'create'){
                response = await axios.post('/addaccountdetails', { loggedInUserId, userData });
            }else{
                response = await axios.post('/updateaccountdetails', { loggedInUserId, userData });
            }
            if(response){
                if(stype == 'additional'){
                    setAdditionalCreditsModal(true)
                }else{
                    setShowPaymentForm(true);
                }
            }
        }catch(error){
            console.log("error", error)
        }    
    }
    const handleCancel = () =>{
        navigate('/main');
    }
    return(
        <>
        <div className="h-100 d-flex align-items-center justify-content-center">
          <div className="p-4 border shadow align-items-center" style={{ width:'700px'}}>
          <h3 className="text-center fs-4 mb-4">Payment Information</h3>
          <Form className='row g-3' onSubmit={handleSubmit}>
                <Form.Group className="col-md-6" controlId="companyname">
                    <Form.Label>Name : </Form.Label>
                    <Form.Control required type="text" placeholder="Company Name" value={accountName} onChange={handleAccountNameChange}/>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="billingemail">
                    <Form.Label>Email : </Form.Label>
                    <Form.Control required type="email" placeholder="Billing Email" value={accountEmail} onChange={handleAccountEmailChange}/>
                </Form.Group>
                <Form.Group className="col-md-12" controlId="billingemail">
                    <Form.Label>Address : </Form.Label>
                    <Form.Control required type="text" placeholder="Billing Address" value={address} onChange={handleAddressChange}/>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="city">
                    <Form.Label>City : </Form.Label>
                    <Form.Control required type="text" placeholder="City" value={accountCity} onChange={handleAccountCityChange}/>
                </Form.Group>
                <Form.Group className="col-md-6" controlId="zipcode">
                    <Form.Label>Zip Code : </Form.Label>
                    <Form.Control required type="text" placeholder="Zip Code" value={accountZipcode} onChange={handleAccountZipcodeChange}/>
                </Form.Group>
                <Form.Group className="col-md-6">
                    <Form.Label controlId='signup_usercountry'>Country</Form.Label>
                    <CountrySelector onCountryChange={handleCountryChange} onIpInfoFetched={handleIpInfoFetched} country={country}/>
                </Form.Group>
                <Form.Group className="col-md-6">
                    <Form.Label controlId='signup_usercountry'>Contact : </Form.Label>
                    <PhoneInput
                        placeholder="Enter phone number"
                        value={phone}
                        onChange={ handlePhoneChange }
                        defaultCountry={country}
                        className='form-control'
                    />
                </Form.Group>
                {loading && <div className='w-100 text-success text-center' >Processing, please wait...</div>}
                <div class="d-flex justify-content-end">
                    <Button onClick={handleCancel} variant='outline-secondary me-4'>Cancel</Button>

                    <Button type="submit" variant='primary' disabled={loading}>Proceed to Pay</Button>
                </div>
                
            </Form>
          </div>
        </div>
        {showPaymentForm && <Payment showPaymentForm={true} userData={userData} setShowPaymentForm={setShowPaymentForm} planid={planid} stype={stype} period={period} currency={currency}/>}
        <style>
            {`
            .PhoneInputInput {
                border: none;
                outline: none;
            }
            `}
        </style>
        <Modal show={additionalCreditsdModal} onHide={hideadditionalCreditsdModal} animation={true} centered size='md'>
            <Modal.Body>

                 <AdditionalCredits hideadditionalCreditsdModal={hideadditionalCreditsdModal} loggedInUserId={loggedInUserId}/>

            </Modal.Body>
        </Modal>

        </>
        
    )
}

export default AccountDetails;