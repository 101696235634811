import React from 'react';
import { components } from 'react-select';

const MultiValueContainer = (props) => {
    const { children, data, innerProps, selectProps } = props;
    const selected = selectProps.value;
    const selectedIndex = selected.findIndex(s => (s.value === data?.value));
    const maxVisibleSelections = 3;
  
    if (selectedIndex < maxVisibleSelections) {
      return (
      <components.MultiValueContainer {...props}>
        {children}
      </components.MultiValueContainer>
      )
    } else if (selectedIndex === maxVisibleSelections) {
      return (
      <components.MultiValueContainer {...props}>
        <div style={{ padding: '0 8px' }}>
          ... {selected.length - maxVisibleSelections} more
        </div>
        </components.MultiValueContainer>
      )
    }
  
    return null
  };

export default MultiValueContainer;