import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import axios from 'axios';

function VerifyEmail() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get('token');
    const userId = searchParams.get('userId');
    const [isMailVerified, setIsMailVerified] = useState('')
   
    const handleVerifyClick = async () => {
        try {
            const response = await axios.post('/emailverify', { verificationToken: token, userId: userId });
            if(response){
                fetchData();
            }
        } catch (error) {
            // Handle errors
            console.error('Error:', error);
        }
    };

    const fetchData = async () => {
        try {

            const response = await axios.get(`/user/${userId}`);
            setIsMailVerified(response.data[0].ismailverified.data[0]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {

        fetchData();
    }, []);

    return (
        <>
            <div className="d-flex justify-content-center align-items-center mt-4">
                <Card className="text-center">
                    <Card.Header className="p-4">
                        <i className="bi bi-envelope-paper fs-1 m-4"></i>
                    </Card.Header>
                    <Card.Body className="border-top">
                        {isMailVerified === 1 ? (
                            <div>
                                <Card.Title>Account confirmed</Card.Title>
                                <Card.Text className="p-4 fs-6">
                                    Your email address is confirmed.
                                </Card.Text>
                            </div>
                        ) : (
                            <div>
                                <Card.Title>Verify Your Email</Card.Title>
                                <Card.Text className="p-4 fs-6">
                                    <p> Thank you for signing up</p>
                                    Please click the button below to confirm your email address and complete your registration.
                                </Card.Text>
                                <Button variant="primary" onClick={handleVerifyClick}>Verify</Button>
                            </div>
                        )}
                    </Card.Body>
                </Card>
            </div>

        </>
    )
}

export default VerifyEmail;
