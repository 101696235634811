import React, { Component } from "react"
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import '../../App.css';
import { Modal, Image, Button } from "react-bootstrap"
import { TransformWrapper, TransformComponent, useControls } from "react-zoom-pan-pinch";

const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true,
    enableRowGroup: true,
    cellStyle: {textAlign: 'center'}
};
const autoGroupColumnDef = {
    pinned: 'left',
}
const defaultSideBar = {
    toolPanels: [
        {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
                suppressExpandAll: true,
                suppressFilterSearch: true,
            }
        },
        {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'column',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
                suppressExpandAll: true,
                suppressFilterSearch: true,
            },
        },
    ],
};

const rowSelection = 'single';
const rowGroupPanelShow = 'onlyWhenGrouping';

const rowModelType = 'serverSide';
const cacheBlockSize = 50;



class ImageCellRenderer extends Component {
    render() {
        const imageBase64 = this.props.value;
        return imageBase64 ? (<React.Fragment style={{ height: '80vh', width: "100%" }}>
            <img src={`data:image/png;base64,${imageBase64}`} alt="Image" style={{ width: '50px', height: '50px' }} />
        </React.Fragment>) : null;
    }
}


class CampaignDetails extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showscreenshot: false,
            screenshotid: 0,
            gridApi: null,
            rowData: [],
            columnDefs: [
                { field: 'index', headerName: 'No.', valueGetter: "node.rowIndex + 1", width: 80 },
                { field: 'template', headerName: 'Template', filter: 'agTextColumnFilter', headerClass: 'custom-header-class'},
                { field: 'displayname', headerName: 'Display Name', filter: 'agTextColumnFilter', headerClass: 'custom-header-class' },
                { field: 'to', headerName: 'To', filter: 'agTextColumnFilter', headerClass: 'custom-header-class' },
                { field: 'subject', headerName: 'Subject', filter: 'agTextColumnFilter', headerClass: 'custom-header-class' },
                { field: 'issent', headerName: 'Sent', filter: 'agTextColumnFilter', headerClass: 'custom-header-class' },
                { field: 'cc', headerName: 'Cc', filter: 'agTextColumnFilter', headerClass: 'custom-header-class' },
                { field: 'bcc', headerName: 'Bcc', filter: 'agTextColumnFilter', headerClass: 'custom-header-class' },
            ],
            selectedData: [],
            updatingRowData: false,
            frameworkComponents: {
                imageCellRenderer: ImageCellRenderer,
            },
        }

        this.onGridReady = this.onGridReady.bind(this);
        this.onGridRefresh = this.onGridRefresh.bind(this);
        this.getChildCount = this.getChildCount.bind(this);
        this.onSelectionChanged = this.onSelectionChanged.bind(this);
        this.onModelUpdated = this.onModelUpdated.bind(this);
        this.onRowDataUpdated = this.onRowDataUpdated.bind(this);
        this.onRefresh = this.onRefresh.bind(this)
        this.setshowscreenshot = this.setshowscreenshot.bind(this)
        this.getimage = this.getimage.bind(this) 
    };
     
    fetchData = async (jid) => {
        if (!this.state.updatingRowData) {
            this.setState({ updatingRowData: true });

            if (jid > 0) {
                this.state.gridApi?.setServerSideDatasource({ getRows: this.getRows });
                var rowCount = this.state.gridApi?.getDisplayedRowCount();
            }
            // this.setState({ updatingRowData: false });
        }
    }

    groupCountComparator(valueA, valueB, nodeA, nodeB, isInverted) {
        // Get the group counts for the two groups
        const countA = nodeA ? nodeA.allChildrenCount : valueA; // Number of children in group A
        const countB = nodeB ? nodeB.allChildrenCount : valueB; // Number of children in group B

        // Compare the counts and return the result
        if (countA < countB) {
            return -1;
        } else if (countA > countB) {
            return 1;
        } else {
            return 0;
        }
    }

    onGridReady(params) {
        this.setState({
            gridApi: params.api,
        })
        params.api.setServerSideDatasource({ getRows: this.getRows });
    }

    onGridRefresh(params) {
        this.state.gridApi.setServerSideDatasource({ getRows: this.getRows });
    }

    onRefresh(params) {
        this.state.gridApi.setServerSideDatasource({ getRows: this.getRows });
    }

    getimage = (id, col) => {
        const url = `/api/getscreenshot/${id}/${col}`
        fetch(url, { method: 'get' })
            .then(httpResponse => httpResponse.json())
            .then(response => {
                console.log(response[0].image)
                document.getElementById('screenshot').src = 'data:image/png;base64, ' + response[0].image
            })
            .catch(error => console.error(error))
    }

    getRows = (params) => {
        const serverURL = `/api/emailcampaigndetails/${this.props.crid}`;

        this.setState({ updatingRowData: true });
        fetch(serverURL, {
            method: 'post',
            body: JSON.stringify(params.request),
            headers: { "Content-Type": "application/json; charset=utf-8" }
        })
            .then(httpResponse => httpResponse.json())
            .then(response => {
                console.log(response)
                params.successCallback(response.rows, response.lastRow);
                this.setState({ updatingRowData: false });
            })
            .catch(error => {
                console.error(error);
                params.failCallback();
            })
    }

    getChildCount(data) {
        return data ? data.childCount : undefined
    }

    onSelectionChanged() {
        if (this.state.isGridView) {
            const data = this.state.gridApi.getSelectedRows();
            this.setState({
                selectedData: data,
            })
            this.props.data(data);
        }
    }

    onModelUpdated = (params) => {
        this.setState({ updatingRowData: false });
    }

    onRowDataUpdated = (params) => {
    }

    onS1CellClicked(params) {

        if (params.colDef.field === 'formscreenshot') {
            this.setshowscreenshot(true)
            this.getimage(params.data.id, 'formscreenshot')
        }
    }

    onS2CellClicked(params) {

        if (params.colDef.field === 'submitscreenshot') {
            this.setshowscreenshot(true)
            this.getimage(params.data.id, 'submitscreenshot')
        }

    }

    setshowscreenshot(value) {
        this.setState({ showscreenshot: value })
    }

    render() {
        const Controls = () => {
            const { zoomIn, zoomOut, resetTransform } = useControls();
            return (
                <>
                    <Button size="sm" variant='light' onClick={() => zoomIn()}><i class="bi bi-zoom-in"></i></Button>{' '}
                    <Button size="sm" variant='light' onClick={() => zoomOut()}><i class="bi bi-zoom-out"></i></Button>{' '}
                    <Button size="sm" variant='light' onClick={() => resetTransform()}><i class="bi bi-arrow-clockwise"></i></Button>
                </>
            );
        };

        return (
            <div>
                <div className="ag-theme-balham" style={{ height: '80vh', width: "100%" }}>
                    <AgGridReact
                        columnDefs={this.state.columnDefs}
                        defaultColDef={defaultColDef}
                        autoGroupColumnDef={autoGroupColumnDef}
                        sideBar={defaultSideBar}
                        animateRows={true}
                        rowMultiSelectWithClick={false}
                        rowSelection={rowSelection}
                        rowGroupPanelShow={rowGroupPanelShow}
                        onGridReady={this.onGridReady}
                        onGridRefresh={this.onGridRefresh}
                        onSelectionChanged={this.onSelectionChanged}
                        onRowDataUpdated={this.onRowDataUpdated}
                        onModelUpdated={this.onModelUpdated}
                        rowModelType={rowModelType}
                        getChildCount={this.getChildCount}
                        cacheBlockSize={cacheBlockSize}
                        alwaysShowHorizontalScroll={false}
                        alwaysShowVerticalScroll={false}
                        frameworkComponents={this.state.frameworkComponents}
                    />
                </div>

                <Modal show={this.state.showscreenshot} onHide={() => this.setState({ showscreenshot: false })} centered size="lg" style={{ height: "95vh" }}>
                    <TransformWrapper>
                        <Modal.Header closeButton={true} >
                            <div className="fs-6">Screenshot</div>
                            <div style={{ position: 'absolute', right: '5%' }}>
                                <Controls />
                            </div>
                        </Modal.Header>
                        <Modal.Body className="m-1 p-0">
                            <TransformComponent> 
                                <Image id="screenshot" src="" fluid />
                            </TransformComponent>

                        </Modal.Body>
                    </TransformWrapper>
                </Modal>
            </div>
        )
    }
}

export default CampaignDetails;