import React, { useEffect } from 'react';
import axios from 'axios';

const EncryptPassword = () => {
    
  
    const handleEncryptPassword = async () => {
      
        try {
          // Send a POST request to /encryptPassword when the route is matched
          const response = await axios.post('/encryptPassword');
          console.log('Encryption response:', response.data);
        } catch (error) {
          console.error('Error encrypting passwords:', error);
        }
      
    };
  
    
    useEffect(() => {
      handleEncryptPassword();
    }, []); 
  
    return (
      <></>
    );
  };
  
  export default EncryptPassword;