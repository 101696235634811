import React, { useState, useEffect } from 'react';
import axios from 'axios';

import { Button, Form, Modal, Row, Col, InputGroup} from 'react-bootstrap';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

function AdminPaymentForm(props) {

    const [users, setUsers] = useState([])
    const [paymentModes, setPaymentModes] = useState([])
    const [allData, setAllData] = useState({})
    const [validated, setValidated] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [modifyPayment, setModifyPayment] = useState(false);
    const [inputValueUser, setInputValueUser] = useState('')
    const [selected, setSelected] = useState([])

    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get('/users')
                    .then((res) => {
                        let allData = ([...res?.data])
                        const arrWithLabelValue = allData.map((element) => {
                            return Object.assign(element, { label: element.id+" - "+`${element.firstname?element.firstname:''}`+" "+`${element.lastname?element.lastname:''}`+' '+'{ '+`${element.name?element.name:''}`+' }', value: element.id })
                        })

                        setUsers(arrWithLabelValue)
                    })
                    .catch((err) => {
                        console.log('Something went wrong we cant get users from server')
                    })
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [])

    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get('/paymentmodes')
                    .then((res) => {
                        setPaymentModes(res.data)
                        // setAllData({paymentmodeid:res?.data[0]?.id})
                        setAllData((prevData) => ({
                            ...prevData,
                            paymentmodeid: res?.data[0]?.id,
                          }))
                    })
                    .catch((err) => {
                        console.log('Something went wrong we cant get paymentmodes from server')
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [])

    useEffect(() => {
        setAllData(props.columnData)
        setModifyPayment(true)

        if (props.isCreateOrUpdate == 'update') {
            axios.get(`/accountPaymentById/${props?.columnData?.id}`)
                .then((res) => {
                    setAllData(res.data[0])
                    let allData =[...users]
                    let label = allData.filter((data)=>{return data.id == res.data[0].userid})
                   
                    setSelected([{label:label[0].label,value:res.data[0].id}])

                })
                .catch((err) => {
                    console.log('Something went wrong we cant get paymentmodes from server')
                })
        }
        if (props.isCreateOrUpdate == 'create') {
            setAllData({
                amount: null,
                noofcredits: null,
                notes: "",
                paymentreferenece: "",
                userid: null,
                paymentmodeid: null

            })
        }

    }, [props.columnData, props.isCreateOrUpdate])

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (!allData.amount || !allData.paymentreferenece || !allData.notes || !allData.noofcredits ) {
            setFormSubmitted(true);
            setValidated(false);
        }

        if (form.checkValidity() === false && allData.userid == null ) {
            setValidated(false);
            event.preventDefault();
            event.stopPropagation();
        }

        if (form.checkValidity() && allData.userid !== null  ) {
            event.preventDefault();
            event.stopPropagation();
            const data = {
                formdata: {...allData,createdby:props.loggedInUserId}
            }
            const request = {
                url: '/createPayment',
                data: data,
                method: "POST"
            }

            if (request) {
                axios(request).then(() => {
                    alert('Payment created')
                    props.onRefresh("vw_payments", 'system')
                    props.setShowPaymentModal(false);
                    setSelected([])
                    setInputValueUser('')
                    setValidated(false)
                    setAllData({ userid: null, paymentmodeid:paymentModes[0].id, amount: null, paymentreferenece: "", notes: "", noofcredits: null })
                })
                    .catch((err) => {
                        console.log('error occured while creating payment', err)
                        alert('Server Error! unable to create payment')
                    })
            }
        }else{
            event.preventDefault();
            event.stopPropagation();
        }
    };

    const handleUpdate = (event) => {
        const form = event.currentTarget;

        if (!allData.amount || !allData.paymentreferenece || !allData.notes || !allData.noofcredits ) {
            setFormSubmitted(true);
            setValidated(false);
        }

        if (form.checkValidity() === false) {
            setValidated(false);
            event.preventDefault();
            event.stopPropagation();
        }

        if (form.checkValidity()) {
            event.preventDefault();
            event.stopPropagation();
            const data = {
                formdata: allData
            }
            const request = {
                url: `/accountPaymentById`,
                data: data,
                method: "POST"
            }

            if (request) {
                axios(request).then(() => {
                    // props.onGridReady()
                    props.onRefresh("vw_payments", 'system')
                    alert('Payment updated')
                    props.setShowPaymentModal(false);
                    setSelected([])
                    setInputValueUser('')
                    setAllData({ userid: null, paymentmodeid:paymentModes[0].id, amount: null, paymentreferenece: "", notes: "", noofcredits: null })
                })
                    .catch((err) => {
                        console.log('error occured while updating payment', err)
                        alert('Server Error! unable to update payment')
                    })
            }
        }else{
            event.preventDefault();
            event.stopPropagation();
        }
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        if (name == 'amount' || name == 'noofcredits') {
            const validateVal = Math.max(0, parseInt(value, 10));
            setAllData({ ...allData, [name]: validateVal })
        } else {

            setAllData({ ...allData, [name]: value })
        }
    }

    const onUserChange = (value) => {
        
        setAllData({ ...allData, 'userid': value.value })
        setSelected([value])
    }

    const onInputChangeUser = (selectedOption, { action, inputValue }) => {
        if (action === 'select-option' || action === 'set-value') {
            setAllData({ ...allData, 'userid': selectedOption.value });
            setSelected([selectedOption]);
            setInputValueUser('');
        } else if (action === 'input-change') {
            setInputValueUser(inputValue);
        }
    }

    const onMenuClose = () => {
        setInputValueUser('')
      }

    return <>
        <Modal show={props.showPaymentModal} size="lg" centered>
            <Modal.Header>
                <Modal.Title>{modifyPayment && props.isCreateOrUpdate == 'update' ? "Update payment" : "Create payment"}</Modal.Title>
            </Modal.Header>
            <Modal.Body className='p-3 shadow'>
                <div className='d-flex flex-column'>
                    <Form noValidate validated={validated} onSubmit={(e) => props.isCreateOrUpdate == 'update' ? handleUpdate(e) : handleSubmit(e)}>
                        <Row className="mb-3">
                            <Col xs={12} md={4} >
                                <Form.Group controlId="validationCustom01">
                                    <Form.Label>User {<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                    {/* <InputGroup hasValidation> */}
                                        {/* <Form.Select required aria-label="Name" type="text" onChange={handleChange} value={allData.userid} name='userid' >
                                        {
                                            users.map((user)=>{
                                                return(
                                                <option key={user.id} value={user.id}>{user.email}</option>
                                                
                                            )}) 
                                        } 
                                    </Form.Select> */}
                                        <Select
                                            className="lg"
                                            styles={{width:"100%"}}
                                            isMulti={false}
                                            backspaceRemovesValue={false}
                                            closeMenuOnSelect={true}
                                            value={selected}
                                            onChange={onUserChange}
                                            options={users}
                                            components={animatedComponents}
                                            onInputChange={onInputChangeUser}
                                            inputValue={inputValueUser}
                                            isValid={formSubmitted && allData.userid ==null ? true : false}
                                            onMenuClose={onMenuClose}
                                        // isDisabled={this.props.isCrawling}
                                        />
                                        {
                                            formSubmitted && allData.userid==null ? (
                                            <>
                                                <p style={{color:"rgb(220 53 69)",fontSize:".875em"}}>Please provide a valid User.</p>
                                            </>
                                            ):''
                                        }

                                        {/* <Form.Control.Feedback>Looks good!</Form.Control.Feedback> */}
                                    {/* </InputGroup> */}
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <InputGroup hasValidation>
                                    <Form.Group controlId="validationCustom02">
                                        <Form.Label>Amount {<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Amount"
                                            value={allData.amount}
                                            name='amount'
                                            onChange={handleChange}
                                            isInvalid={formSubmitted && (allData.amount === null || allData.amount === undefined || allData.amount === '')}
                                        />
                                        <Form.Control.Feedback type="invalid">Please provide a valid Amount.</Form.Control.Feedback>

                                    </Form.Group>
                                </InputGroup>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="validationCustomUsername">
                                    <Form.Label>Payment Mode {<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                    <InputGroup hasValidation>
                                        <Form.Select required aria-label="Payment Mode" type="text" onChange={handleChange} value={allData.paymentmodeid} name='paymentmodeid' >
                                            {
                                                paymentModes.map((payment) => {
                                                    return (
                                                        <option key={payment.id} value={payment.id}>{payment.name}</option>

                                                    )
                                                })
                                            }
                                        </Form.Select>
                                        <Form.Control.Feedback type="invalid">
                                            Please choose a username.
                                        </Form.Control.Feedback>
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={8}>
                                <Form.Group controlId="validationCustom03">
                                    <Form.Label>Payment Reference {<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder="Payment Referenece"
                                        value={allData.paymentreferenece}
                                        name='paymentreferenece'
                                        onChange={handleChange}
                                        required
                                        isInvalid={formSubmitted && !allData.paymentreferenece}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid payment reference.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group controlId="validationCustom05">
                                    <Form.Label>No Of Credits {<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                    <Form.Control
                                        type="number"
                                        placeholder="No Of Credits"
                                        value={allData.noofcredits}
                                        onChange={handleChange}
                                        name='noofcredits'
                                        required
                                        isInvalid={formSubmitted && !allData.noofcredits}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid no of credits.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={12}>
                                <Form.Group controlId="validationCustom04">
                                    <Form.Label>Notes</Form.Label>
                                    <Form.Control
                                        type="text"
                                        as="textarea" rows={3}
                                        placeholder="Notes"
                                        value={allData.notes}
                                        name='notes'
                                        onChange={handleChange}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please provide a valid notes.
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        
                            <div className='p-2 d-flex justify-content-end'>
                                <Button className='mx-1' variant="secondary" onClick={() => { props.setShowPaymentModal(false); setInputValueUser(''); setSelected([]); setAllData({ userid: null, paymentmodeid: paymentModes[0]?.id ? paymentModes[0]?.id : null, amount: null, paymentreferenece: "", notes: "", noofcredits: null }); setFormSubmitted(false); setModifyPayment(false); props.setIsCreateOrUpdate('create') }}>
                                    Cancel
                                </Button>
                            
                                <Button type='submit' className={`mx-1`} variant="primary">
                                    {modifyPayment && props.isCreateOrUpdate === 'update' ? 'Modify' : 'Pay'}
                                </Button>
                            
                            </div>
                        
                    </Form>
                    </div>
            </Modal.Body>
        </Modal>
    </>
}

export default AdminPaymentForm