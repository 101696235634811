import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import SocialMediaLogin from './socialmedialogin';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import CountrySelector from './countryselector';
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/css/bootstrap.min.css';

function SignupPage(props) {
  const navigate = useNavigate();

  const [firstname, setFirstname] = useState(null);
  const [lastname, setLastname] = useState(null);
  const [company, setCompany] = useState(null);
  const [phone, setPhone] = useState(null);
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [country, setCountry] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [thisProps] = useState(props);
  const [validated, setValidated] = useState(false);
  const [validateEmail, setValidateEmail] = useState(false);
  const [validatePassword, setValidatePassword] = useState(false);
  const [validatePhone, setValidatePhone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ipInfo, setIpInfo] = useState(null);
  const [passwordVisible, setPasswordVisible] = useState(false);


  function setSignupStatus(bStatus, email, userid, planid, userimg, userplan, username, sessionid, userdata) {
    thisProps.parentCallback(bStatus, email, userid, planid, userimg, userplan, username, sessionid, userdata)
  };

  function handleFirstNameChange(event) {
    const newFname = event.target.value;
    setFirstname(newFname);
  };

  function handleLastNameChange(event) {
    const newLname = event.target.value;
    setLastname(newLname);
  };

  function handlePhoneChange(event) {
    const newPhone = event.target.value;
    setPhone(newPhone);
    setValidatePhone(true);
  };

  function handleCompanyChange(event) {
    const newCompany = event.target.value;
    setCompany(newCompany);
  };

  function handleEmailChange(event) {
    const newEmail = event.target.value;
    setEmail(newEmail);
    setValidateEmail(true);
  };

  function handlePasswordChange(event) {
    const newPassword = event.target.value;
    setPassword(newPassword);
    setValidatePassword(true);
  };

  const handleCountryChange = (selectedCountry) => {
    setCountry(selectedCountry.value);
  };

  const handleIpInfoFetched = (data) => {
    setIpInfo(data);
  };

  const isValidEmail = (!validateEmail) || (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email));
  const isValidPassword = (!validatePassword) || (/[A-Z]/.test(password) && /[\d]/.test(password) && /[\W]/.test(password) && /^[A-Za-z\d\W]{8,15}$/.test(password));
  const isValidPhone = (!validatePhone) || (/^[0-9]{10}$/.test(phone));

  function resetErrorMessage() {
    setErrorMessage(null)
  }
  async function handleSubmit(event) {
    event.preventDefault();
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.stopPropagation();
    } else {
      setLoading(true);
      try {
        const response = await axios.post('/signup', { firstname, lastname, company, phone, email, password, country, ipInfo });
        // token = response.data && response.data[0];
        const userid = response.data && response.data.userid;
        const planid = response.data && response.data.planid;
        const userplan = response.data && response.data.userplan;
        const sessionid = response.data && response.data.sessionid;
        const userdata = response.data && response.data.userdata;
        // localStorage.setItem('token', token);
        setSignupStatus(true, email, userid, planid, null, userplan, firstname, sessionid, userdata);
        navigate('/main');
      } catch (error) {
        setErrorMessage(error.response.data.error);
        // setSignupStatus(false, null, null, null, null, null, null, null);
      }finally {
        setLoading(false); // Hide loading message
      }
    }
    setValidated(true);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  return (
    <React.Fragment>
      <div className="h-100 d-flex align-items-center justify-content-center">
      <div className="p-4 border shadow " style={{ width: '700px' }}>
      <h3 className="text-center fs-4 mb-4">Sign Up</h3>
      <Form noValidate validated={validated} onSubmit={handleSubmit} className='row g-3'>
      <Form.Group className="col-md-6">
              <Form.Label controlId='signup_usereamil'>Email <span style={{ color: 'red' }}>*</span></Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  id='signup_useremail'
                  type="email"
                  placeholder="Enter Email"
                  value={email}
                  onChange={handleEmailChange}
                  onFocus={resetErrorMessage}
                  isInvalid={!isValidEmail}
                  autoFocus={true}
                />
                <Form.Control.Feedback type="invalid">
                  Enter valid mail
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-6">
            <Form.Label controlId='signup_password'>Password <span style={{ color: 'red' }}>*</span></Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  id='signup_password'
                  type={passwordVisible ? 'text' : 'password'}
                  placeholder='Enter Password'
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={resetErrorMessage}
                  isInvalid={!isValidPassword}
                />
                <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                  {passwordVisible ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  Your password must be 8 to 15 characters long, contain letters and numbers, and at least one special character and one upper case letter.
                </Form.Control.Feedback>
                </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label controlId='signup_userfname'>First Name <span style={{ color: 'red' }}>*</span></Form.Label>
                <InputGroup>
                  <Form.Control
                    required
                    id='signup_userfname'
                    type="text"
                    placeholder="Enter First Name"
                    value={firstname}
                    onChange={handleFirstNameChange}
                    onFocus={resetErrorMessage}
                  />
                </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-6">
              <Form.Label controlId='signup_userlname'>Last Name</Form.Label>
                <InputGroup>
                  <Form.Control
                    id='signup_userlname'
                    type="text"
                    placeholder="Enter Last Name"
                    onChange={handleLastNameChange}
                    value={lastname}
                  />
                </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label controlId='signup_userphone'>Phone</Form.Label>
              
              <InputGroup hasValidation>
                {/* <Form.Control
                  id='signup_userphone'
                  type="text"
                  placeholder="Enter Phone"
                  value={phone}
                  onChange={handlePhoneChange}
                  onFocus={resetErrorMessage}
                  isInvalid={!isValidPhone}
                /> */}
                
                <PhoneInput
                placeholder="Enter phone number"
                value={phone}
                onChange={setPhone}
                defaultCountry={country}
                className='form-control'
                />
                
                <Form.Control.Feedback type="invalid">
                  Enter valid phone number
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label controlId='signup_usercountry'>Country</Form.Label>
              <CountrySelector onCountryChange={handleCountryChange} onIpInfoFetched={handleIpInfoFetched} />
            </Form.Group>
            <Form.Group className="col-md-4">
              <Form.Label controlId='signup_usercompany'>Company</Form.Label>
                <InputGroup>
                  <Form.Control
                    id='signup_usercompany'
                    type="text"
                    placeholder="Enter Company"
                    value={company}
                    onChange={handleCompanyChange}
                  />
                </InputGroup>
            </Form.Group>            
            <Form.Group>
              <Form.Check
                required
                label={
                  <>
                    I agree to the <a href='https://wowleads.com/terms&conditions.html' target='blank'>terms and conditions </a> and The <a href='https://wowleads.com/policy.html' target='blank'>Privacy Policy</a> <span style={{ color: 'red' }}>*</span>
                  </>
                }
                feedback="You must agree before submitting."
                feedbackType="invalid"
                onFocus={resetErrorMessage}
              />

            </Form.Group>
       
        <div class="col-md-6 d-grid mx-auto">
        {loading && <div className='w-100 text-success text-center' >Signing up, please wait...</div>}
        <Button type="submit" variant='outline-primary' disabled={(!isValidEmail || !isValidPassword || !isValidPhone || loading)}>Sign Up</Button>
                {errorMessage && <div className='w-100 text-danger text-center' >{errorMessage}</div>}
        </div>
        
      </Form>
            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                <hr style={{ flex: '1', borderTop: '2px solid #000' }} />
                <div style={{ margin: '0 10px', color: '#000' }}>Or</div>
                <hr style={{ flex: '1', borderTop: '2px solid #000' }} />
            </div>
            <div className='col-md-6 d-grid mx-auto'>
                <SocialMediaLogin setLoginStatus={setSignupStatus} setErrorMessage={setErrorMessage} status={'signup'} loading={loading} setLoading={setLoading}/>
            </div>
      </div>
      <style>
        {`
          .PhoneInputInput {
            border: none;
            outline: none;
          }
        `}
      </style>
      </div>
      {/* <div className="h-75 d-flex align-items-center justify-content-center">
        <div className="p-4 border shadow " style={{ width: '350px' }}>
          <h3 className="text-center fs-4 mb-4">Sign Up</h3>
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <Form.Group className="mb-3">
              <Form.Label controlId='signup_userid'>Email ID</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  id='signup_userid'
                  type="text"
                  placeholder="Enter User ID"
                  value={username}
                  onChange={handleUsernameChange}
                  onFocus={resetErrorMessage}
                  autoFocus={true}
                  isInvalid={!isValidUserName}
                />
                <Form.Control.Feedback type="invalid">
                  User name must be 5 to 10 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label controlId='signup_password'>Password</Form.Label>
              <InputGroup hasValidation>
                <Form.Control
                  required
                  id='signup_password'
                  type="password"
                  placeholder='Enter Password'
                  value={password}
                  onChange={handlePasswordChange}
                  onFocus={resetErrorMessage}
                  isInvalid={!isValidPassword}
                />
                <Form.Control.Feedback type="invalid">
                  Your password must be 8 to 15 characters long, contain letters and numbers, and at least one special character and one upper case letter.
                </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Check
                  required
                  label="Agree to terms and conditions"
                  feedback="You must agree before submitting."
                  feedbackType="invalid"
                  onFocus={resetErrorMessage}
                />
              </Form.Group>
              <div className="d-grid mb-3">
                <Button type="submit" variant='outline-primary' disabled={(!isValidUserName || !isValidPassword)}>Sign Up</Button>
                {errorMessage && <div className='w-100 text-danger text-center' >{errorMessage}</div>}
              </div>
            </Form>
            <div style={{ display: 'flex', alignItems: 'center', margin: '10px 0' }}>
                <hr style={{ flex: '1', borderTop: '2px solid #000' }} />
                <div style={{ margin: '0 10px', color: '#000' }}>Or</div>
                <hr style={{ flex: '1', borderTop: '2px solid #000' }} />
            </div>
            <div className='mt-2'>
                <SocialMediaLogin setLoginStatus={setSignupStatus} setErrorMessage={setErrorMessage} />
            </div>
          </div>
        </div> */}
    </React.Fragment>
  );
}

export default SignupPage;