import React, { Component } from 'react';
import Spinner from 'react-bootstrap/Spinner';

const ERROR_URL = '/error.html';

class WebPage extends Component {
  constructor (props) {
    super(props);

    this.state = {
      pageKey: Date.now(),
      pageLoaded: false
    }

    this.onPageLoaded = this.onPageLoaded.bind(this);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({
        pageKey: Date.now(),
        pageLoaded: false
      })
    }
  }

  onPageLoaded() {
    this.setState({
      pageLoaded: true
    })
  }

  render() {
    return (
      <div className='w-100 h-100'>
        <div className={`w-100 h-50 align-items-center justify-content-center ${this.state.pageLoaded ? 'd-none' : 'd-flex'}`}>
          <Spinner as="span" variant='primary' size="lg" animation="border" role="status" aria-hidden="true" />
        </div>
        {/* unique value for key attribute ensures content is completely redrawn */}
        <div key={this.state.pageKey} className={`w-100 h-100 ${this.state.pageLoaded ? 'visible' : 'invisible'}`}>
          <object 
            id={`wp-${Date.now()}`}
            alt='Web Page Preview'
            title='Web Page Preview'
            className={`w-100 h-100`} 
            data={this.props.data} 
            onLoad={this.onPageLoaded}
          >
            <div className='d-flex justify-content-center'>
            <a href={this.props.data} target="_blank" rel="noopener noreferrer">
              <object data={ERROR_URL} alt='There is a problem loading this page' title='Click here to try opening in the page in a new window...' >
                {/* <img className={'w-100 h-100'} src={`/screenshot?url=${this.props.data}`} alt="Preview" /> */}
              </object>
            </a>
            </div>
          </object>
          {/*
          <iframe 
            id={`wp-${Date.now()}`}
            ref='iframe'
            title={Date.now}
            src={this.props.data}
            className="w-100 h-100 border border-rounded"
            sandbox
            onLoad={this.onPageLoaded}
          />
          */}
        </div>
      </div>
    );
  }
}

export default WebPage;