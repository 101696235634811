import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';
import { useLocation } from "react-router-dom";
import { Link } from 'react-router-dom';

function ChangeUserPassword({ email, setShowUserPasswordModal }) {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [validatePassword, setValidatePassword] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [validated, setValidated] = useState(false);
    const [emailid, setEmailId] = useState(email);
    const token = searchParams.get('token');
    const [successMessage, setSuccessMessage] = useState(null);
    const [expirationStatus, setExpirationStatus] = useState(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [newpasswordVisible, setNewPasswordVisible] = useState(false);

    function handlePasswordChange(event) {
        const newPassword = event.target.value;
        setPassword(newPassword);
        setValidatePassword(true);
    };

    function handleConfirmPasswordChange(event) {
        const newConfirmPassword = event.target.value;
        setConfirmPassword(newConfirmPassword);
    };

    function resetErrorMessage() {
        setErrorMessage(null);
    }

    const isValidPassword = (!validatePassword) || (/[A-Z]/.test(password) && /[\d]/.test(password) && /[\W]/.test(password) && /^[A-Za-z\d\W]{8,15}$/.test(password));
    const passwordsMatch = password === confirmPassword;

    async function handleSubmit(event) {
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false || !passwordsMatch) {
            event.stopPropagation();
            setValidated(true);
            return;
        }

        try {
            const response = await axios.post('/chnageuserpass', { password, emailid });
            if (response) {
                setSuccessMessage("Password updated successfully!");

                setTimeout(() => {
                    if (setShowUserPasswordModal) {
                        setShowUserPasswordModal(false);
                    }
                    setSuccessMessage(null); // Reset success message
                }, 1000);
            }
        } catch (error) {
            console.log(error)
        }
    };

    useEffect(() => {
        const fetchData = async () => {
            try {
                await axios.get(`/checktoken/${token}`)
                    .then((res) => {
                       setEmailId(res.data[0].email)
                       setExpirationStatus(res.data[0].expiration_status)
                       console.log(res)
                    })
                    .catch((err) => {
                        console.log('Something went wrong we are unable to fetch token from server')
                    });
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [token])

    if (expirationStatus === 'expired') {
        return (
            <div className="h-75 d-flex align-items-center justify-content-center">
                <div className="text-center">
                    <h1 className="display-4">Token has expired!</h1>
                    <p className="lead">Please request a new password reset link.</p>
                </div>
            </div>
        )
    }
    const togglePasswordVisibility = () => {
        setPasswordVisible(!passwordVisible);
    };
    const toggleNewPasswordVisibility = () => {
        setNewPasswordVisible(!newpasswordVisible);
    };
    return (
        <div className="h-75 d-flex align-items-center justify-content-center">
            <div className={`${setShowUserPasswordModal ? '' : "border shadow"} p-4 `} style={{ width: '480px' }}>
                <h3 className="text-center fs-4 mb-4">Change Password</h3>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>

                    <Form.Group className="mb-2 p-2" controlId="formBasicPassword">
                        <Form.Label controlId='password'>Enter New Password </Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                id='password'
                                type={passwordVisible ? 'text' : 'password'}
                                placeholder='Enter New Password'
                                value={password}
                                onChange={handlePasswordChange}
                                onFocus={resetErrorMessage}
                                isInvalid={!isValidPassword}
                                className="mt-1"
                            />
                            <InputGroup.Text onClick={togglePasswordVisibility} style={{ cursor: 'pointer' }}>
                                {passwordVisible ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                                Your password must be 8 to 15 characters long, contain letters and numbers, and at least one special character and one upper case letter.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>

                    <Form.Group className="mb-2 p-2" controlId="formBasicConfirmPassword">
                        <Form.Label controlId='confirmPassword'>Confirm New Password </Form.Label>
                        <InputGroup hasValidation>
                            <Form.Control
                                required
                                id='confirmPassword'
                                type={newpasswordVisible ? 'text' : 'password'}
                                placeholder='Confirm New Password'
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                onFocus={resetErrorMessage}
                                isInvalid={!passwordsMatch}
                                className="mt-1"
                            />
                            <InputGroup.Text onClick={toggleNewPasswordVisibility} style={{ cursor: 'pointer' }}>
                                {newpasswordVisible ? <i className="bi bi-eye-slash"></i> : <i className="bi bi-eye"></i>}
                            </InputGroup.Text>
                            <Form.Control.Feedback type="invalid">
                                Passwords do not match.
                            </Form.Control.Feedback>
                        </InputGroup>
                    </Form.Group>
                    <div className="row justify-content-md-center mt-4">
                        {errorMessage && <div className='w-100 text-danger text-center'>{errorMessage}</div>}
                        {successMessage && <div className='w-100 text-success text-center'>{successMessage}</div>}

                        <div className="col-md-4 d-grid gap-2">
                            <Button type="submit" variant='outline-primary' disabled={!isValidPassword || !passwordsMatch}>Save</Button>
                        </div>
                        <div className="col-md-4 d-grid gap-2">
                            {setShowUserPasswordModal ? (
                                <Button variant="outline-secondary" onClick={() => setShowUserPasswordModal(false)}>Cancel</Button>
                            ) : (
                                <Link to="/login" className="btn btn-outline-secondary">Cancel</Link>
                            )}
                        </div>
                    </div>

                </Form>
            </div>
        </div>
    )
}

export default ChangeUserPassword;
