import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import axios from 'axios';

function ForgotPassword() {
    const [email, setEmail] = useState('');
    const [validateEmail, setValidateEmail] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);
    const [validated, setValidated] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [loading, setLoading] = useState(false);

    function handleEmailChange(event) {
        const newEmail = event.target.value;
        setEmail(newEmail);
        setValidateEmail(true);
    };

    function resetErrorMessage() {
        setErrorMessage(null);
    }

    const isValidEmail = (!validateEmail) || (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email));

    async function handleSubmit(event){
        const form = event.currentTarget;
        event.preventDefault();

        if (form.checkValidity() === false) {
            event.preventDefault();
        } else {
                setLoading(true);
                try {
                  const response = await axios.post('/changepasswordmail', { email });
                    if(response.data.success){
                        setSuccessMessage("Email send successfully!");
                    }else{
                        setSuccessMessage("No data found!");
                    } 
                        setTimeout(() => {
                        setSuccessMessage(null); // Reset success message
                    }, 2000);
                  
                  
                  
                }catch(error){
                    console.log(error)
                }finally {
                    setLoading(false); // Hide loading message
                  }
        }
        setValidated(true);
    };
    return (
        <div className="h-85 d-flex align-items-center justify-content-center mt-2">
      <div className="p-4 border shadow " style={{ width: '480px' }}>
      <h3 className="text-center fs-4 mb-4">Forgot your password?</h3>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>

                <Form.Group className="mb-2 p-2" controlId="formBasicEmail">
                    <Form.Label controlId='email' >Email</Form.Label>
                    <InputGroup hasValidation>
                        <Form.Control
                            required
                            id='email'
                            type="email"
                            placeholder='Enter your email'
                            value={email}
                            onChange={handleEmailChange}
                            onFocus={resetErrorMessage}
                            isInvalid={!isValidEmail}
                            className="mt-1"
                        />
                        <Form.Control.Feedback type="invalid">
                        Enter valid mail
                        </Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>

                <div className="col-md-6 d-grid mx-auto mb-2">
                    <Button type="submit" variant='primary' disabled={(!isValidEmail)}>Send me the link</Button>
                    {loading && <div className='w-100 text-success text-center fs-6' >Sending mail, please wait...</div>}
                    {errorMessage && <div className='w-100 text-danger text-center fs-6'>{errorMessage}</div>}
                    {successMessage && <div className='w-100 text-success text-center fs-6'>{successMessage}</div>}
                </div>
            </Form>
        </div>
        </div>
    )
}

export default ForgotPassword;