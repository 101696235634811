import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Modal, Card, Container, Row, Col, Button, Image } from 'react-bootstrap';
import OpenStripe from './stripepayment';
import openRazorpay from './razorpaypayment';
import MessageModal from './messagemodal';
import { useNavigate } from "react-router-dom";

function Payment({ showPaymentForm, userData, setShowPaymentForm, planid, stype, period, currency, creditsAmount, hideadditionalCreditsdModal, credits }) {
    const [showStripePaymentForm, setShowStripePaymentForm] = useState(false);
    const [api, setApi] = useState('')
    const [params, setParams] = useState();
    const [amount, setAmount] = useState(null);
    const [pid, setPid] = useState(null);
    const [priceId, setPriceId] = useState();
    const [showMessageModal, setShowMessageModal] = useState(false);
    const [message, setMessage] = useState('');
    const navigate = useNavigate();
    const handleClose = () => {
        setShowMessageModal(false);
        navigate('/main');
    };
    const onSuccess = (msg) => {
        setMessage(msg);
        if(hideadditionalCreditsdModal){hideadditionalCreditsdModal();}
        setShowMessageModal(true);
        navigate('/main');
    };

    useEffect(() => {
        const fetchData = async () => {
            const response = await axios.get(`/pricedetails/${planid}/${currency}`)
            const priceid = response.data[0].id
            setPriceId(response.data[0].id);

            if(stype == 'normal'){
                if(currency ==  1){
                    handleRazorPay( period == 1 ?  response.data[0].monthlyfee  : response.data[0].annualfee, priceid)
                }else{
                    handleStripePay(period == 1 ?  response.data[0].monthlyfee  : response.data[0].annualfee) 
                }
            }else if(stype == 'additional'){
                if(currency == 1){
                    setPriceId(response.data[0].id);
                    handleRazorPay(creditsAmount, priceid)
                }else{
                    setPriceId(response.data[0].id);
                    handleStripePay(creditsAmount) 
                }
            }else{
                if(currency == 1){
                    handleRazorpaySubscription({ planid: response.data[0].pid, priceid})
                }else{
                    handleStripeSubscription({ pid: response.data[0].pid}) 
                }
            }

        }
        fetchData()
    },[])
    
    const handleStripePay = async (amount) => {

        setShowStripePaymentForm(true);
        setParams(userData);
        setApi('/create-payment-intent');
        setAmount(amount)
    }

    const handleRazorPay = async (amount, priceid) => {

        const onSuccess = (msg) => {
            setMessage(msg);
            if(hideadditionalCreditsdModal){hideadditionalCreditsdModal();}
            setShowMessageModal(true);
            navigate('/main');
        };

        const user = {
            accountid : userData.accountid,
            name: userData.name,
            email: userData.email,
            phone: userData.phone
        }
        
        openRazorpay({ amount, subscription: false, user: user, priceid:priceid, onSuccess, credits:credits });

    };

    const handleStripeSubscription = async ({ pid }) => {
        const response = await axios.post('/create-stripe-customer', { userData });
        if (response) {
            setShowStripePaymentForm(true);
            setParams(response.data);
            setApi('/create-stripe-subscription');
            setPid(pid)
        }
    }

    const handleRazorpaySubscription = async ({ planid, priceid }) => {
        const onSuccess = (msg) => {
            setMessage(msg);
            // if(hideadditionalCreditsdModal){hideadditionalCreditsdModal();}
            setShowMessageModal(true);
            navigate('/main');
        };

        const user = {
            accountid : userData.accountid,
            name: userData.name,
            email: userData.email,
            planid: planid
        }
        openRazorpay({ amount, subscription: true, user: user, priceid:priceid, onSuccess, credits:credits });

    }

    return (
        <>
        {showStripePaymentForm &&
            <Modal show={showPaymentForm} size="md" centered>
                <Modal.Body className='p-2 shadow'>
                    <Container>
                        <Row>
                            <Col className='p-2'>
                            <div className='mb-4'>
                                
                                 <Card style={{ width:'140px', height:'80px', backgroundColor:'#f4f4f4f4'}} className='mb-4'>
                                     <Card.Body className='d-flex align-items-center justify-content-center'>
                                         
                                         <Image src="wowleads-black.png" width="100" alt="WowLeads" />
                                         
                                     </Card.Body>
                                 </Card>
                                 <span className='mb-4'><h6>Price Summary : <b>US ${amount}</b></h6></span>   
                            </div>
                                {showStripePaymentForm && <OpenStripe api={api} params={params} amount={amount} pid={pid} priceid={priceId} 
                                            onSuccess={(msg) => {
                                                onSuccess(msg);
                                                setShowStripePaymentForm(false);
                                            }}
                                            credits={credits}
                                    />}
                            </Col>
                        </Row>
                    </Container>
                
                </Modal.Body>
                <Modal.Footer style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <p>Secured by<a href="https://stripe.com/" target="_blank" rel="noopener noreferrer">
                                    <Image style={{ width: "60px" }} src="Stripe-logo.png" alt="stripe" />
                                </a>
                    </p>
                    <Button onClick={() => setShowPaymentForm(false)}>Cancel</Button>
                </Modal.Footer>

            </Modal>
        }

        <MessageModal message={message} show={showMessageModal} handleClose={handleClose} />

        </>
    );
}

export default Payment;
