import React, { useEffect } from 'react';

function LogoutOnUnload({ sessionid }) {
  
  useEffect(() => {

    // const getNavigationType = () => {
    //   if (typeof performance.getEntriesByType === 'function' && performance.getEntriesByType('navigation').length > 0) {
    //     const [navigationEntry] = performance.getEntriesByType('navigation');
    //     return navigationEntry.type;
    //   } else if (performance.navigation) {
    //     // Fallback to the deprecated performance.navigation.type
    //     switch (performance.navigation.type) {
    //       case performance.navigation.TYPE_NAVIGATE:
    //         return 'navigate';
    //       case performance.navigation.TYPE_RELOAD:
    //         return 'reload';
    //       case performance.navigation.TYPE_BACK_FORWARD:
    //         return 'back_forward';
    //       default:
    //         return 'navigate';
    //     }
    //   }
    //   return null;
    // };

    const handleBeforeUnload = () => {
     
        const url = '/api/logout'; // Replace with your logout endpoint
        const data = JSON.stringify({ sessionid: sessionid });
        const blob = new Blob([data], { type: 'application/json' });
        navigator.sendBeacon(url, blob);
      
    };

    // Listen for beforeunload event
    window.addEventListener('beforeunload', handleBeforeUnload);

    // Cleanup event listeners on component unmount
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload); 
    };
  }, [sessionid]);

  return null;
}

export default LogoutOnUnload;
