import {React, useState,useRef, useEffect} from 'react';
import { } from 'react-bootstrap';
import axios from 'axios';

import {  Button, Tab, Nav, Form, Modal, Row, Col} from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'

function SMTPCredentials(props){

    const [showForm,setShowForm]=useState(false)
    const [isUpdate,setIsupdate]=useState(false)
    const [validated, setValidated] = useState(false);
    const [formData,setFormData]=useState({})

    const columns = [
        { field: 'id', headerName: 'Id', hide:"true", filter: 'agNumberColumnFilter',  },
        { field: 'title', headerName: 'Title', filter: 'agTextColumnFilter'},
        { field: 'host', headerName: 'Host', filter: 'agTextColumnFilter'},
        { field: 'port', headerName: 'Port', filter: 'agTextColumnFilter'},
        { field: 'authuser', headerName: 'Auth User', filter: 'agTextColumnFilter' },
        { field: 'authpass', headerName: 'Auth Password', filter: 'agTextColumnFilter' },
        { field: 'from', headerName: 'From', filter: 'agTextColumnFilter'},
        { field: 'displayname', headerName: 'Display Name', filter: 'agTextColumnFilter', headerClass: 'custom-header-class' },
    ];

    const gridRef = useRef();

    const datasource = {
        getRows: function (params)  {
            const serverApiUrl = `/api/getagdata/${props.uid}`;

            fetch(serverApiUrl, {
                method: 'post',
                body: JSON.stringify({...params.request, tablename:'smtpcredentials'}),
                headers: { "Content-Type": "application/json; charset=utf-8" }
            })
            .then(httpResponse => httpResponse.json())
            .then(response => {
                params.successCallback(response.rows, response.lastRow);
            })
            .catch(error => {
                console.error(error);
                params.failCallback();
            });
        }
    };

    useEffect(() => {
        // gridRef.current.api?.setGridOption('serverSideDatasource', datasource);
        gridRef.current.api?.refreshServerSide([]);
    }, [])

    const defaultColDef={
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: {textAlign: 'center'}
        // floatingFilter: false,
        // enableValue: true,
        // enableRowGroup: true,
        // enablePivot: true,
    }

    const onGridReady = (params) => {
         gridRef.current.api?.setServerSideDatasource(datasource);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const handleSubmit = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false ) {
            event.preventDefault();
            event.stopPropagation();
        }
        else if (form.checkValidity()===true ) {
            event.preventDefault();
            event.stopPropagation();

            const request = {
                url: '/smtpcreadentials',
                data: {...formData,createdby:props.uid},
                method: "POST"
            }

            if (request) {
                axios(request).then(() => {
                    alert('SMTP Credentials Created')
                    gridRef.current.api?.refreshServerSide([]);
                    
                    setFormData({})
                    setValidated(true);
                    setShowForm(false)
                })
                    .catch((err) => {
                        console.log('error occured while creating SMTP Credentials', err)
                        alert('Server Error! unable to create SMTP Credentials')
                    })
            }
        }

        setValidated(true);
    };

    const handleUpdate = (event) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false ) {
            event.preventDefault();
            event.stopPropagation();
        }
        else if (form.checkValidity()===true ) {
            event.preventDefault();
            event.stopPropagation();

            const request = {
                url: '/smtpcreadentials',
                data: {...formData,modifiedby:props.uid},
                method: "PUT"
            }

            if (request) {
                axios(request).then(() => {
                    alert('SMTP Credentials Modified')
                    gridRef.current.api?.refreshServerSide([]);
                    setShowForm(false)
                    setFormData({})
                    setIsupdate(false)
                })
                    .catch((err) => {
                        alert('Server Error! unable to update SMTP Credentials')
                    })
            }
        }

        setValidated(true);
    }

    const handleAddNew=()=>{
        setValidated(false)
        setShowForm(true)
    }

    const handleModify=(node)=>{
        axios({
            url: `/smtpcreadentials/${node.data.id}`,
            method: "GET"
        })
        .then((result)=>{
            setFormData(result.data)
            setValidated(false)
            setIsupdate(true)
            setShowForm(true)
        })
        .catch((err)=>{
            console.log("Error: ",err)
        })
    }

    const handleDelete=(node)=>{
        if (window.confirm('Are you sure you want to delete SMTP Credentials')) {
            axios({
                url: `/smtpcreadentials/${node.data.id}`,
                method: "DELETE"
            })
            .then((result)=>{
                gridRef.current.api?.refreshServerSide([]);
            })
            .catch((err)=>{
                console.log("Error: ",err)
            })
        }
    }
    
    const getContextMenuItems = (params) => {
        const { node } = params;
        return [
          {
            name: 'New',
            action: () => {
              handleAddNew()
            },
            icon: <i class="bi bi-plus-lg bi-lg"></i>,
          },
          {
            name: 'Modify',
            action: () => {
              handleModify(node)
            },
            icon: <i class="bi bi-pencil"></i>,
            disabled: !node && !node?.data
          },
          {
            name: 'Delete',
            action: () => {
              handleDelete(node)
            },
            icon: <i class="bi bi-trash3"></i>,
            disabled: !node && !node?.data
          },
    
        ]
    }

    return <>
        <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
                <Tab.Container defaultActiveKey="smtpcredentials">
                    <Nav variant='tabs' className='fs-6 border' >
                        <Nav.Item><Nav.Link eventKey="smtpcredentials">SMTP Credentials</Nav.Link></Nav.Item>
                        <Nav.Item className='ms-auto'>
                            <Button title="New SMTP Credentials" className={`btn btn-primary mx-1 float-right`} onClick={() => {setValidated(false);setShowForm(true);}}><i class={`bi bi-plus text-white`} style={{fontWeight:'bold'}}></i></Button>
                            <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={()=>gridRef.current.api?.refreshServerSide([])}><i class={`bi bi-arrow-repeat`}></i></Button>
                        </Nav.Item>
                    </Nav>
                    <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation={true} mountOnEnter={true} unmountOnExit={true} >
                        <Tab.Pane className='w-100 h-100' eventKey="smtpcredentials" title="Campaigns">
                            <div className="m-0 p-0 h-100 d-flex flex-row">
                                <div className="border h-100 ag-theme-balham" style={{width: "100%"}}>
                                    <AgGridReact
                                        ref={gridRef}
                                        defaultColDef={defaultColDef}
                                        onGridReady={onGridReady}
                                        columnHoverHighlight={'true'}
                                        suppressRowGroupHidesColumns={'true'}
                                        cacheBlockSize={50}
                                        rowModelType={'serverSide'}
                                        columnDefs={columns}
                                        // serverSideDatasource={datasource}
                                        getContextMenuItems={(params) => getContextMenuItems(params)}
                                    />
                                </div>
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
                </Tab.Container>
            </div>

            <Modal show={showForm} size="lg" centered>
            <Modal.Header className='py-2 bg-primary text-white'>
                <div className="fs-5">{isUpdate ? "Update SMTP Credentials" : "Create SMTP Credentials"}</div>
            </Modal.Header>
            <Modal.Body className='p-3 shadow'>
                <div className='d-flex flex-column'>
                    <Form noValidate validated={validated} onSubmit={(e) => isUpdate ? handleUpdate(e) : handleSubmit(e)}>
                        <Row className="mb-3">
                            <Col xs={12} md={4} >
                                <Form.Group >
                                        <Form.Label>Title{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Title"
                                            value={formData.title}
                                            name='title'
                                            onChange={handleChange}
                                            required
                                            // isInvalid={!formData.title}
                                            pattern={'^[a-zA-Z 0-9]+$'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid title.
                                        </Form.Control.Feedback>
                                    </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group >
                                        <Form.Label>Host{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Host"
                                            value={formData.host}
                                            name='host'
                                            onChange={handleChange}
                                            required
                                            pattern={'^[a-z.A-Z0-9]+$'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid host.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group >
                                        <Form.Label>Port Number{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            type="number"
                                            placeholder="Port"
                                            value={formData.port}
                                            name='port'
                                            onChange={handleChange}
                                            required
                                            pattern={'^[0-9]{2,4}$'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid port number.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={8}>
                                <Form.Group >
                                        <Form.Label>Auth User{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Auth User"
                                            value={formData.authuser}
                                            name='authuser'
                                            onChange={handleChange}
                                            required
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid auth user.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={4}>
                                <Form.Group >
                                        <Form.Label>Auth Password{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            type="password"
                                            placeholder="Auth Password"
                                            value={formData.authpass}
                                            name='authpass'
                                            onChange={handleChange}
                                            required
                                            // pattern={'/[a-z]+/g'}
                                            minLength={6}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid auth password. Letters should be more than 5
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row className="mb-3">
                            <Col xs={12} md={6}>
                                <Form.Group >
                                        <Form.Label>From Email{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            type="email"
                                            placeholder="From Email"
                                            value={formData.from}
                                            name='from'
                                            onChange={handleChange}
                                            required
                                            // isInvalid={formSubmitted && !formData.host}
                                             pattern={'^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid From Email.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                            <Col xs={12} md={6}>
                                <Form.Group >
                                        <Form.Label>Display Name{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                        <Form.Control
                                            type="text"
                                            placeholder="Display Name"
                                            value={formData.displayname}
                                            name='displayname'
                                            onChange={handleChange}
                                            required
                                            // isInvalid={formSubmitted && !formData.host}
                                            pattern={'^[a-zA-Z 0-9]+$'}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please provide a valid Display Name.
                                        </Form.Control.Feedback>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Control
                                            type="hidden"
                                            value={formData.id}
                                            name='id'
                                        />

                            <div className='p-2 d-flex justify-content-end'>
                                <Button className='mx-1' variant="secondary" onClick={() => { setShowForm(false);setFormData({});setIsupdate(false);setValidated(false)}}>
                                    Cancel
                                </Button>
                            
                                <Button type='submit' className={`mx-1`} variant="primary">
                                    {isUpdate===true ? 'Update' : 'Add'}
                                </Button>
                            
                            </div>
                        
                    </Form>
                    </div>
            </Modal.Body>
        </Modal>
    </>
}

export default SMTPCredentials;