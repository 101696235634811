export const CRAWL_NOT_STARTED = 0;
export const CRAWL_IN_PROGRESS = 1;
export const CRAWL_COMPLETED = 2;
export const CRAWL_ABORTED = 3;
export const CRAWL_PAUSED = 4;

export const CRAWL_TXT_NOT_STARTED = 'Not Started';
export const CRAWL_TXT_IN_PROGRESS = 'In Progress';
export const CRAWL_TXT_COMPLETED = 'Completed';
export const CRAWL_TXT_ABORTED = 'Aborted';
export const CRAWL_TXT_PAUSED = 'Paused';
