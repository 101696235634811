import React from "react";
import Image from 'react-bootstrap/Image';

export default function MobileView(){
    return(
        <>
         <div className="d-flex align-content-middle" style={{height: "100vh", backgroundColor: "#BFD7ED"}}>
          <div 
            className="mx-auto my-auto rounded-4 border border-5 border-dark d-flex flex-column justify-content-center text-center" 
            style={{width: "60%", height: "70%", minWidth: "260px", backgroundImage: "linear-gradient(to top, #68BBE3, #055C9D)", boxShadow: "6px 6px 18px #000000"}}
          >
            <p><Image style={{width: "50%", minWidth: "220px"}} src="wowleads-white.png" alt="WowLeads" /></p>
            <p><span className="text-light text-break" style={{fontWeight: "bolder", fontSize: "20px", textShadow: "1px 1px 2px #000000"}} >Mobile App<br/>Coming Soon ...</span></p>
            <p><span className="fs-6 text-light" >Stay Tuned!</span></p>
          </div>
        </div>
        </>
    )
}