import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { io } from 'socket.io-client';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';

import SelectCategories from './selectcategories';
import SelectPostalCodes from './selectpostalcodes';
import '../App.css';
import * as consts from '../consts';

const URL = process.env.REACT_APP_WS_URL;
const socket = io(URL, {
  autoConnect: false,
  transports: ['websocket']
});

function CustomCrawl(props) {
    const [isCrawling, setIsCrawling] = useState(false);
    const [isBackground, setIsBackground] = useState(false);
    const [selectedKeywords, setSelectedKeywords] = useState([]);
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [selectedGeoData, setSelectedGeoData] = useState([]);
    const [shouldAppend, setShouldAppend] = useState(false);
    const [isConnected, setIsConnected] = useState(false);
    const [socketId, setSocketId] = useState(0);
    const [editListName, setEditListName] = useState(false);
    const [listName, setListName] = useState('');
    const [selectedJob, setSelectedJob] = useState(props.jid);

    useEffect(() => {
      function onConnect() {
        setIsConnected(true);
      }

      function onDisconnect() {
        setSocketId(0);
        setIsConnected(false);
      }

      function onUserConnected(value) {
        // do something
      }
  
      function onStatusUpdate(value) {
        props.stu(value);

        if (value === 'Completed') { 
          props.aju(0);
          props.lpu(0);
          props.pst(0);
          props.stu('Completed');
          socket.disconnect();
          props.icu(false);
          setIsCrawling(false);
          setIsBackground(false);
          props.pfjr();
          props.pfr();
        }
      }

      function onLeadCountUpdate(value) {
        const leadCount = parseInt(value);
        props.lcu(leadCount);
      }

      function onPlaceProgressUpdate(value) {
        const placeProgress = parseFloat(value);
        props.ppu(placeProgress);
      }

      function onPlacesRemainingUpdate(value) {
        const placesRemaining = parseInt(value);
        props.pru(placesRemaining);
      }

      function onLeadProgressUpdate(value) {
        var leadProgress = parseInt(value);
        props.lpu(leadProgress);
      }

      function onLeadStatusUpdate(value) {
        var leadStatus = parseInt(value);
        props.lsu(leadStatus);

        if ((selectedJob > 0) && (!isBackground)) {
          setIsBackground(true);
          props.aju(selectedJob);
        };
      }

      socket.on('connect', onConnect);
      socket.on('disconnect', onDisconnect);
      socket.once('user_connected', onUserConnected);
      socket.on('status', onStatusUpdate);
      socket.on('lcount', onLeadCountUpdate);
      socket.on('pprogress', onPlaceProgressUpdate);
      socket.on('premaining', onPlacesRemainingUpdate);
      socket.on('lprogress', onLeadProgressUpdate);
      socket.on('lstatus', onLeadStatusUpdate);
  
      return () => {
        socket.off('connect', onConnect);
        socket.off('disconnect', onDisconnect);
        socket.off('user_connected', onUserConnected);
        socket.off('status', onStatusUpdate);
        socket.off('lcount', onLeadCountUpdate)
        socket.off('pprogress', onPlaceProgressUpdate)
        socket.off('premaining', onPlacesRemainingUpdate)
        socket.off('lprogress', onLeadProgressUpdate)
        socket.off('lstatus', onLeadStatusUpdate);
      };
    }, [props]);

    function initSocketConnection(jobId) {
      if (isConnected) { socket.disconnect() };
      setSocketId(jobId);
      socket.io.opts.query = `jobId=${jobId}`;
      socket.connect();
    }

    function updateSelectedKeywords(value) {
      setSelectedKeywords(value);
      // props.kws(value);
    }
  
    function updateSelectedCategories(value) {
      setSelectedCategories(value);
      // props.cas(value);
    }
  
    function updateSelectedGeoData(value) {
      setSelectedGeoData(value);
    }
  
    function canStartCrawl () {
      var status = 'disabled';

      if (((selectedKeywords.length > 0) || (selectedCategories.length > 0)) 
        && ((selectedGeoData[0]?.locgroup > 0) || (selectedGeoData[0]?.country > 0) || ((selectedGeoData[0]?.country === 0) && (selectedGeoData[0]?.pcodes.length > 0)))
        ) {
          status = ''
        }
      return status
    }

    function crawl() {
      if ((props.jstatus !== consts.CRAWL_PAUSED) && (canStartCrawl() === 'disabled')) return;

      try {
        props.lcu(0);

        const jobId = selectedJob;
        if (jobId > 0) {
          axios.get(`/crawljob/${jobId}/${socketId}`)
          .then((res) => {
            // process result
          })
          .catch((error) => {
            // process error
            console.log(error);
          })
        } else {
          throw new Error('Unable to initiate the crawl job')
        }
      } catch (error) {
        console.log(error);
      }
    }

    useEffect(() => {
      if (props.resumeCrawl) { 
        resumeCrawl() 
      };
    }, [props.resumeCrawl]);

    useEffect(() => {
      if (props.pauseCrawl) { 
        endCrawl(consts.CRAWL_PAUSED)
      };
    }, [props.pauseCrawl]);

    useEffect(() => {
      if (props.abortCrawl) { 
        endCrawl(consts.CRAWL_ABORTED)
      };
    }, [props.abortCrawl]);

    useEffect(() => {
      if (isCrawling) { crawl() }
    }, [isCrawling]);

    useEffect(() => {
      if (props.jid !== selectedJob) {
        setSelectedJob(props.jid);
        setShouldAppend(false);
        initSocketConnection(props.jid);
      }
    }, [props.jid])

    function changeListName (e) {
      setListName(e.target.value)
    }

    function cancelCrawl () {
      setListName('');
      setEditListName(false);
    }

    async function proceedWithCrawl () {
      setEditListName(false);

      try {
        var jobId = 0;
        if (shouldAppend) {
          jobId = selectedJob;
        } else {
          props.lcu(0);
          
          var userId = props.uid;        
          const selKeywords = selectedKeywords.map(o => o['value']);
          const selCategories = selectedCategories.map(o => o['value']);
          
          await axios.post(`/newjob`, { userId: userId, jobName: listName, keywords: selKeywords, categories: selCategories, geodata: selectedGeoData })
          .then((res) => {
            jobId = res.data.insertId;
          })
          .catch((error) => {
            jobId = 0;
          })
          props.sju(jobId);
          props.pfjr();
          props.pfr();
        }
        setSelectedJob(jobId);
        props.aju(jobId);
        initSocketConnection(jobId);
        setIsCrawling(true);
        props.icu(true);
        props.pst(Date.now());
      } catch (error) {
        // handle error(s)
        alert(error.message)
      }
    }

    function onStartBtnClick() {
      var jobNames = [];
      selectedKeywords.map((kw) => jobNames.push(kw.label));
      selectedCategories.map((cat) => jobNames.push(cat.label));
      setListName('List of ' + jobNames.join(', '));

      if (shouldAppend) {
        proceedWithCrawl()
      } else {
        setEditListName(true)
      }
    }

    function resumeCrawl() {
      const jobId = props.jid;
      props.sju(jobId);
      props.aju(jobId);

      initSocketConnection(jobId)
      setIsCrawling(true);
      props.icu(true);
    }

    async function endCrawl(jobStatus) {
      const activeJob = props.ajid;
      var stopCrawl = isCrawling || isBackground;
      
      if (stopCrawl) {
        if (activeJob > 0) {
          await axios.post(`/updatejobstatus/${activeJob}/${jobStatus}`)
          .then((res) => {
            // process result
          })
          .catch((error) => {
            // process error
          })
          props.sju(activeJob);
          props.pfjr();
          props.pfr();
        }
      }
    }
    
    return (
        <React.Fragment>
          <div className='p-1 border bg-light'>
            <SelectCategories plid={props.plid} plan={props.plan} updateKeywords={updateSelectedKeywords} updateCategories={updateSelectedCategories} isCrawling={isCrawling} />
          </div>
          <div className='p-1 border bg-light'>
            <SelectPostalCodes updateGeoData={updateSelectedGeoData} isCrawling={isCrawling} />
          </div>
          <div className={`p-1 border bg-light ${((selectedJob > 0) && (props.jstatus !== consts.CRAWL_PAUSED) && (canStartCrawl() !== 'disabled') && false) ? 'd-flex' : 'd-none'}`}>
            <Form.Check 
              id='add_to_current' 
              type='checkbox' 
              label="Append to current list" 
              checked={shouldAppend} 
              onChange={(e) => setShouldAppend(e.target.checked)} 
              disabled={isCrawling}
            />
          </div>
          <div className={`p-1 d-flex justify-content-center align-items-center`}>
            <Button 
              variant="primary"
              className={`btn ${canStartCrawl()} ${(isCrawling || isBackground) ? 'd-flex' : 'd-flex'}`} 
              disabled={(isCrawling || isBackground)}
              onClick={onStartBtnClick}>
              Start Crawling
            </Button>
            <Modal show={editListName} onHide={cancelCrawl} centered>
              <Modal.Body className='p-2 shadow'>
                <div className='d-flex flex-column'>
                  <Form.Group className="m-3">
                    <Form.Label className='fs-6 fw-bold'>Please choose a name for your list</Form.Label>
                    <Form.Control
                      type='text'
                      defaultValue={listName}
                      placeholder='List Name'
                      autoFocus
                      onChange={changeListName}
                    />
                  </Form.Group>
                </div>
                <div className='p-2 d-flex justify-content-end'>
                  <Button className='mx-1' variant="secondary" onClick={cancelCrawl}>
                    Cancel
                  </Button>
                  <Button className={`mx-1 ${(listName === '') ? 'disabled' : ''}`} variant="primary" onClick={proceedWithCrawl}>
                    Proceed
                  </Button>
                </div>
              </Modal.Body>
            </Modal>
          </div>
        </React.Fragment>
    )
}

export default CustomCrawl;