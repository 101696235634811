import React, { useState, useEffect } from 'react';
import Image from 'react-bootstrap/Image';
import logo from './assets/wowleads_white.png';
import { BrowserRouter as Router, Routes, Route, Link ,Navigate} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

import "./App.scss";
import './App.css';

import Main from './components/main';
import Pmain from './components/pmain';
import CMain from './campaigns/formcampaign/cmain';
import LoginPage from './components/login';
import SignupPage from './components/signup';
import axios from 'axios';
import LogoutOnUnload from './components/logoutonunload';
import AdminDashboard from './components/admindashboard';
import EncryptPassword from './components/encryptpassword';
import ProfileDropdown from './components/profiledropdown';
import VerifyEmail from './components/verifyemail';
import ForgotPassword from './components/forgotpassword';
import ChnageUserPassword from './components/chnageUserPassword';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import AccountDetails from './components/accountdetails';
import Pricing from './components/pricing';


import CampaignsMain from './campaigns/campaignsmain'
import SMTPCredentials from './campaigns/emailcampaign/smtpcredentials';
import EmailTemplates from './campaigns/emailcampaign/emailtemplates';
import Campaigns from './campaigns/formcampaign/campaigns';
import ContactFormTemplates from './campaigns/formcampaign/contactformtemplates';
import EmailCampaigns from './campaigns/emailcampaign/campaigns';
import MobileView from './components/mobileview';
// import Campaigns from './campaigns/campaigns';

const MIN_SCREEN_WIDTH = 768;

function App() {
  const [mobile, setMobile] = useState(window.innerWidth < MIN_SCREEN_WIDTH);
  const [isLoggedIn, setIsLoggedIn] = useState(() => {return JSON.parse(sessionStorage.getItem('isLoggedIn')) || false});
  const [loggedInUser, setLoggedInUser] = useState(sessionStorage.getItem('email') || '');
  const [loggedInUserName, setLoggedInUserName] = useState(sessionStorage.getItem('username') || '');
  const [loggedInUserId, setLoggedInUserId] = useState(localStorage.getItem('userid') || -1);
  const [loggedInPlanId, setLoggedInPlanId] = useState(sessionStorage.getItem('planid') || 0);
  const [loggedInUserProfile, setLoggedInUserProfile] = useState(sessionStorage.getItem('userimg') === 'null' ? null : sessionStorage.getItem('userimg') || null);
  const [loggedInUserPlan, setLoggedInUserPlan] = useState(() => { return JSON.parse(sessionStorage.getItem('userplan')) || null });
  const [isProfileDropdownVisible, setIsProfileDropdownVisible] = useState(false);
  const [sessionId, setSessionId] = useState(localStorage.getItem('sessionid') || '');
  const [showSessionPopup, setShowSessionPopup] = useState(false);
  const [userData,setUserData]=useState(null)
  
  const handleCallback = (bStatus, email, userid, planid, userimg, userplan, username, session, userdata) => {
    setIsLoggedIn(bStatus);
    setLoggedInUser(email)
    setLoggedInUserId(userid);
    setLoggedInPlanId(planid);
    setLoggedInUserProfile(userimg);
    setLoggedInUserPlan(userplan);
    setLoggedInUserName(username);
    setSessionId(session?.id)
    setUserData(userdata)

    sessionStorage.setItem('isLoggedIn', JSON.stringify(bStatus));
    sessionStorage.setItem('email', email);
    sessionStorage.setItem('userid', userid);
    sessionStorage.setItem('planid', planid);
    sessionStorage.setItem('userimg', userimg);
    sessionStorage.setItem('userplan', JSON.stringify(userplan));
    sessionStorage.setItem('username', username);
    sessionStorage.setItem('sessionid', session?.id);
    sessionStorage.setItem('sessiontoken', session?.sessionToken);
    sessionStorage.setItem('userdata', JSON.stringify(userdata));


    localStorage.setItem('userid', userid);
    localStorage.setItem('sessionid', session?.id);
    localStorage.setItem('sessiontoken', session?.sessionToken);
    console.log("userdata",userdata)
    const eventSource = initializeEventSource(userid, session?.id);

    // Cleanup function
    return () => {
      if (eventSource) eventSource.close();
    };
  };

  const initializeEventSource = (userId, sessionId) => {
    const eventSource = new EventSource(`${process.env.REACT_APP_WS_URL}/events?userId=${userId}&sessionId=${sessionId}`);
  
    eventSource.onmessage = (event) => {
      const eventData = JSON.parse(event.data);
      if (eventData.type === 'SESSION_INVALIDATED') {
        setIsLoggedIn(false);
        if (eventSource) eventSource.close();
      }else if (eventData.type === 'ANOTHER_LOGIN') {
        setIsLoggedIn(false);
        localStorage.clear();
        sessionStorage.clear();
        if (eventSource) eventSource.close();
      }
    };
  
    return eventSource;
  };

  useEffect(() => {
    const loggedInUserId = localStorage.getItem("userid");
    if (loggedInUserId) {
      const sessionId = localStorage.getItem('sessionid');
      const fetchData = async () => {
        try {
          const publicIP = await axios.get('https://api.ipify.org?format=json');
          const ipInforesponse = await axios.get(`/ipInfo/${publicIP.data.ip}`);
          const ipInfo = ipInforesponse.data;
          const userdata = await axios.get(`/user/${loggedInUserId}`);
          console.log("userdata",userdata)
  
          const response = await axios.post('/validatesession', { loggedInUserId, sessionId, ipInfo });
          if (response.status === 205) {
            // User not found, show Session popup
            setShowSessionPopup(true);
            return;
          }
  
          if (response.statusText === "OK") {
            const userPlan = {
              id: userdata.data[0].planid,
              plan: userdata.data[0].plan,
              maxparallelcrawls: userdata.data[0].maxparallelcrawls,
              maxunmaskedleads: userdata.data[0].maxunmaskedleads,
              maxleadsperlist: userdata.data[0].maxleadsperlist,
              mailverificationkey: userdata.data[0].mailverificationkey,
              iskeywords:userdata.data[0].iskeywords
            };
            const udata = {
              isformcampaigns : userdata.data[0].isformcampaigns,
              isemailcampaigns : userdata.data[0].isemailcampaigns
            }
            console.log("udata",udata)
            localStorage.setItem('sessionid', response.data.id);
            localStorage.setItem('sessiontoken', response.data.sessionToken);
            sessionStorage.setItem('sessionid', response.data.id);
            sessionStorage.setItem('sessiontoken', response.data.sessionToken);
            sessionStorage.setItem('isLoggedIn', true);
            sessionStorage.setItem('email', userdata.data[0].email);
            sessionStorage.setItem('userid', loggedInUserId);
            sessionStorage.setItem('planid', userdata.data[0].planid);
            sessionStorage.setItem('userimg', null);
            sessionStorage.setItem('userplan', JSON.stringify(userPlan));
            sessionStorage.setItem('username', userdata.data[0].firstname);
            sessionStorage.setItem('userdata', JSON.stringify(udata));
            
            setIsLoggedIn(true);
            setLoggedInUser(userdata.data[0].email);
            setLoggedInUserName(userdata.data[0].firstname);
            setLoggedInUserId(loggedInUserId);
            setUserData(udata)
            // setisformcampaigns(userdata.data[0].isformcampaigns)
            setLoggedInPlanId(userdata.data[0].planid);
            setLoggedInUserProfile(localStorage.getItem('userimg') === 'null' ? null : localStorage.getItem('userimg'));
            setLoggedInUserPlan(userPlan);
            setSessionId(response.data.id);

            const eventSource = initializeEventSource(loggedInUserId, sessionId);
            window.location.href = '/main';
            // Cleanup function
            return () => {
              if (eventSource) eventSource.close();
            };
            
          } else {
            setIsLoggedIn(false);
            localStorage.clear();
            sessionStorage.clear();
          }
        } catch (error) {
          setIsLoggedIn(false);
        }
      };
    
      if(sessionStorage.getItem('sessionid') === null){
        fetchData();
      }else{
        setIsLoggedIn(true);
        setLoggedInUser(sessionStorage.getItem('email'))
        setLoggedInUserId(sessionStorage.getItem('userid'));
        setLoggedInPlanId(sessionStorage.getItem('planid'));
        setLoggedInUserProfile(null);
        setLoggedInUserPlan(() => { return JSON.parse(sessionStorage.getItem('userplan'))});
        setLoggedInUserName(sessionStorage.getItem('username'));
        setSessionId(sessionStorage.getItem('sessionid'))
        setUserData(() => { return JSON.parse(sessionStorage.getItem('userdata'))})

      }
    
    }
    
  }, []);

  window.addEventListener('storage', (event) => {
    if (event.key === 'sessiontoken') {
        const currentSessionId = sessionStorage.getItem('sessiontoken');
        const newActiveSessionId = event.newValue;
        if (currentSessionId !== newActiveSessionId) {
            // The session has been invalidated
            handleSessionInvalidation();
        }
    }
  });

  function handleSessionInvalidation() {
      alert("Your session has expired because a new session was started in another tab.");
      window.location.href = 'https://wowleads.com/'; 
  }

  const handleWindowSizeChange = () => {
    setMobile(window.innerWidth < MIN_SCREEN_WIDTH);
  }
  
  useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange);
    }
  }, []);

  const toggleLogin = async () => {
    if (isLoggedIn) {
      try {
        const response = await axios.post('/logout', { sessionid: sessionId })
        if (response.data.success) {
          setIsLoggedIn(false);
          setLoggedInUser(null);
          setLoggedInUserId(null);
          setLoggedInPlanId(null);
          setLoggedInUserProfile(null);
          setLoggedInUserPlan(null);
          setLoggedInUserName(null);
          setSessionId(null);
          setUserData(null)
          sessionStorage.clear();
          localStorage.clear();
        }
        else {
          console.error('Logout API request failed', response.data.error);
        }
      } catch (error) {
        console.log(error)
      }
    }
  }

  const renderTooltip = props => (
    <Tooltip className='shadow' {...props}>Account</Tooltip>
  );
  
  const toggleProfileDropdown = () => {
    setIsProfileDropdownVisible(!isProfileDropdownVisible);
  }

  const handleCreateNewSession = async () => {
    const publicIP = await axios.get('https://api.ipify.org?format=json');
    const ipInforesponse = await axios.get(`/ipInfo/${publicIP.data.ip}`);
    const ipInfo = ipInforesponse.data;
    const userdata = await axios.get(`/user/${loggedInUserId}`);
    const response = await axios.post('/createnewsession', { loggedInUserId, sessionId, ipInfo });
    
    if (response.statusText === "OK") {
      const userPlan = {
        id: userdata.data[0].planid,
        plan: userdata.data[0].plan,
        maxparallelcrawls: userdata.data[0].maxparallelcrawls,
        maxunmaskedleads: userdata.data[0].maxunmaskedleads,
        maxleadsperlist: userdata.data[0].maxleadsperlist,
        mailverificationkey: userdata.data[0].mailverificationkey,
        iskeywords:userdata.data[0].iskeywords
      };

      const udata = {
        isformcampaigns : userdata.data[0].isformcampaigns,
        isemailcampaigns : userdata.data[0].isemailcampaigns
      }
      localStorage.setItem('sessionid', response.data.id);
      localStorage.setItem('sessiontoken', response.data.sessionToken);
      sessionStorage.setItem('sessionid', response.data.id);
      sessionStorage.setItem('sessiontoken', response.data.sessionToken);
      sessionStorage.setItem('isLoggedIn', true);
      sessionStorage.setItem('email', userdata.data[0].email);
      sessionStorage.setItem('userid', loggedInUserId);
      sessionStorage.setItem('planid', userdata.data[0].planid);
      sessionStorage.setItem('userimg', null);
      sessionStorage.setItem('userplan', JSON.stringify(userPlan));
      sessionStorage.setItem('username', userdata.data[0].firstname);
      sessionStorage.setItem('userdata', JSON.stringify(udata));

      setIsLoggedIn(true);
      setLoggedInUser(userdata.data[0].email);
      setLoggedInUserName(userdata.data[0].firstname);
      setLoggedInUserId(loggedInUserId);
      setLoggedInPlanId(userdata.data[0].planid);
      setLoggedInUserProfile(localStorage.getItem('userimg') === 'null' ? null : localStorage.getItem('userimg'));
      setLoggedInUserPlan(userPlan);
      setSessionId(response.data.id);
      setShowSessionPopup(false);
      setUserData(udata)
      const eventSource = initializeEventSource(loggedInUserId, sessionId);
      window.location.href = '/main';
      // Cleanup function
      return () => {
        if (eventSource) eventSource.close();
      };
      
    }
};

const handleCancelClick = () => {
  setShowSessionPopup(false)
  window.location.href = 'https://wowleads.com/';
};

  return (
    <div className="App">
      <Router>
        <header className="App-header">
          <Navbar collapseOnSelect expand="md" bg="dark" variant="dark" className="p-1 m-0">
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Brand className='p-0' style={{ marginLeft: '40px', marginRight: '40px' }}>
              <Link to={'https://wowleads.com/'} target='_blank'>
                <Image src={logo} width="144" alt="WowLeads" />
              </Link>
            </Navbar.Brand>
            <Container fluid>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto fs-6" >
                  <Nav.Link href="#" className="m-0 p-0">
                    <Link to={'/main'} className={`nav-link`}>
                      Find Leads
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#" className="m-0 p-0">
                    <Link to={'/pmain'} className={`nav-link`}>
                      Find People
                    </Link>
                  </Nav.Link>

                  ${(loggedInUserPlan?.id === 1||(userData?.isformcampaigns==1||userData?.isemailcampaigns==1)) ?<Nav.Link href="#" className={`m-0 p-0`}>
                    <Link to={'/campaigns'} className={`nav-link`}>
                      Campaigns
                    </Link>
                  </Nav.Link>: ''}

                </Nav>
                <Nav className="justify-content-end m-0 fs-6">
                  <Nav.Link href="#" className={`m-0 p-0 ${isLoggedIn ? 'd-none' : 'd-block'}`}>
                    <Link to={'/signup'} className="nav-link">
                      {isLoggedIn ? '' : 'Sign Up'}
                    </Link>
                  </Nav.Link>
                  <Nav.Link href="#" className={`m-0 p-0 ${isLoggedIn ? 'd-none' : 'd-block'}`}>
                    <Link to={'/login'} className="nav-link" onClick={toggleLogin}>
                      {isLoggedIn ? '' : 'Sign In'}
                    </Link>
                  </Nav.Link>
                  {/* <Nav>
                    <Link to={'/login'} className="nav-link fs-6" onClick={toggleLogin}>
                      {isLoggedIn ? 'Sign Out' : 'Sign In'}
                    </Link>
                  </Nav> */}
                  {/* <Nav>
                    <Link>
                      {isLoggedIn ? (<i style={{ color: `var(--plan-${loggedInUserPlan.id}` }} className={`bi bi-star-fill`}></i>) : ''}
                    </Link>
                  </Nav> */}
                </Nav>
                <Nav className="justify-content-end fs-6">
                  <Nav.Link href="#" className={`m-0 p-0 ${mobile ? 'mt-2' : 'ms-2'}`}>
                    <Link to={`/adminPanel`} style={{ width: 36 }} className={`btn btn-primary ${isLoggedIn && (loggedInUserPlan.id === 1) ? 'd-block' : 'd-none'}`}>
                      <i className={`bi bi-sliders`}></i>
                    </Link>
                  </Nav.Link>
                  <Nav.Link className={`m-0 p-0 ${mobile ? 'mt-2' : 'ms-2'}`}>
                    <OverlayTrigger placement="bottom" overlay={renderTooltip}>
                      <Link id='profile-icon' className={`${isLoggedIn ? 'd-block' : 'd-none'} ${loggedInUserProfile ? '' : 'btn btn-sm rounded-circle btn-primary d-flex align-items-center justify-content-center'}`} 
                      onClick={toggleProfileDropdown} style={{ height: 36, width: 36, alignSelf: "center" }}
                      >
                        {isLoggedIn ? (
                          loggedInUserProfile ? (
                            <img src={loggedInUserProfile} alt={loggedInUser.substring(0, 1).toUpperCase()} className="profile-img" style={{ height: 36, width: 36, objectFit: 'fill', borderRadius: '50%' }} />
                          ) : (
                            <span className='fs-5 shadow fw-medium'>{loggedInUserName.substring(0, 1).toUpperCase()}</span>
                          )
                        ) : ''}
                      </Link>
                    </OverlayTrigger>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>

              {isProfileDropdownVisible && (
                <ProfileDropdown email={loggedInUser} onLogout={toggleLogin} closeDropdown={toggleProfileDropdown} loggedInUserId={loggedInUserId} loggedInUserPlan={loggedInUserPlan} loggedInUserName={loggedInUserName} loggedInUserProfile={loggedInUserProfile} />
              )}
            </Container>
          </Navbar>
        </header>
        {showSessionPopup ? (
          <Modal show={showSessionPopup} onHide={() => setShowSessionPopup(false)} centered size='md'>
          <Modal.Header closeButton={true}>
            <h6 className='mb-0 fw-semibold'>Wowleads</h6>
          </Modal.Header>
          <Modal.Body>
            <div>
                <label>
                  WowLeads is currently open in another window. Would you like to close the session in the other window and open Wowleads in this window instead?
                </label>
              <div className='mt-2 d-flex justify-content-end'>
              <Button onClick={handleCancelClick} className='btn-sm me-2' variant="secondary">
                Cancel
              </Button>
              <Button
                onClick={handleCreateNewSession}
                className={`btn-sm`}
                variant="primary"
              >
                Proceed
              </Button>
            </div>
            
            </div>
          </Modal.Body>
        </Modal>
      ) : (
        <Container fluid className="p-0 m-0">
          <div className={`wrapper main-panel`}>
            <Routes>
              <Route
                exact
                path="/"
                element={isLoggedIn || localStorage.getItem('userid') ? 
                  <>
                  {mobile ? 
                    <MobileView />
                  :
                    <>
                      <LogoutOnUnload sessionid={sessionId} /> 
                      <div className='loader-overlay'>
                        <div className="loader-container">
                          <img src="loader.gif" alt="Loading..." className="loader-image" />
                          <div className='fs-1 w-100 text-center'>Welcome to WowLeads</div>
                        </div>
                      </div>
                    </>
                  }
                  </>
                    : 
                    <LoginPage parentCallback={handleCallback} />
                  }
              />
              <Route
                exact
                path="/signup"
                element={ mobile ? <MobileView /> : <SignupPage parentCallback={handleCallback} />}
              />
              <Route
                exact
                path="/login"
                element={ mobile ? <MobileView /> : <LoginPage parentCallback={handleCallback} />}
              />
              <Route
                exact
                path="/main"
                element={isLoggedIn || localStorage.getItem('userid') ? 
                  <>
                   {mobile ? 
                    <MobileView />
                  :
                    <>
                    <LogoutOnUnload sessionid={sessionId} />
                    <Main uid={loggedInUserId} plid={loggedInPlanId} plan={loggedInUserPlan} />
                    </>
                  }
                  </>
                  :
                  <LoginPage parentCallback={handleCallback} />}
              />
              <Route
                exact
                path="/pmain"
                element={isLoggedIn ? 
                  <>
                   {mobile ? 
                    <MobileView />
                  :
                    <>
                      <LogoutOnUnload sessionid={sessionId} />
                      <Pmain uid={loggedInUserId} plid={loggedInPlanId} plan={loggedInUserPlan} />
                    </>
                  }
                  </>
                  :
                  <LoginPage parentCallback={handleCallback} />}
              />
              <Route
                exact
                path="/campaigns"
                element={isLoggedIn || localStorage.getItem('userid') ? 
                  <>
                  {mobile ? 
                    <MobileView />
                  :
                    <>
                      <LogoutOnUnload sessionid={sessionId} />
                      <CampaignsMain loggedInUserPlan={loggedInUserPlan} user={userData}/> 
                    </>
                  } 
                  </>
                    :
                    <LoginPage parentCallback={handleCallback} />
                  }
              >
                
                <Route path="/campaigns" element={ mobile ? <MobileView /> : <></>}/>
                <Route path="/campaigns/form-campaigns" element={ mobile ? <MobileView /> : <Campaigns uid={loggedInUserId}/>}/>
                <Route path="/campaigns/form-templates" element={ mobile ? <MobileView /> : <ContactFormTemplates uid={loggedInUserId}/>}/>
                <Route path="/campaigns/smtp-credentials" element={ mobile ? <MobileView /> : <SMTPCredentials uid={loggedInUserId}/>}/>
                <Route path="/campaigns/mail-templates" element={ mobile ? <MobileView /> : <EmailTemplates uid={loggedInUserId}/>}/>
                <Route path="/campaigns/email-campaigns" element={ mobile ? <MobileView /> : <EmailCampaigns uid={loggedInUserId}/>}/>
                
                </Route>
               {/* <Route
                exact
                path="/campaigns"
                element={isLoggedIn || localStorage.getItem('userid') ? 
                  <>
                    <LogoutOnUnload sessionid={sessionId} />
                    <CMain uid={loggedInUserId} /> 
                  </>
                    :
                    <LoginPage parentCallback={handleCallback} />
                  }
              /> */}
              <Route
                exact
                path="/adminPanel"
                element={isLoggedIn && (loggedInUserPlan.id === 1) ? 
                  <>
                    {mobile ? 
                      <MobileView />
                    :
                    <>
                      <LogoutOnUnload sessionid={sessionId}/> 
                      <AdminDashboard loggedInUserId={loggedInUserId} sessionid = {sessionId} />
                    </>
                    } 
                  </>
                  :
                  <LoginPage parentCallback={handleCallback} />
                }
              />
              <Route
                exact
                path="/encryptPassword"
                element={ mobile ? <MobileView /> : <EncryptPassword />}
              />
              <Route
                exact
                path="/verifyEmail"
                element={ mobile ? <MobileView /> : <VerifyEmail />}
              />
              <Route
                exact
                path="/forgotPassword"
                element={ mobile ? <MobileView /> : <ForgotPassword />}
              />
            <Route
                exact
                path="/chnagePassword"
                element={ mobile ? <MobileView /> : <ChnageUserPassword />}
              />
              <Route
                exact
                path="/accountdetails"
                element={isLoggedIn || localStorage.getItem('userid') ? 
                <>
                  {mobile ? 
                    <MobileView />
                  :
                  <>
                    <LogoutOnUnload sessionid={sessionId} />
                    <AccountDetails loggedInUserId={loggedInUserId}/> 
                  </>
                  } 
                </>
                  :
                  <LoginPage parentCallback={handleCallback} />
                }
              />
              <Route
                exact
                path="/pricing"
                element={<Pricing />}
              />
            </Routes>
          </div>
        </Container>
      )}  
      </Router>
    </div>
  )
}

export default App;
