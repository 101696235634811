import React, { useState, useEffect } from 'react'
import { Col, Row, Card, Button, Modal, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import axios from 'axios'
import ReactApexChart from "react-apexcharts";
import CampaignDetails from './campaigndetails'
import {Tab, Nav } from 'react-bootstrap';

function EmailCampaigns(props) {
    const [campaigns, setCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState(0)
    const [showsummary, setshowsummary] = useState(false)
    const [showdetails, setshowdetails] = useState(false)
    const [summary, setsummary] = useState({})

    const getcampaigns = () => {
        const url = `/emailcampaigns/${props.uid}`
        axios.get(url)
            .then(result => setCampaigns(result.data))
            .catch(err => console.log(err))
    }

    const changeCampaignState = (crid, state) => {
        const url = `/changecampaignstate/${crid}/${state}`
        axios.get(url)
            .then(result => {
                getcampaigns()
            })
            .catch(error => console.log(error))

    }

    useEffect(() => {
        getcampaigns()
    }, [])

    const calcprogress = (N, D) => {

        if (D)
            return Math.round((N / D) * 100)
        else
            return 100
    }

    return (
        <React.Fragment>
            <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
            <Tab.Container defaultActiveKey="campaigns" activeKey="campaigns">
            <Nav variant='tabs' className='fs-6 border' >
                        <Nav.Item><Nav.Link eventKey="campaigns">Email Campaigns</Nav.Link></Nav.Item>
                        <Nav.Item className='ms-auto'>
                            <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={getcampaigns}><i class={`bi bi-arrow-repeat`}></i></Button>
                        </Nav.Item>
            </Nav>
            <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation={true} mountOnEnter={true} unmountOnExit={true} >
                        <Tab.Pane className='w-100 h-100' eventKey="campaigns" title="">
                            {/* <Campaigns setCampaignRefresh={setCampaignRefresh} refresh={campaignRefresh} uid={props.uid} tid={selectedTemplate} /> */}
                            {/* <div className={`h-100 overflow-auto d-flex flex-wrap`}> */}
                            <Row>
                                {campaigns.map((row, index) => (
                                    <Col xs={2} className='m-1' style={{ width: '100%', minWidth: '199px', maxWidth: 'calc(12% - 12px' }}>
                                        <Card
                                            key={index}
                                            onClick={() => { setSelectedCampaign(row.id) }}
                                            className={`text-primary ${(index + 1 === selectedCampaign.id) ? `border-primary shadow-lg` : ''}`}
                                        >
                                            <Card.Header className={`text-white bg-${row.remaining >0 ? 'primary' : 'success'} p-0 align-items-center`}>
                                                <div className="d-flex flex-row">
                                                    <div className="p-2"><span>{`Campaign [ID-${props.uid+'00'+row.id}]`}</span></div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className='p-2 d-flex align-content-end flex-column'>
                                                <Card.Text>
                                                    <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>Leads List</b></Col><Col className="ps-1" title={row.list}>{row.list.length < 18 ? row.list : row.list?.substring(0, 15) + '...'}</Col></Row>
                                                    <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>Started By</b></Col><Col className="ps-1">{row.user.length < 15 ? row.user : row.user?.substring(0, 18) + '...'}</Col></Row>
                                                    {/* <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>Start Time</b></Col><Col className="ps-1">{row.starttime?.substring(0, 16)}</Col></Row>
                                                    {(row.status === 1) ? '' : (
                                                        <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>End Time</b></Col><Col className="ps-1">{row.endtime?.substring(0, 16)}</Col></Row>
                                                    )} */}
                                                    <Row className="mx-0 border-top border-bottom">
                                                        <Col xs={4} className="p-0"><b>Status</b></Col>
                                                        <Col className="ps-1">{`${(row.remaining>0) ? `In Progress`:'Completed'} [${(row.sent)}/${row.total}]  (${calcprogress(row.sent, row.total)}%)`}</Col>
                                                    </Row>
                                                </Card.Text>
                                                <Card.Text>
                                                    <ProgressBar now={calcprogress(row.sent, row.total)} title={`${(row.remaining>0) ? "In Progress" : "Completed"} (${calcprogress(row.sent, row.total)}%)`} />
                                                </Card.Text>
                                                <Card.Text className={`m-0 p-0`}>
                                                    <Row className={`w-100 m-0`}>
                                                        <Col className="p-0 d-flex">
                                                            <Button title="Details" size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => { setshowdetails(true) }}><i class="bi bi-clock-history"></i></Button>
                                                            {/* <Button title="Summary" size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => { setshowsummary(true); setsummary(campaigns[index]) }}><i class="bi bi-bar-chart-line"></i></Button>
                                                            <Button disabled={row.status === 1 ? false : true} size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => { changeCampaignState(row.id, row.ispaused ? 0 : 1) }}><i className={row.status !== 1 ? "bi bi-check-circle" : row.ispaused ? "bi bi-play" : "bi bi-pause"}></i></Button> */}
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                                </Row>
                            {/* </div> */}
                        </Tab.Pane>
                    </Tab.Content>
            </Tab.Container>
            </div>

            <Modal show={showsummary} onHide={() => setshowsummary(false)} centered size="lg">
                <Modal.Header closeButton={true} >
                    <div className="fs-6">Campaign [ID-{summary.id} statistics]</div>
                </Modal.Header>
                <Modal.Body className="m-1 p-3">
                    <div>
                        <ReactApexChart
                            options={{
                                chart: { type: 'bar', toolbar: { show: false } },
                                plotOptions: { bar: { borderRadius: 4, distributed: true, horizontal: true, barHeight: '90%' } },
                                dataLabels: { enabled: true },
                                xaxis: { categories: ['Total', 'Submitted', 'Error', 'Captcha/Aborted', 'Not started', 'Form not found'] },
                                legend: { show: false },
                            }}
                            series={[{ name: "Leads", data: [summary.leadscount, summary.submitted, summary.error, summary.captcha, summary.notstarted, summary.formnotfound] }]}
                            type="bar"
                            height={'240px'}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showdetails} onHide={() => setshowdetails(false)} centered size="xl" >
                <Modal.Header closeButton={true} >
                    <div className="fs-6">Campaign Details [ID-{props.uid}00{selectedCampaign}]</div>
                </Modal.Header>
                <Modal.Body className="m-1 p-2">
                    <CampaignDetails crid={selectedCampaign} uid={props.uid}/>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}



export default EmailCampaigns