import React, { useState, useEffect } from 'react';
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import { Button } from 'react-bootstrap';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

function CheckoutForm({ onSuccess }) {
  const stripe = useStripe();
  const elements = useElements();
  
  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // Use elements.getElement to get a reference to the PaymentElement.
    // const paymentElement = elements.getElement('payment');
    // console.log(paymentElement)
    // Use the stripe.confirmPayment() method to handle the payment process.
    const { error, paymentIntent } = await stripe.confirmPayment( {
      elements,
      confirmParams: {
        // return_url: "http://localhost:3000/adminPanel",
      },
      redirect: 'if_required'
    });

    if (error) {
      onSuccess('Payment Failed.');
      // Handle payment failure
    } else if (paymentIntent && paymentIntent.status === 'succeeded') {
      await axios.post('/updatepaymentstatus', {
        paymentid : paymentIntent.id,
        status : 1
    });
    onSuccess('Payment Successful.');
    } else {
      // Handle other payment statuses if necessary
      console.log('Payment status:', paymentIntent ? paymentIntent.status : 'Unknown status');
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <Button type="submit" disabled={!stripe} className='mt-4 px-4'>
        <b>Pay</b>
      </Button>
    </form>
  );
}

export default function OpenStripe({ api, params, amount, pid, priceid, onSuccess, credits }) {
  const [clientSecret, setClientSecret] = useState('');

  useEffect(() => {
    const fetchClientSecret = async () => {
      try {
        const response = await axios.post(api, { params, amount, pid, priceid, credits });
        setClientSecret(response.data.clientSecret);
      } catch (error) {
        console.error('Error fetching client secret:', error);
      }
    };

    fetchClientSecret();
  }, []);

  

  const options = {
    clientSecret: clientSecret, // Replace '{{CLIENT_SECRET}}' with the actual client secret
  };
  
  return (
    <div>
      {clientSecret && stripePromise && (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm  onSuccess={onSuccess} />
        </Elements>
      )}
    </div>
  );
};


