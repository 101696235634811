import React, { useState, useEffect } from 'react'
import { Col, Row, Card, Button, Modal, ProgressBar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import axios from 'axios'
import ReactApexChart from "react-apexcharts";
import CampaignDetails from './campaigndetails'
import {Tab, Nav } from 'react-bootstrap';

function Campaigns(props) {
    const [campaigns, setCampaigns] = useState([])
    const [selectedCampaign, setSelectedCampaign] = useState(0)
    const [showsummary, setshowsummary] = useState(false)
    const [showdetails, setshowdetails] = useState(false)
    const [summary, setsummary] = useState({})

    const getcampaigns = () => {
        const url = `/getcampaigns/${props.uid}/${props.tid ? props.tid : 0}`
        axios.get(url)
            .then(result => setCampaigns(result.data))
            .catch(err => console.log(err))
    }

    const changeCampaignState = (crid, state) => {
        const url = `/changecampaignstate/${crid}/${state}`
        axios.get(url)
            .then(result => {
                getcampaigns()
            })
            .catch(error => console.log(error))

    }

    useEffect(() => {
        getcampaigns()
    }, [props.tid, props.refresh])

    const calcprogress = (N, D) => {
        if (D) return Math.round((N / D) * 100)
        else return 100
    }

    return (
        <React.Fragment>
            <div className='w-100 h-100 d-flex flex-column overflow-hidden'>
            <Tab.Container defaultActiveKey="campaigns" activeKey="campaigns">
            <Nav variant='tabs' className='fs-6 border' >
                        <Nav.Item><Nav.Link eventKey="campaigns">Form Campaigns</Nav.Link></Nav.Item>
                        <Nav.Item className='ms-auto'>
                            <Button title="Refresh" className={`btn btn-primary mx-1 float-right`} onClick={getcampaigns}><i class={`bi bi-arrow-repeat`}></i></Button>
                        </Nav.Item>
            </Nav>
            <Tab.Content className='w-100' style={{ height: "calc(100% - 34px)" }} animation={true} mountOnEnter={true} unmountOnExit={true} >
                        <Tab.Pane className='w-100 h-100' eventKey="campaigns" title="">
                            {/* <Campaigns setCampaignRefresh={setCampaignRefresh} refresh={campaignRefresh} uid={props.uid} tid={selectedTemplate} /> */}
                            <div className={`h-100 overflow-auto d-flex flex-wrap`}>
                                {campaigns.map((row, index) => (
                                    <Col xs={2} className='m-1' style={{ width: '100%', minWidth: '199px', maxWidth: 'calc(12% - 12px' }}>
                                        <Card
                                            key={index}
                                            onClick={() => { setSelectedCampaign(row.id) }}
                                            className={`text-primary ${(index + 1 === selectedCampaign.id) ? `border-primary shadow-lg` : ''}`}
                                        >
                                            <Card.Header className={`text-white bg-${row.status === 1 ? 'primary' : 'success'} p-0 align-items-center`}>
                                                <div className="d-flex flex-row">
                                                    <div className="p-2"><span>{`Campaign [ID-${index + 1}]`}</span></div>
                                                </div>
                                            </Card.Header>
                                            <Card.Body className='p-2 d-flex align-content-end flex-column'>
                                                <Card.Text>
                                                    <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>Leads List</b></Col><Col className="ps-1" title={row.jname}>{row.jname.length < 18 ? row.jname : row.jname?.substring(0, 15) + '...'}</Col></Row>
                                                    <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>Started By</b></Col><Col className="ps-1">{row.uname.length < 15 ? row.uname : row.uname?.substring(0, 18) + '...'}</Col></Row>
                                                    <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>Start Time</b></Col><Col className="ps-1">{row.starttime?.substring(0, 16)}</Col></Row>
                                                    {(row.status === 1) ? '' : (
                                                        <Row className="mx-0 border-top"><Col xs={4} className="p-0"><b>End Time</b></Col><Col className="ps-1">{row.endtime?.substring(0, 16)}</Col></Row>
                                                    )}
                                                    <Row className="mx-0 border-top border-bottom">
                                                        <Col xs={4} className="p-0"><b>Status</b></Col>
                                        <Col className="ps-1">{`${(row.status === 2) ? `Completed [${(row.success + row.failed + row.formnotfound)}/${row.leadscount}]`: `${row.ispaused===1 ? `Paused ` : `In Progress `}[${(row.success + row.failed + row.formnotfound)}/${row.leadscount}]`}  (${calcprogress((row.success + row.failed + row.formnotfound), row.leadscount)}%)`}</Col>
                                                    </Row>
                                                </Card.Text>
                                                <Card.Text>
                                    <ProgressBar now={calcprogress((row.success + row.failed + row.formnotfound), row.leadscount)} title={`${(row.status === 1) ? "In Progress" : "Completed"} (${calcprogress((row.success + row.failed + row.formnotfound), row.leadscount)}%)`} />
                                                </Card.Text>
                                                <Card.Text className={`m-0 p-0`}>
                                                    <Row className={`w-100 m-0`}>
                                                        <Col className="p-0 d-flex">
                                                            <Button title="Details" size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => { setshowdetails(true) }}><i class="bi bi-clock-history"></i></Button>
                                                            <Button title="Summary" size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => { setshowsummary(true); setsummary(campaigns[index]) }}><i class="bi bi-bar-chart-line"></i></Button>
                                                            <Button disabled={row.status === 1 ? false : true} size="sm" variant="outline-primary" style={{ borderRadius: "50%" }} className="me-1 border border-gray" onClick={() => { changeCampaignState(row.id, row.ispaused ? 0 : 1) }}><i className={row.status !== 1 ? "bi bi-check-circle" : row.ispaused ? "bi bi-play" : "bi bi-pause"}></i></Button>
                                                        </Col>
                                                    </Row>
                                                </Card.Text>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                ))}
                            </div>
                        </Tab.Pane>
                    </Tab.Content>
            </Tab.Container>
            </div>

            <Modal show={showsummary} onHide={() => setshowsummary(false)} centered size="lg">
                <Modal.Header closeButton={true} >
                    <div className="fs-6">Campaign [ID-{summary.id} statistics]</div>
                </Modal.Header>
                <Modal.Body className="m-1 p-3">
                    <div>
                        <ReactApexChart
                            options={{
                                chart: { type: 'bar', toolbar: { show: false } },
                                plotOptions: { bar: { borderRadius: 4, distributed: true, horizontal: true, barHeight: '90%' } },
                                dataLabels: { enabled: true },
                                xaxis: { categories: ['Total', 'Auto Submittable', 'Manual Review', 'Submitted', 'Not processed', 'Form not found', 'Captcha'] },
                                legend: { show: false },
                            }}
                            series={[{ name: "Leads", data: [summary.leadscount, (summary.success), (summary.failed), summary.submitted, (summary.inprogress + summary.notstarted), summary.formnotfound, summary.captcha] }]}
                            type="bar"
                            height={'240px'}
                        />
                    </div>
                </Modal.Body>
            </Modal>

            <Modal show={showdetails} onHide={() => setshowdetails(false)} centered size="xl" dialogClassName="my-modal">
                <Modal.Header closeButton={true}>
                    <div className="fs-6">Campaign [ID-{selectedCampaign} Details]</div>
                </Modal.Header>
                <Modal.Body className="m-1 p-2">
                    <CampaignDetails crid={selectedCampaign}/>
                </Modal.Body>
            </Modal>
        </React.Fragment>
    )
}



export default Campaigns