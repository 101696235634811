import React from 'react';

function LeadFacts(props) {
    const data = props.data.length > 0 ? props.data[0] : [];

    return (
        <div className="m-3">
            <div style={{display: "grid", gridTemplateColumns: "repeat(auto-fill, calc(15%))", gridGap: "15px", justifyContent: "center"}}>
                <LeadFact title={data.name} fact={data.comp_logo} logo={true} />
                <LeadFact icon="bi bi-person-square" title="CEO" fact={data.ceo_name} link={data.ceo_linkedin} />
                <LeadFact icon="bi bi-calendar-check" title="Founded" fact={data.founded} />
                <LeadFact icon="bi bi-coin" title="Est. Revenue" fact={data.revenue} />
                <LeadFact icon="bi bi-people" title="Est. Head Count" fact={data.headcount} />
                <LeadFact icon="bi bi-bank2" title="Funding" fact={data.funding} />
                <LeadFact icon="bi bi-buildings" title="Acquisitions" fact={data.acquisitions} />
                <LeadFact icon="bi bi-piggy-bank" title="Investments" fact={data.investments} />
                <LeadFact icon="bi bi-building-exclamation" title="Type" fact={data.comp_type} multi={true} />
                <LeadFact icon="bi bi-buildings-fill" title="Competitors" fact={data.competitors} multi={true}/>
                <LeadFact icon="bi bi-tags" title="Sectors" fact={data.sectors} multi={true} />
                <LeadFact icon="bi bi-bookmarks" title="SIC Codes" fact={data.sic_codes} multi={true} />
            </div>
        </div>
    )
}

function LeadFact(props) {
    return (<>
        {((props.fact && ((props.multi && (JSON.parse(props.fact).length > 0)) || ((!props.multi) && (props.fact !== ''))))) ? (
        <div title={props.title} className="border border-gray shadow text-center p-2" style={{gridRowEnd: `span ${(props.logo || props.multi) ? 2 : 1}`}}>
            {(props.logo) ? (
                <div className="h-100 d-flex align-items-center justify-content-center">
                    <img style={{maxWidth: "100%"}} src={props.fact} alt="Company Logo" />
                </div>
            ) : (<>
                <p className="fs-5">
                    <i className={`me-2 ${props.icon}`} />
                    {props.title}
                </p>
                {(props.multi) ? (
                    (props.fact && (JSON.parse(props.fact).length > 0)) ? (
                        <p className="fs-6 text-primary">{JSON.parse(props.fact).map(c => (<div>{c}</div>))}</p>
                    ) : (
                        <p className='fs-4 text-secondary'>N/A</p>
                    )
                ) : (
                    <p className="fs-4">
                        {(props.fact === '') ? <span className="text-secondary">N/A</span> : <span className="text-primary">{props.fact}</span>}
                        {props.link ? <a rel="noreferrer" target='_blank' href={props.link}><i className="ms-2 bi bi-linkedin" /></a> : ''}
                    </p>
                )}
            </>)}
        </div>
        ) : ''}
    </>)
}

export default LeadFacts;