import React, { useState, useRef, useCallback, useMemo, useEffect } from "react";
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'
import 'ag-grid-enterprise'
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import axios from 'axios';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const serverApiUrl = '/api/adminDashboard';

const columns = [
    { field: 'id', headerName: 'Id', hide:true, filter: 'agNumberColumnFilter', width: 300, headerClass: 'custom-header-class' },
    { field: 'name', headerName: 'Name', filter: 'agTextColumnFilter', width: 300, headerClass: 'custom-header-class', },
    { field: 'l1_name', headerName: 'Level 1 Name', filter: 'agTextColumnFilter', width: 400, headerClass: 'custom-header-class', },
    { field: 'l2_name', headerName: 'Level 2 Name', filter: 'agTextColumnFilter', width: 400, headerClass: 'custom-header-class', },
]

function AGgridListbox({ setShowAddLocationGroupModal, locationRowData, isModifyLocationGroup, onSuccess }) {
    const gridRef = useRef();
    const secondGridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [selectedRows, setSelectedRows] = useState()
    const [groupName, setGroupName] = useState('');
    const [rowData1, setRowData1] = useState('')
    const [message, setMessage] = useState('');
    const [messageType, setMessageType] = useState();

    useEffect(() => {
        async function fetchData() {
            if (isModifyLocationGroup) {
                setGroupName(locationRowData.name)
                try {
                    const groupid = locationRowData.id
                    const response = await axios.get(`/locationgroupdetails/${groupid}`);
                    setRowData1(response.data)
                    console.log(response.data); // Assuming you want to log the response data
                } catch (err) {
                    console.error(err);
                }
            }
        }

        fetchData();
    }, []);

    const sideBar = useMemo(() => {
        return {
            toolPanels: [
                {
                    id: 'columns',
                    labelDefault: 'Columns',
                    labelKey: 'columns',
                    iconKey: 'columns',
                    toolPanel: 'agColumnsToolPanel',
                },
                {
                    id: 'filters',
                    labelDefault: 'Filters',
                    labelKey: 'filters',
                    iconKey: 'filter',
                    toolPanel: 'agFiltersToolPanel',
                }
            ],
            defaultToolPanel: '',
        };
    }, []);

    const datasource = {
        getRows: function (params) {
            const { request, tablename, db } = params;

            const tablenameset = tablename ? tablename : 'postalcodes';
            const setdb = db ? db : 'system';
            fetch(serverApiUrl, {
                method: 'post',
                body: JSON.stringify({ ...request, tablenameset, setdb }),
                headers: { "Content-Type": "application/json; charset=utf-8" }
            })
                .then(httpResponse => httpResponse.json())
                .then(response => {
                    params.successCallback(response.rows, response.lastRow);
                })
                .catch(error => {
                    console.error(error);
                    params.failCallback();
                });
        }
    };

    const gridOptions = {
        rowModelType: 'serverSide',
        columnDefs: columns,

        defaultColDef: {
            sortable: true,
            filter: true,
            resizable: true,
            floatingFilter: true,
            enableValue: true,
            enableRowGroup: true,
            enablePivot: true,
        },

        sideBar: sideBar,
        rowSelection: 'multiple',
        columnHoverHighlight: true,
        suppressRowGroupHidesColumns: true,
        cacheBlockSize: 50,

    }

    const onGridReady = useCallback((params) => {
        gridOptions.api.setServerSideDatasource(datasource);
    }, []);

    const onSelectionChanged = useCallback(() => {
        const selectedRows = gridRef.current.api.getSelectedRows();
        setSelectedRows(selectedRows);
    }, []);

    const onSecondGridSelectionChanged = useCallback(() => {
        const selectedRows = secondGridRef.current.api.getSelectedRows();
        setSelectedRows(selectedRows);
    }, []);

    const handleAddClick = () => {
        if (selectedRows && selectedRows.length > 0) {
            setRowData1(prevRowData1 => [...prevRowData1, ...selectedRows]);
        }
    }

    const handleRemoveClick = () => {
        if (selectedRows && selectedRows.length > 0) {
            const filteredRows = rowData1.filter(row => !selectedRows.includes(row));
            setRowData1(filteredRows);
        }
    }

    const handleChange = (event) => {
        setGroupName(event.target.value);
    };

    const handleSaveClick = async () => {
        // Check if groupName or rowData1 is null or blank
        if (!groupName || !rowData1 || !rowData1.length) {
            setMessageType('danger');
            setMessage('Group name or row data cannot be empty');
            setTimeout(() => {
                setMessage('');
            }, 2000);
            return; // Stop further execution
        }

        try {
            if (isModifyLocationGroup) {
                const response = await axios.post('/updatelocations', { name: groupName, rowData: rowData1, id: locationRowData.id });
                if (response.status === 200) {
                    setMessageType('success');
                    setMessage('Location group has been updated.');
                }

            } else {
                const response = await axios.post('/addlocations', { name: groupName, rowData: rowData1 });
                if (response.status === 200) {
                    setMessageType('success');
                    setMessage('Location group has been created.');
                }
            }
            setTimeout(() => {
                setMessage('');
                setShowAddLocationGroupModal(false)
                onSuccess()
            }, 800);
        } catch (err) {
            console.error(err);
            setMessage('An error occurred while saving. Please try again later.');
            setTimeout(() => {
                setMessage('');
            }, 5000); // Remove the error message after 5 seconds
        }
    };

    return (
        <Container fluid className="mt-2">
            <Row>
                <Col>
                    <InputGroup className="mb-3">
                        <Form.Label className="me-4 mt-2">Group Name : </Form.Label>
                        <Form.Control placeholder="Enter Group Name" value={groupName} onChange={handleChange} />
                    </InputGroup>
                    {message && (
                        <div className={`text-${messageType === 'success' ? 'success' : 'danger'} text-center fs-6`}>{message}</div>
                    )}
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className="ag-theme-alpine" style={gridStyle}>
                        <AgGridReact
                            ref={gridRef}
                            gridOptions={gridOptions}
                            onGridReady={onGridReady}
                            onSelectionChanged={onSelectionChanged}
                            onRowDoubleClicked={handleAddClick}
                        />
                    </div>
                </Col>
                <div style={{ width: '18px' }} className="d-flex flex-column justify-content-center align-items-center">
                    <Button variant="secondary" className="btn-sm mb-1" onClick={handleAddClick}>
                        <i className="bi bi-arrow-right"></i>
                    </Button>
                    <Button variant="secondary" className="btn-sm" onClick={handleRemoveClick}>
                        <i className="bi bi-arrow-left"></i>
                    </Button>
                </div>

                <Col>
                    <div className="ag-theme-alpine" style={{ height: 440 }}>
                    {rowData1.length > 0 ? (
                        <AgGridReact
                            ref={secondGridRef}
                            rowData={rowData1}
                            columnDefs={columns}
                            rowSelection={"multiple"}
                            onSelectionChanged={onSecondGridSelectionChanged}
                            defaultColDef={gridOptions.defaultColDef}
                            onRowDoubleClicked={handleRemoveClick}
                        />
                    ) : (
                        <div className="d-flex align-items-center justify-content-center">
                            <div className="text-center"><p>No rows selected</p></div>
                        </div>
                      )}
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md={{ offset: 10 }} className="mt-3 mb-2">
                    <Button variant="secondary" className="btn-sm me-3" onClick={() => setShowAddLocationGroupModal(false)}>
                        Cancel
                    </Button>
                    <Button variant="primary" className="btn-sm" onClick={handleSaveClick}>
                        Save
                    </Button>
                </Col>
            </Row>

        </Container>

    )

}

export default AGgridListbox;

