import axios from 'axios';

const openRazorpay = async ({ amount, subscription, user, priceid, onSuccess, credits }) => {
    try {
        let subscription_id;
        let data;
        if (subscription) {
            const subscriptionResponse = await axios.post('/create_razorpay_subscription', {
                planid: user.planid,
                user: user,
                priceid: priceid
            });
            if (subscriptionResponse) {
                subscription_id = subscriptionResponse.data.id;
                data = await subscriptionResponse.data;
            }
        }else{
            const orderResponse = await axios.post('/create_razorpay_order', {
                amount: amount,
                currency: 'INR',
                receipt: 'order_rcptid_11',
                payment_capture: 1,
                user : user,
                priceid: priceid,
                credits : credits
            });
            data = await orderResponse.data;

        }
        const options = {
            key: process.env.REACT_APP_RAZORPAY_KEY_ID,
            subscription_id: subscription ? subscription_id : '',
            amount: data.amount, // amount in the smallest currency unit
            currency: data.currency,
            name: 'Wowleads',
            description: 'Payment for your order',
            image: 'https://wowleads.com/assets/img/wow-logo.png',
            order_id: data.id,
            handler: async function (response) {
                // Extract relevant data from the response object
                const { razorpay_payment_id, razorpay_subscription_id, razorpay_signature, razorpay_order_id } = response;

                try {
                    if (razorpay_subscription_id) {
                        // Subscription payment, proceed with signature verification
                        const verificationResponse = await axios.post('/verify-signature', {
                            razorpay_payment_id,
                            razorpay_subscription_id,
                            razorpay_signature,
                            status : 1
                        });
                        onSuccess('Payment Successful.');
                        console.log(verificationResponse)
                        
                    } else {
                        const verificationResponse = await axios.post('/verify-signature', {
                            razorpay_payment_id,
                            razorpay_order_id,
                            razorpay_signature,
                            status : 1,
                            amount : amount,
                            email : user.email,
                        });
                        onSuccess('Payment Successful.');
                        console.log(verificationResponse)
                    }
                } catch (error) {
                    // Handle error
                    onSuccess('Payment Failed.');
                    console.error('Error:', error);
                }
            },
            prefill: {
                name: user.name,
                email: user.email,
                contact: user.phone,
            },
            theme: {
                color: '#06164b',
            },
            modal: {
                ondismiss: function() {
                    window.location.href = '/main';
                }
            }
        };

        const razorpayInstance = new window.Razorpay(options);
        razorpayInstance.open();

    } catch (error) {
        console.error('Error:', error);
    }
};

export default openRazorpay;

