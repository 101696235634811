import React, { Component } from 'react';
import axios from 'axios';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import MultiValueContainer from './multivaluecontainer';
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import SelectPlaces from './selectplaces';

const animatedComponents = makeAnimated();
class SelectPostalCodes extends Component {
  constructor (props) {
    super(props);

    this.popupPlacesRef = React.createRef();

    this.state = {
        isStateAll: false,
        isCountyAll: false,
        isPcodeAll: false,
        locgroups: [],
        places: [],
        countries: [],
        states: [],
        counties: [],
        pcodes: [],
        selectedLocGroup: 0,
        selectedPlaces: [],
        selectedCountry: 0,
        selectedState: 0,
        selectedCounty: 0,
        selectedOLocGroup: [],
        selectedOCountry: [],
        selectedOState: [],
        selectedOCounty: [],
        selectedPcodes: [],
        inputLocGroup: '',
        inputPlace: '', 
        inputCountry: '',
        inputState: '',
        inputCounty: '',
        inputValue: '',
        geoLevel1: 'Geo Level 1',
        geoLevel2: 'Geo Level 2',
        placesSelected: 0,
        loadingPlaces: false,
        popupPlaces: false,
        accordion: "2",
    };

    this.onLocGroupChange = this.onLocGroupChange.bind(this);
    this.onCountryChange = this.onCountryChange.bind(this);
    this.onStateChange = this.onStateChange.bind(this);
    this.onCountyChange = this.onCountyChange.bind(this);
    this.onPostalCodeChange = this.onPostalCodeChange.bind(this);
    this.clearSelection = this.clearSelection.bind(this);
    this.onStateAllChange = this.onStateAllChange.bind(this);
    this.onCountyAllChange = this.onCountyAllChange.bind(this);
    this.onPcodeAllChange = this.onPcodeAllChange.bind(this);
    this.onLocGroupInputChange = this.onLocGroupInputChange.bind(this);
    this.onLocGroupMenuClose = this.onLocGroupMenuClose.bind(this);
    this.onCountryInputChange = this.onCountryInputChange.bind(this);
    this.onCountryMenuClose = this.onCountryMenuClose.bind(this);
    this.onStateInputChange = this.onStateInputChange.bind(this);
    this.onStateMenuClose = this.onStateMenuClose.bind(this);
    this.onCountyInputChange = this.onCountyInputChange.bind(this);
    this.onCountyMenuClose = this.onCountyMenuClose.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.onMenuClose = this.onMenuClose.bind(this);
    this.getGeoData = this.getGeoData.bind(this);
    this.setPlacesSelected = this.setPlacesSelected.bind(this);

    this.fetchPlaces = this.fetchPlaces.bind(this);
    this.onPlaceChange = this.onPlaceChange.bind(this);
    this.onPlaceInputChange = this.onPlaceInputChange.bind(this);
    this.onPlaceMenuClose = this.onPlaceMenuClose.bind(this);
    this.showPopupPlaces = this.showPopupPlaces.bind(this);
    this.hidePopupPlaces = this.hidePopupPlaces.bind(this);
    this.setSelectedPlaces = this.setSelectedPlaces.bind(this);
    this.handleAccordion = this.handleAccordian.bind(this);
  };

  setSelectedPlaces(data) {
    this.onPlaceChange(data);
    this.setState({ popupPlaces: false });
  }

  showPopupPlaces() {
    this.setState({ popupPlaces: true })
  }

  hidePopupPlaces() {
    this.setState({ popupPlaces: false })
  }

  fetchPlaces(inputValue) {
    var keywords = inputValue;
    if (keywords.trim() !== '') {keywords = keywords.concat(' ')}

    return new Promise (async (resolve, reject) => {
      await axios.post('/places', {kw: keywords})
      .then((res) => {
        this.setState({places: res ? res.data : []});
        this.setState({ loadingPlaces: false });
        resolve(true)
      })
      .catch((err) => {
        this.setState({places: []})
        this.setState({ loadingPlaces: false });
        console.log(err)
        reject(false)
      })
    })
  }

  async onPlaceChange(value) {
    this.setState({ selectedPlaces: value || [] });
    this.props.updateGeoData(this.getGeoData(0, 0, 0, 0, false, false, false, value.map(o => o['value'])));
  }
    
  onPlaceInputChange = (value, e) => {
    if (e.action === 'input-change') {
      this.setState({ inputPlace: value });
      if (!this.state.loadingPlaces) {
        this.setState({ loadingPlaces: true });
        this.fetchPlaces(value);
      }
    }
  } 

  onPlaceMenuClose() {
    this.setState({inputPlace: '', places: []});
  }

  async setPlacesSelected(geoData) {
    await axios.post(`/getplacenames`, { geodata: geoData })
    .then(res => {
      this.setState({
        placesSelected: res.data.length
      });
    })
    .catch((error) => {
      console.log(error);
    })
  }

  getGeoData(locgroup, country, state, county, isStateAll, isCountyAll, isPcodeAll, pcodes) {
    const geoData = [{
      locgroup: locgroup,
      country: country,
      state: state,
      county: county,
      isStateAll: isStateAll,
      isCountyAll: isCountyAll,
      isPcodeAll: isPcodeAll,
      pcodes: pcodes
    }];

    this.setPlacesSelected(geoData);

    return geoData;
  }

  async onLocGroupChange(o) {
    const eLocGroup = o ? o.value : 0;
    this.setState({
      selectedLocGroup: eLocGroup,
      selectedOLocGroup: o
    })
    this.props.updateGeoData(this.getGeoData(eLocGroup, 0, 0, 0, false, false, false, []));
  }

  onLocGroupInputChange = (value, e) => {
    if (e.action === 'input-change') {
        this.setState({inputLocGroup: value});
    }
  } 

  onLocGroupMenuClose () {
    this.setState({inputLocGroup: ''});
  }

  async onCountryChange(o) {
    const eCountry = o ? o.value : 0;
    this.setState({
      selectedCountry: eCountry,
      selectedOCountry: o,
      selectedState: 0,
      selectedOState: [],
      selectedCounty: 0,
      selectedOCounty: [],
      isStateAll: false,
      isCountyAll: false,
      isPcodeAll: false,
      states: [],
      counties: [],
      pcodes: [],
      selectedPcodes: [],
      geoLevel1: 'Geo Level 1',
      geoLevel2: 'Geo Level 2'
    });
    this.props.updateGeoData(this.getGeoData(0, eCountry, 0, 0, false, false, false, []));
    
    if (eCountry > 0) {
      await axios.get(`/geolevels/${eCountry}`)
        .then(res => {
          this.setState({
            geoLevel1: res.data[0].geo_level1,
            geoLevel2: res.data[0].geo_level2
          });
        })
        .catch((error) => {
          console.log(error);
        })

      await axios.get(`/states/${eCountry}`)
        .then(res => {
          this.setState({
            states: res.data
          });
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  onCountryInputChange = (value, e) => {
    if (e.action === 'input-change') {
        this.setState({inputCountry: value});
    }
  } 

  onCountryMenuClose () {
    this.setState({inputCountry: ''});
  }

  async onStateAllChange(e) {
    const eChecked = e.target.checked;
    this.setState({
      isStateAll: eChecked,
      isCountyAll: false,
      isPcodeAll: false,
      selectedState: 0,
      selectedOState: [],
      selectedCounty: 0,
      selectedOCounty: [],
      counties: [],
      pcodes:[],
      selectedPcodes: []
    })
    this.props.updateGeoData(this.getGeoData(0, this.state.selectedCountry, 0, 0, eChecked, false, false, []));
  }

  async onStateChange(o) {
    const eState = o ? o.value : 0;
    this.setState({
      selectedState: eState,
      selectedOState: o,
      selectedCounty: 0,
      selectedOCounty: [],
      isCountyAll: false,
      counties: [],
      isPcodeAll: false,
      pcodes:[],
      selectedPcodes: []
    });
    this.props.updateGeoData(this.getGeoData(0, this.state.selectedCountry, eState, 0, this.state.isStateAll, false, false, []));

    if (eState > 0) {
      await axios.get(`/counties/${eState}`)
        .then(res => {
          this.setState({
            counties: res.data
          });
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  onStateInputChange = (value, e) => {
    if (e.action === 'input-change') {
        this.setState({inputState: value});
    }
  } 

  onStateMenuClose () {
    this.setState({inputState: ''});
  }

  async onCountyAllChange(e) {
    const eChecked = e.target.checked;
    this.setState({
      isCountyAll: eChecked,
      isPcodeAll: false,
      selectedCounty: 0,
      selectedOCounty: [],
      pcodes: [],
      selectedPcodes: []
    })
    this.props.updateGeoData(this.getGeoData(0, this.state.selectedCountry, this.state.selectedState, 0, this.state.isStateAll, eChecked, false, []));
  }

  async onCountyChange(o) {
    const eCounty = o ? o.value : 0;
    this.setState({
      selectedCounty: eCounty,
      selectedOCounty: o,
      isPcodeAll: false,
      pcodes: [],
      selectedPcodes: []
    })
    this.props.updateGeoData(this.getGeoData(0, this.state.selectedCountry, this.state.selectedState, eCounty, this.state.isStateAll, this.state.isCountyAll, false, []));

    if (eCounty > 0) {
      await axios.get(`/postalcodesbycounty/${eCounty}`)
        .then(res => {
          this.setState({
            pcodes: res.data
          });
        })
        .catch((error) => {
          console.log(error);
        })
    }
  }

  onCountyInputChange = (value, e) => {
    if (e.action === 'input-change') {
        this.setState({inputCounty: value});
    }
  } 

  onCountyMenuClose () {
    this.setState({inputCounty: ''});
  }

  async onPcodeAllChange(e) {
    const eChecked = e.target.checked;
    this.setState({
      isPcodeAll: eChecked,
      selectedPcodes: []
    })
    this.props.updateGeoData(this.getGeoData(0, this.state.selectedCountry, this.state.selectedState, this.state.selectedCounty, this.state.isStateAll, this.state.isCountyAll, eChecked, []));
  }

  async onPostalCodeChange(value) {
    this.setState({
      selectedPcodes: value
    })
    this.props.updateGeoData(this.getGeoData(0, this.state.selectedCountry, this.state.selectedState, this.state.selectedCounty, this.state.isStateAll, this.state.isCountyAll, this.state.isPcodeAll, value.map(o => o['value'])));
  }

  onInputChange = (value, e) => {
    if (e.action === 'input-change') {
        this.setState({inputValue: value});
    }
  } 

  onMenuClose () {
    this.setState({inputValue: ''});
  }

  async componentDidMount() {
    await axios.get(`/locationgroups`)
      .then(res => {
        this.setState({
          locgroups: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      })

    await axios.get(`/countries`)
      .then(res => {
        this.setState({
          countries: res.data
        });
      })
      .catch((error) => {
        console.log(error);
      })
  }

  clearSelection() {
    this.setState({
      selectedPcodes: []
    })
  }

  handleAccordian(e) {
    const accordionId = e.currentTarget.dataset.id;

    if (((accordionId === "0") && ((this.state.selectedLocGroup > 0) || (this.state.selectedCountry > 0))) || 
        ((accordionId === "1") && ((this.state.selectedCountry > 0) || (this.state.selectedPlaces.length > 0))) ||
        ((accordionId === "2") && ((this.state.selectedLocGroup > 0) || (this.state.selectedPlaces.length > 0)))) {
        // do nothing
    } else {
      this.setState({accordion: accordionId})
    }
  }
  renderTooltip = (props) => {
    const tooltipStyle = {
      textAlign: 'left',
      maxHeight: '400px', 
      overflowY: 'auto',
      padding: '3px'
    };
  
    return (
      <Tooltip  {...props} style={{'--bs-tooltip-max-width': '300px', ...props.style}}>
        <div style={tooltipStyle}>
          <h6 style={{ marginBottom: '10px', textAlign: 'center' }}>
          Search Operators
          </h6>
          <div>
            <p>There are numerous symbols you can use in your search words to refine them for the results you want to see.  Let’s take a look at them below </p>
            [<span style={{ color: '#FFF455', fontWeight: 'bold' }}>+</span>]
            <span className='m-2'>
              A leading or trailing plus sign indicates that this word must be present in each row that is returned.
              <br />
              <strong>Example:</strong> <code>+beach +resort</code>
            </span>
          </div>
          <br />
          <div>
            [<span style={{ color: '#FFF455', fontWeight: 'bold' }}>-</span>]
            <span className='m-2'>
              A leading or trailing minus sign indicates that this word must not be present in any of the rows that are returned.
              <br />
              <strong>Example:</strong> <code>-expensive -crowded</code>
            </span>
          </div>
          <br />
          <div>
            [<span style={{ color: '#FFF455', fontWeight: 'bold' }}>&gt;</span>]
            <span className='m-2'>
              The &gt; operator increases the contribution of a word to the relevance value.
              <br />
              <strong>Example:</strong> <code>beach &gt;luxury</code>
            </span>
          </div>
          <br />
          <div>
            [<span style={{ color: '#FFF455', fontWeight: 'bold' }}>&lt;</span>]
            <span className='m-2'>
              The &lt; operator decreases the contribution of a word to the relevance value.
              <br />
              <strong>Example:</strong> <code>beach &lt;expensive</code>
            </span>
          </div>
        </div>
    </Tooltip>
    );
  };
  
  

  render() {
    return (
      <React.Fragment>
        <div>
          <div style={{height: "30px"}} className="mb-1 ps-0 pb-1 w-100 d-flex align-items-center">
            <Form.Label style={{cursor: "pointer"}} className={`my-auto text-${((this.state.selectedLocGroup > 0) || (this.state.selectedPlaces.length > 0)) ? 'secondary' : 'dark'}`} data-id="2" onClick={this.handleAccordion}>
              <i className={`me-1 bi bi-caret-${this.state.accordion==="2" ? 'right' : 'down'}-fill`} />Country
            </Form.Label>
          </div>
          <div className={`${(this.state.accordion==="2" ? 'd-block' : 'd-none')}`}>
            <Form.Group className="mb-2">
              <Select
                id='select_country'
                className="sm"
                isMulti={false}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                value={this.state.selectedOCountry}
                onChange={this.onCountryChange}
                options={this.state.countries}
                components={animatedComponents}
                onInputChange={this.onCountryInputChange}
                onMenuClose={this.onCountryMenuClose}
                inputValue={this.state.inputCountry}
                isDisabled={(this.state.selectedLocGroup > 0) || (this.state.selectedPlaces.length > 0) || this.props.isCrawling}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Group as={Row}>
                <Col>
                  <Form.Label>{this.state.geoLevel1}</Form.Label>
                </Col>
                <Col className='d-flex justify-content-end'>
                  <Form.Check id='select_state_all' type='switch' label="All" onChange={this.onStateAllChange} disabled={(this.state.selectedCountry===0) || this.props.isCrawling} />
                </Col>
              </Form.Group>
              <Select
                id='select_state'
                className="sm"
                isMulti={false}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                value={this.state.selectedOState}
                onChange={this.onStateChange}
                options={this.state.states}
                components={animatedComponents}
                onInputChange={this.onStateInputChange}
                onMenuClose={this.onStateMenuClose}
                inputValue={this.state.inputState}
                isDisabled={(this.state.selectedCountry===0) || this.state.isStateAll || this.props.isCrawling}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Group as={Row}>
                <Col>
                  <Form.Label>{this.state.geoLevel2}</Form.Label>
                </Col>
                <Col className='d-flex justify-content-end'>
                  <Form.Check id='select_county_all' type='switch' label="All" onChange={this.onCountyAllChange} checked={this.state.isCountyAll} disabled={((this.state.selectedState===0) && !this.state.isStateAll) || this.props.isCrawling} />
                </Col>
              </Form.Group>
              <Select
                id='select_county'
                className="sm"
                isMulti={false}
                isClearable={true}
                backspaceRemovesValue={true}
                closeMenuOnSelect={true}
                value={this.state.selectedOCounty}
                onChange={this.onCountyChange}
                options={this.state.counties}
                components={animatedComponents}
                onInputChange={this.onCountyInputChange}
                onMenuClose={this.onCountyMenuClose}
                inputValue={this.state.inputCounty}
                isDisabled={(this.state.selectedState===0) || this.state.isCountyAll || this.props.isCrawling}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Group as={Row}>
                <Col>
                  <Form.Label>Postal Codes</Form.Label>
                </Col>
                <Col className='d-flex justify-content-end'>
                  <Form.Check id='select_pcode_all' type='switch' label="All" onChange={this.onPcodeAllChange} checked={this.state.isPcodeAll} disabled={((this.state.selectedCounty===0) && !this.state.isCountyAll) || this.props.isCrawling} />
                </Col>
              </Form.Group>
              <Select
                id='select_pcode'
                className="sm"
                isMulti={true}
                backspaceRemovesValue={false}
                closeMenuOnSelect={false}
                components={{ MultiValueContainer }}
                value={this.state.selectedPcodes}
                onChange={this.onPostalCodeChange}
                options={this.state.pcodes}
                onInputChange={this.onInputChange}
                onMenuClose={this.onMenuClose}
                inputValue={this.state.inputValue}
                isDisabled={(this.state.selectedCounty===0) || this.state.isPcodeAll || this.props.isCrawling}
              />
            </Form.Group>
          </div>
          <hr className="my-2" />
          <div className={`mt-1`}>
          <div style={{height: "30px"}} className="d-flex flex-row mb-1 ps-0 pb-1 w-100 align-items-center">
            <Form.Label style={{cursor: "pointer"}} className={`my-auto text-${((this.state.selectedLocGroup > 0) || (this.state.selectedCountry > 0)) ? 'secondary' : 'dark'}`} data-id="0" onClick={this.handleAccordion} >
              <i className={`me-1 bi bi-caret-${this.state.accordion==="0" ? 'right' : 'down'}-fill`} />Places <OverlayTrigger placement="right" trigger={"click"} overlay={this.renderTooltip} rootClose><i className='bi bi-info-circle'/></OverlayTrigger> {this.state.loadingPlaces ? (<i className="fa fa-spinner fa-pulse text-primary"></i>) : ''}
            </Form.Label>
            <Button size="sm" variant="outline-primary" className={`ms-auto border border-0 ${(this.state.accordion==="0" ? 'd-block' : 'd-none')}`} onClick={this.showPopupPlaces}
              disabled={this.props.isCrawling} 
            >
              <i className={`h6 bi bi-box-arrow-up-right`}></i>
            </Button>
          </div>
          <Select 
            id='select_place'
            className={`sm ${(this.state.accordion==="0" ? 'd-block' : 'd-none')}`}
            isMulti
            components={{ MultiValueContainer }}
            value={this.state.selectedPlaces}
            options={this.state.places.map(place => {return {value: place.id, label: `${place.code} - ${place.name} - ${place.l1_name}`, row: place}})} 
            inputValue={this.state.inputPlace}
            onChange={this.onPlaceChange}
            onInputChange={this.onPlaceInputChange}
            onMenuClose={this.onPlaceMenuClose}
            backspaceRemovesValue={false}
            closeMenuOnSelect={false}
            filterOption={null}
            isDisabled={(this.state.selectedLocGroup > 0) || (this.state.selectedCountry > 0) || this.props.isCrawling}
          />
        </div>
        <hr className="my-2" />
        <Form.Group>
          <div style={{height: "30px"}} className="mb-1 ps-0 pb-1 w-100 d-flex align-items-center">
            <Form.Label style={{cursor: "pointer"}} className={`my-auto text-${((this.state.selectedCountry > 0) || (this.state.selectedPlaces.length > 0)) ? 'secondary' : 'dark'}`} data-id="1" onClick={this.handleAccordion}>
              <i className={`me-1 bi bi-caret-${this.state.accordion==="1" ? 'right' : 'down'}-fill`} />Location Group
            </Form.Label>
          </div>
          <Select
            id='select_locgroup'
            className={`sm ${(this.state.accordion==="1" ? 'd-block' : 'd-none')}`}
            isMulti={false}
            isClearable={true}
            backspaceRemovesValue={true}
            closeMenuOnSelect={true}
            value={this.state.selectedOLocGroup}
            onChange={this.onLocGroupChange}
            options={this.state.locgroups}
            components={animatedComponents}
            onInputChange={this.onLocGroupInputChange}
            onMenuClose={this.onLocGroupMenuClose}
            inputValue={this.state.inputLocGroup}
            isDisabled={(this.state.selectedCountry > 0) || (this.state.selectedPlaces.length > 0) || this.props.isCrawling}
          />
        </Form.Group>
        <hr className="my-2" />
        </div>
        <div className={`px-1 pb-2 d-flex flex-row ${(this.state.placesSelected > 0) ? 'd-flex' : 'd-none'}`}>
          <div className="pe-2">Crawl Scope</div><div className="flex-grow-1 bg-light rounded border border-gray text-center">{this.state.placesSelected}</div>
        </div>
        <Modal show={this.state.popupPlaces} onHide={this.hidePopupPlaces} size='xl' className='shadow-lg'>
          <Modal.Header className="py-2 bg-primary text-white">
            <span className="fs-5">Select Places</span>
          </Modal.Header>
          <Modal.Body className="m-1 p-1">
            <div className="m-0 px-2 d-flex flex-column" style={{height: "600px"}}>
              <SelectPlaces hidePopupPlaces={this.hidePopupPlaces} selectedPlaces={this.state.selectedPlaces} savePopupPlaces={this.setSelectedPlaces} />
            </div>
          </Modal.Body>
        </Modal>
      </React.Fragment>
    ); 
  }
}

export default SelectPostalCodes;