import React, { useEffect, useState, useRef } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import axios from 'axios';
import '../App.css'

const ShareJob = ({ isOpen, onClose, job, loggedInUserId }) => {

  const [activeJobData, setActiveJobData] = useState();
  const [activeJobName, setActiveJobName] = useState('');
  const [sharedWith, setSharedWith] = useState()
  const [statusMessage, setStatusMessage] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [fetchingData, setFetchingData] = useState(false);

  const userEmailRef = useRef(null);

  function onShareBtnClick() {
    ShareJobs(userEmail)
  }
  
  function onUserEmailChange(e) {
    setUserEmail(e.target.value);
  }

  function onUserEmailKeyDown(e) {
    if ((e.keyCode === 13) && (userEmail.trim() !== '')) {
      onShareBtnClick()    
    }
  }

  const SharedWith = () => {
    // Filter the sharedWith array based on the searchQuery, or show all data if no search
    const filteredSharedWith = searchQuery
      ? sharedWith.filter(user =>
        user.email.toLowerCase().includes(searchQuery.toLowerCase())
      )
      : sharedWith;


    return (
      <div className='d-flex flex-column' style={{height: "50vh"}}>
        <div className='fs-6 my-2 d-flex flex-row'>
          <span>Users with access</span>
          <i className={`mx-1 my-auto fa fa-spinner fa-pulse ${fetchingData ? 'd-flex' : 'd-none'}`} style={{height: "18px"}} />
        </div>
        
        <div className="h-100 px-1 rounded border border-gray" style={{ overflowY: 'auto', overflowX:'hidden'}}>
          {filteredSharedWith && filteredSharedWith.length > 0 ? (
            filteredSharedWith.map(user => (
              <div
                key={user.id}
                style={{ border: '2px solid #e8e9e9', borderRadius: '30px' }}
                className='d-flex p-1 mt-2 align-items-center justify-content-between'
              >
                <div>
                  <Button className='rounded-circle'>
                    <span className='fs-6 shadow fw-bold'>
                      {user.email.substring(0, 1).toUpperCase()}
                    </span>
                  </Button>
                  <span className='fs-6 mx-4'>{user.email}</span>
                </div>
                {(user.id === activeJobData?.userid) ? (<span className='me-2'>Owner</span>):
                ((loggedInUserId === activeJobData?.userid) ?
                  (<Button
                    variant="warning"
                    className='text-white rounded-circle justify-content-between'
                    onClick={() => {RemoveAccess(user.id)}}
                  >
                    <i className='bi bi-x-circle-fill'></i>
                  </Button>)
                  : '' )}
              </div>
            ))
          ) : (
            <div className='fs-6 text-center mt-2'>No results found for your search</div>
          )}
        </div>
      </div>
    );
  };

  const fetchData = async () => {
    if (job > 0) {
      try {
        setFetchingData(true);

        const jobResponse = await axios.get(`/job/${job}`);
        setActiveJobData(jobResponse.data[0]);

        const jobName = jobResponse && jobResponse.data && jobResponse.data[0].label;
        setActiveJobName((jobName.length <= 30) ? jobName : jobName.substring(0, 30).concat('...'));

        const response = await axios.post('/users', { jobid: job, loggedInUserId: loggedInUserId });
        setSharedWith(response.data.result2);
      } catch (err) {
        console.log("error", err)
      } finally {
        setFetchingData(false)
      }

    }
  }

  useEffect(() => {
      setSearchQuery('');
      setActiveJobName('');
      setUserEmail('')
      setSharedWith();
      fetchData();
  }, [isOpen])

  const ShareJobs = async (userEmail) => {
    try {
      const response = await axios.post('/grantJobAccess', { jobId: job, userEmail: userEmail });
      setStatusMessage(response.data);

      await fetchData();
      userEmailRef.current.value = '';
    } catch (err) {
      console.log("Error while granting access", err)
      setStatusMessage('Error while granting access');
    }
  }

  const RemoveAccess = async (userId) => {
    try {
      const response = await axios.post('/revokeJobAccess', { jobId: job, userId: userId });
      setStatusMessage(response.data);

      await fetchData();
    } catch (err) {
      console.log("Error while revoking access", err)
      setStatusMessage('Error while revoking access');
    }
  }

  useEffect(() => {
    // Clear the success message after a brief delay
    const timeoutId = setTimeout(() => {
      setStatusMessage('');
    }, 3000);

    return () => {
      // Clear the timeout when the component unmounts
      clearTimeout(timeoutId);
    };
  }, [statusMessage]);

  return (
    <React.Fragment>
      <Modal show={isOpen} onHide={onClose} centered>
        <Modal.Header className="py-2 bg-primary text-white">
          <div title={activeJobData?.label} className="fs-5">Share {activeJobName} [List #{loggedInUserId}00{job}]</div> 
        </Modal.Header>
        <Modal.Body className='p-2 shadow'>
          <input
            className={`form-control ${(sharedWith?.length > 7) ? 'd-flex' : 'd-none'}`}
            type='text'
            placeholder='Search users with access ...'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <SharedWith />
          <div className='fs-6 my-2'>Grant access</div>
          {/* <div className={`p-1 my-2 ${(loggedInUserId === activeJobData?.userid) ? 'd-flex flex-row' : 'd-none'}`} style={{ border: '2px solid #e8e9e9', borderRadius: '30px' }}> */}
          <div className={`p-1 my-2 d-flex flex-row`} style={{ border: '2px solid #e8e9e9', borderRadius: '30px' }}>
            <Button className='rounded-circle justify-content-between'><i className='bi bi-person-plus-fill' /></Button>
            <Form.Control ref={userEmailRef} autoFocus className="mx-2" autoComplete='new-password' placeholder='Enter user email ...' defaultValue={userEmail} onChange={onUserEmailChange} onKeyDown={onUserEmailKeyDown} />
            <Button variant='success' className='rounded-circle text-white justify-content-between' onClick={onShareBtnClick} disabled={(userEmail.trim() === '')}>
              <i className='bi bi-share-fill' />
            </Button>
          </div>
          <div className="d-flex">
            {statusMessage && (
              <div className={`my-auto fs-6`}>{statusMessage}</div>
            )}
            <Button className="ms-auto my-1" variant="secondary" onClick={onClose}>
              Close
            </Button>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
};

export default ShareJob;