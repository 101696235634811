import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

function LeadCharts(props) {
  const [sbarOptions, setSBarOptions] = useState(initSBarOptions([]));
  const [sbarSeries, setSBarSeries] = useState(initSBarSeries([]));
  const [barOptions, setBarOptions] = useState(initBarOptions([]));
  const [barSeries, setBarSeries] = useState(initBarSeries([]));
  const [pieOptions, setPieOptions] = useState(initPieOptions([]));
  const [pieSeries, setPieSeries] = useState(initPieSeries([]));
  const [thisProps] = useState(props);

  function initSBarOptions(data) {
    return {
      title: {
        text: 'Social Media Presence',
        floating: 0,
        align: 'center'
      },
      plotOptions: {
        bar: {
          barHeight: '90%',
          distributed: true,
          horizontal: true,
          borderRadius: 0,
          dataLabels: { position: 'bottom' }
        }
      },
      colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7', '#8024f4', '#d2e769' ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        offsetX: 10,
        offsetY: 0,
        style: { colors: ['#000'] },
        formatter: function (val, opt) {
          // return (val > 0) ? opt.w.globals.labels[opt.dataPointIndex] + ":  " + val : ''
          return (val > 0) ? val : ''
        },
        dropShadow: { enabled: false, color: '#fff' }
      },
      xaxis: {
        categories: data ? data : []
      },
      yaxis: {
        labels: { show: true }
      },
      legend: { show: false, position: 'bottom' },
      chart: {
        events: {
          dataPointSelection: function(event, chartContext, config) {
            const sociaMediaIndex = 80;
            thisProps.lfu({index: (sociaMediaIndex + config.dataPointIndex), value: data[config.dataPointIndex]})
          }
        },
        toolbar: { show: false }
      }
    }
  }

  function initSBarSeries(data) {
    return [{
      name: "Leads",
      data: data ? data : []
    }]
  }

  function initBarOptions(data) {
    return {
      title: {
        text: 'Leads Breakup',
        floating: 0,
        align: 'center'
      },
      plotOptions: {
        bar: {
          barHeight: '90%',
          distributed: true,
          horizontal: true,
          borderRadius: 0,
          dataLabels: { position: 'bottom' }
        }
      },
      colors: ['#33b2df', '#546E7A', '#d4526e', '#13d8aa', '#A5978B', '#2b908f', '#f9a3a4', '#90ee7e', '#f48024', '#69d2e7', '#8024f4', '#d2e769' ],
      dataLabels: {
        enabled: true,
        textAnchor: 'start',
        offsetX: 10,
        offsetY: 0,
        style: { colors: ['#000'] },
        formatter: function (val, opt) {
          // return (val > 0) ? opt.w.globals.labels[opt.dataPointIndex] + ":  " + val : ''
          return (val > 0) ? val : ''
        },
        dropShadow: { enabled: false, color: '#fff' }
      },
      xaxis: {
        categories: data ? data : []
      },
      yaxis: {
        labels: { show: true }
      },
      legend: { show: false, position: 'bottom' },
      chart: {
        events: {
          dataPointSelection: function(event, chartContext, config) {
            thisProps.lfu({index: config.dataPointIndex, value: data[config.dataPointIndex]})
          }
        },
        toolbar: { show: false }
      }
    }
  }

  function initBarSeries(data) {
    return [{
      name: "Leads",
      data: data ? data : []
    }]
  }

  function initPieOptions(data) {
    return {
      title: {
        text: 'Top 10 Categories',
        floating: 0,
        align: 'center'
      },
      plotOptions: {
        pie : {
          startAngle: 0,
          endAngle: 360,
          donut: {
            size: '66%',
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }
      },
      fill: {
        type: 'gradient',
      },
      dataLabels: {
        enabled: true,
        textAnchor: 'end',
        formatter: function(val, opts) {return opts.w.globals.series[opts.seriesIndex]},
      },
      legend: {
        position: 'bottom'
      },
      labels: data ? data : [],
      chart: {
        type: "donut",
        events: {
          dataPointSelection: function(event, chartContext, config) {
            var value = data[config.dataPointIndex];
            if (value === 'Other') {
              thisProps.lfu({index: 0, value: ''})
            } else {
              thisProps.lfu({index: 99, value: data[config.dataPointIndex]})
            }
          },
          // click: function(event, chartContext, config) {
          //   thisProps.lfu({index: 0, value: ''})
          // },
        }
      }
    }
  }

  function initPieSeries(data) {
    return data ? data : []
  }

  useEffect(() => {
    setSBarOptions(initSBarOptions(props.sbardata.labels));
    setSBarSeries(initSBarSeries(props.sbardata.series));
    setBarOptions(initBarOptions(props.bardata.labels));
    setBarSeries(initBarSeries(props.bardata.series));
    setPieOptions(initPieOptions(props.piedata.labels));
    setPieSeries(initPieSeries(props.piedata.series));
  }, [props]);

  return (
    <div className="my-2 d-flex flex-row flex-wrap justify-content-around" style={{height: "calc(100% - 20px)"}}>
        <div className='ms-2 px-2 shadow' style={{height: "100%", width: "32%"}}>
          <Chart
            type='donut'
            options={pieOptions}
            series={pieSeries}
            height="100%"
          />
        </div>
        <div className='ms-2 px-2 shadow' style={{height: "100%", width: "32%"}}>
          <Chart
            type='bar'
            options={barOptions}
            series={barSeries}
            height="100%"
          />
        </div>
        <div className='ms-2 px-2 shadow' style={{height: "100%", width: "32%"}}>
          <Chart
            type='bar'
            options={sbarOptions}
            series={sbarSeries}
            height="100%"
          />
        </div>
    </div>
  );
}

export default LeadCharts;