import React, { useState, useEffect, useRef, useMemo } from 'react';
import axios from 'axios';
import { Row, Col, Button, Form} from 'react-bootstrap'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-balham.css';
import 'ag-grid-enterprise'
import { DisplayedGroupCreator } from 'ag-grid-enterprise';
import JoditEditor from 'jodit-react';

function CreateCampaign(props){

    const [templates, setTemplates] = useState([])
    const [jobs, setJobs] = useState([])
    const [smtpCredentials,setSmtpCredentials]=useState([])

    const [selectedJob, setSelectedJob] = useState(-1)
    const [selectedTemplateId,setSelectedTemplateId]=useState(props.templateid?props.templateid:-1)
    const campaignIdRef=useRef(-1)
    const [selectedSmtpCredentials,setSelectedSmtpCredentials]=useState(-1)

    const [includeGenericEmails,setIncludeGenericEmails]=useState(true)
    const [includeSystemGeneratedEmails,setIncludeSystemGeneratedEmails]=useState(false)
    const [selectedFormat,setSelectedFormat]=useState(-1)

    const [rowFormData,setRowFormData]=useState({})
    const attachmentRef=useRef()
    const editor = useRef(null);
	const config =useMemo(()=>{ return {
        readonly: false, // all options from https://xdsoft.net/jodit/docs/,
        placeholder: 'Start typing...',
        height:285
    }},[]);
    const [validated, setValidated] = useState(false);
    
    const [isTemplateValid,setIsTemplateValid]=useState(undefined)
    const [isJobValid,setIsJobValid]=useState(undefined)
    const [isSmtpCreadentialsValid,setIsSmtpCreadentialsValid]=useState(undefined)
    const [message,setMessage]=useState('')

    const getTemplates = () => {
        axios.get(`/emailtemplates/userid/${props.uid}`)
            .then(res => {
                setTemplates(res.data)
                // props.setTemplates(res.data)
                // props.setSelectedTemplate(res.data[0].id)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getsmtpcredentials = () => {
        axios.get(`/smtpcreadentials/userid/${props.uid}`)
            .then(result => {
                setSmtpCredentials(result.data)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const getjobs = () => {
        axios.get(`/getjobswithemails/userid/${props.uid}`)
            .then(result => {
                setJobs(result.data)
                props.setShowstartmodal(true)
            })
            .catch(err => {
                console.log(err)
            })
    }

    const startcampaign = (event) => {
        let fileds=[]

        if(selectedTemplateId==-1)
            // setIsTemplateValid(false)
            fileds.push('Email Template')
        if(selectedJob==-1)
            // setIsJobValid(false)
            fileds.push('List')
        if(selectedSmtpCredentials==-1)
            // setIsSmtpCreadentialsValid(false)
            fileds.push('SMTP Credentials')

        if(fileds.length>0)
        {
            alert('Please select following fields: '+fileds.join(','))
        }
        else 
        {   if(campaignIdRef.current==-1)
                alert("Something wrong happened")
            else 
            {
                if(window.confirm('Are you sure you want start email campaign?'))
                {
                    axios.put(`/startemailscampaign/${props.uid}/${campaignIdRef.current}/${selectedSmtpCredentials}`)
                    .then(res => {
                        alert(`Campaign Started!`)
                        props.setShowstartmodal(false)
                        // props.setShowstartmodal(false)
                        // props.setCampaignRefresh(prevdata => !prevdata)
                    })
                    .catch(err => {
                        console.log(err)
                    })
                  
                }
            }
        }

    }

    const handletemplateselect = (event) => {
        setSelectedTemplateId(event.target.value)
        // if(event.target.value===-1)
        //     setIsTemplateValid(false)
        // else
        // setIsTemplateValid(true)
    }
    const handlejobselect = (event) => {
        setSelectedJob(event.target.value)
        // if(event.target.value===-1)
        //     setIsJobValid(false)
        // else
        //     setIsJobValid(true)
    }
    const handlesmtpselect = (event) => {
        setSelectedSmtpCredentials(event.target.value)
        // if(event.target.value===-1)
        //     setIsSmtpCreadentialsValid(false)
        // else
        //     setIsSmtpCreadentialsValid(true)
    }
    
    async function createCampaign(){
        const request = {
            url: '/emailcampaigns',
            data: {templateid:selectedTemplateId, smtpcredentialsid:0, jobid:selectedJob,createdby:props.uid},
            method: "POST"
        }

        if (request) {
            axios(request)
            .then((result) => {
                campaignIdRef.current=result.data.campaignid
                gridRef.current.api?.refreshServerSide({purge:true});
                
            })
            .catch((err) => {
                console.log('error occured while creating email campaign', err)
            })
        }
    }

    useEffect(()=>{
        getTemplates();
        getjobs()
        getsmtpcredentials()
    },[])

    //when list or job changed
    useEffect(()=>{
        if(selectedTemplateId!=-1&&selectedJob!=-1)
        {   
            createCampaign()

            setIncludeGenericEmails(true)
            setIncludeSystemGeneratedEmails(false)
            setSelectedFormat(-1)
        }
    },[selectedTemplateId,selectedJob])

    useEffect(()=>{

        const request = {
            url: `/campaigndetails/includegenericemails/${campaignIdRef.current}/${includeGenericEmails?1:0}`,
            data: {},
            method: "PUT"
        }

        if (request) {
            axios(request).then(() => {
                gridRef.current.api?.refreshServerSide({purge:true});
                
            })
                .catch((err) => {
                    alert('Something wrong happened. Can you please try after some time?')
                })
        }
    },[includeGenericEmails])

    useEffect(()=>{
        if(selectedFormat!==-1)
        {
            const request = {
            url: `/campaigndetails/includegeneratedemails/${campaignIdRef.current}/${includeSystemGeneratedEmails?1:0}/${selectedFormat}`,
            data: {},
            method: "PUT"
            }

            if (request) {
                axios(request).then(() => {
                    gridRef.current.api?.refreshServerSide({purge:true});
                    
                })
                    .catch((err) => {
                        alert('Something wrong happened. Can you please try after some time?')
                    })
            }
        }
    },[selectedFormat,includeSystemGeneratedEmails])

    //AgGrid

    const columns = [
        { field: 'id', headerName: 'Id', hide:"true", filter: 'agNumberColumnFilter'},
        { field:"i", headerName:"No.", width:"80",valueGetter:'node.rowIndex+1'},
        { field: 'email', headerName: 'Email', filter: 'agTextColumnFilter'},
        { field: 'name', headerName: 'Business Name', filter: 'agTextColumnFilter'},
        { field: 'person', headerName: 'Person', filter: 'agTextColumnFilter'},
        { field: 'bcc', headerName: 'bcc', filter: 'agTextColumnFilter' },

        { field: 'displayname', hide:"true" },
        { field: 'to', hide:"true" },
        { field: 'cc', hide:"true" },
        { field: 'subject', hide:"true" },
        { field: 'body', hide:"true" },
        { field: 'attachments', hide:"true" }
    ];

    const gridRef = useRef();


    const datasource = {
        getRows: function (params)  {
            const serverApiUrl = `/api/emailcampaigndetails`;

            fetch(serverApiUrl, {
                method: 'post',
                body: JSON.stringify({...params.request, tablename:`emailcampaigndetails`,campaignid:campaignIdRef.current}),
                headers: { "Content-Type": "application/json; charset=utf-8" }
            })
            .then(httpResponse => httpResponse.json())
            .then(response => {
                params.successCallback(response.rows, response.lastRow);
            })
            .catch(error => {
                console.error(error);
                params.failCallback();
            });
        }
    };

    const defaultColDef={
        sortable: true,
        filter: true,
        resizable: true,
        cellStyle: {textAlign: 'center'}
        // floatingFilter: false,
        // enableValue: true,
        // enableRowGroup: true,
        // enablePivot: true,
    }

    const onGridReady = (params) => {
        gridRef.current.api?.setServerSideDatasource(datasource);
    };

    const onSelectionChanged = () => {
        const selectedRows = gridRef.current.api.getSelectedRows();

        let row=selectedRows[0]
        let tmp_attachments=[]
        
        for(let a of JSON.parse(row.attachments))
            tmp_attachments.push(a.filename)
        let str_attachment=tmp_attachments.join(', ')

        setRowFormData({...row,attachments:str_attachment})

        console.log('Selected rows:', selectedRows[0]);
      };

      const handleChange = (e) => {
        const { name, value } = e.target;
        setRowFormData({ ...rowFormData, [name]: value })
    }

    const handleUpdate = async (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === false ) {
        }
        else if (form.checkValidity()===true ) {

            let data = new FormData();
            for (const [key, val] of Object.entries(rowFormData))
                data.set(key, val);
            data.set('modifiedby', props.uid);

            for(let file of attachmentRef.current.files)
                data.set(file.name, file);

            const response=await fetch('/api/emailcampaigndetails',{
                method:"PUT",
                body:data
            })

            if(response.ok){
                alert('Email details modified')
                setRowFormData({})
                setValidated(true)
            }
            else{
                alert('Something wrong happened. Can you please try after some time?')
            }
        }

        setValidated(true);
    }

    const handleCancel = async () => {
        if(campaignIdRef.current!=-1)
        {
            axios.put(`/deletetmpemailcampaigns/${campaignIdRef.current}`)
            .then(res => {
                props.setShowstartmodal(false)
            })
            .catch(err => {
                props.setShowstartmodal(false)
            })
        }
        else  props.setShowstartmodal(false)
    }

    return <>
    <div className='h-100' >
        <Row>
            <Col>
                <Form.Group >
                    <Form.Label className='fw-bold'>Template</Form.Label>
                    <Form.Select onChange={handletemplateselect} size="sm" value={selectedTemplateId}>
                        <option key='default' value={-1}>
                            Select Email Template
                        </option>
                        {templates.map((option, index) => (
                            <option key={index} value={option.id} >
                                [ID-{props.uid}00{option.id}]  {option.title} 
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                <Form.Group >
                    <Form.Label className='fw-bold'>List</Form.Label>
                    <Form.Select id="selectOptions" onChange={handlejobselect} size="sm" value={selectedJob}>
                        <option key='default' value={-1}>
                            Select list
                        </option>
                        {jobs.map((option, index) => (
                            <option key={index} value={option.id} disabled={ [2,3].includes(option.status) ? false : true} >
                                [ID-{props.uid}00{option.id}] {option.name}
                                {/* ( {option.emailcount} emails ) */}
                            </option>
                        ))}
                    </Form.Select>
                </Form.Group>
            </Col>
            <Col>
                
                <Row className='w-100 mt-1'>
                    <Col>
                            <Form.Check
                                className='fw-bold'
                                inline
                                label="Include Generic Emails"
                                type='checkbox'
                                defaultChecked={true}
                                disabled={selectedJob===-1?true:false}
                                checked={includeGenericEmails}
                                onChange={()=>setIncludeGenericEmails(!includeGenericEmails)}
                            />
                            <Form.Check
                                onChange={()=>setIncludeSystemGeneratedEmails(!includeSystemGeneratedEmails)}
                                className='fw-bold'
                                inline
                                label="Include System-generated email IDs"
                                type='checkbox'
                                checked={includeSystemGeneratedEmails}
                                disabled={selectedJob===-1?true:false}
                            />
                    </Col>
                    </Row>
                    {/* style={{visibility:includeSystemGeneratedEmails?'visible':'hidden'}} */}
                        <Row className={`w-100 mt-1`} > 
                            {/* <Col sm={2}>
                                <div className='fw-bold'> Select Format</div>
                            </Col> */}
                            <Col>
                                <Form.Check
                                    disabled={!includeSystemGeneratedEmails}
                                    inline
                                    label="first.last@domain"
                                    name="format"
                                    type='radio'
                                    onChange={()=>setSelectedFormat(1)}
                                    checked={selectedFormat===1}
                                />
                                <Form.Check
                                disabled={!includeSystemGeneratedEmails}
                                    inline
                                    label="FLast@domain"
                                    name="format"
                                    type='radio'
                                    onChange={()=>setSelectedFormat(2)}
                                    checked={selectedFormat===2}
                                />
                                <Form.Check
                                disabled={!includeSystemGeneratedEmails}
                                    inline
                                    label="first@domain"
                                    name="format"
                                    type='radio'
                                    onChange={()=>setSelectedFormat(3)}
                                    checked={selectedFormat===3}
                                />
                                <Form.Check
                                disabled={!includeSystemGeneratedEmails}
                                    inline
                                    label="firstL@domain"
                                    name="format"
                                    type='radio'
                                    onChange={()=>setSelectedFormat(4)}
                                    checked={selectedFormat===4}
                                />
                            </Col>
                        </Row>
            </Col>
        </Row>
        <Row className='mt-3 w-100'>
            <Col>
            <Row className='w-100'>
                <div className="ag-theme-balham m-0 p-2" style={{height:'79vh'}}>
                        <AgGridReact
                            ref={gridRef}
                            defaultColDef={defaultColDef}
                            onGridReady={onGridReady}
                            columnHoverHighlight={'true'}
                            suppressRowGroupHidesColumns={'true'}
                            cacheBlockSize={50}
                            rowModelType={'serverSide'}
                            columnDefs={columns}
                            rowSelection="single" // Set to 'single' for single selection
                            onSelectionChanged={onSelectionChanged}
                        />               
                </div>
            </Row>
            </Col>
            <Col sm={4}>
            <Form noValidate validated={validated} onSubmit={handleUpdate}>
                            
                            <Row className="mb-3 mt-2">
                                <Col >
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Display Name (From){<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                placeholder="eg. ABC Enterprises"
                                                value={rowFormData.displayname}
                                                name='displayname'
                                                onChange={handleChange}
                                                required
                                                pattern={'^[a-zA-Z 0-9]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid display name.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col >
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>To{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                value={rowFormData.to}
                                                name='to'
                                                onChange={handleChange}
                                                required
                                                pattern={'^[{}_,@a-zA-Z0-9.]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid to email.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Cc</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                value={rowFormData.cc}
                                                name='cc'
                                                onChange={handleChange}
                                                pattern={'^[{}_,@a-zA-Z0-9.]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid to Cc.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Bcc</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                value={rowFormData.bcc}
                                                name='bcc'
                                                onChange={handleChange}
                                                pattern={'^[{}_,@a-zA-Z0-9.]+$'}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide valid Bcc.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3">
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Subject{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <Form.Control size="sm"
                                                type="text"
                                                value={rowFormData.subject}
                                                name='subject'
                                                onChange={handleChange}
                                                required
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid subject.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="mb-3 h-50">
                                <Col>
                                    <Form.Group >
                                            <Form.Label className='fw-bold'>Email Body{<span style={{ color: 'red' }}>*</span>}</Form.Label>
                                            <JoditEditor 
                                                ref={editor}
                                                value={rowFormData.body}
                                                config={config}
                                                tabIndex={1} // tabIndex of textarea
                                                // onBlur={newContent =>  setFormData({ ...formData, body: newContent })} // preferred to use only this option to update the content for performance reasons
                                                // onChange={newContent => setFormData({ ...formData, body: newContent })}
                                            />
                                            <Form.Control.Feedback type="invalid">
                                                Please provide a valid email body.
                                            </Form.Control.Feedback>
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row >
                                <Col>
                                <Form.Group controlId="formFile" >
                                    <Form.Label className='fw-bold'>Attachment(s)</Form.Label>
                                    <Form.Control size="sm" type="file" name='attachments' ref={attachmentRef} multiple/>
                                    <Form.Label className='fw-bold'>{rowFormData.attachments}</Form.Label>
                                </Form.Group>
                                </Col>
                            </Row>
                                            <Form.Control
                                                type="hidden"
                                                value={rowFormData.id}
                                                name='id'
                                            />

                                <div className='d-flex justify-content-end'>
                                {/* setShowForm(false);setFormData({});setmodifymode(false);setValidated(false);setGuidelinesShown(false) */}
                                
                                    <Button type='submit' className={`mx-1`} variant="primary" size="sm">Update</Button>
                                
                                </div>
                            
                        </Form>
            </Col>
        </Row>
       <Row> 
        <div className='p-2 d-flex justify-content-end'>
            <Form.Group as={Row} className='justify-content-end me-1' style={{width:'800px'}}>
                    <Form.Label className='fw-bold' column sm={6} >{message} {isSmtpCreadentialsValid}</Form.Label>
                    
                    <Col sm={6}>
                        <Form.Select id="selectOptions" onChange={handlesmtpselect} isValid={isSmtpCreadentialsValid}>
                            <option key='default' value={-1}>
                                Select SMTP Credentials
                            </option>
                            {smtpCredentials.map((option, index) => (
                                <option key={index} value={option.id} >
                                    [ID-{props.uid}00{option.id}] {option.title}  
                                </option>
                            ))}
                        </Form.Select>
                        <Form.Control.Feedback column sm={4} type="invalid">
                            Please provide a valid email body.
                        </Form.Control.Feedback>
                    </Col>
                </Form.Group>
            <Button className='mx-1' title='Cancel' variant="secondary" onClick={handleCancel} size='sm'>
                Cancel
            </Button>
            <Button className={`mx-1`} title='Start a campaign with selected Template' variant="primary" onClick={startcampaign} size='sm'>
                Start Campaign
            </Button>
        </div>
        </Row>
    </div>
    </>
}

export default CreateCampaign;