import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import axios from 'axios';

const CountrySelector = ({ onCountryChange, onIpInfoFetched, country }) => {
  console.log("in selector", country)
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const res = await axios.get(`/countries`);
        const countryData = res.data.map(country => ({
          id:country.value,
          value: country.code,
          label: country.label
        }));
        setCountries(countryData);
      } catch (error) {
        console.error("Error fetching countries:", error);
      }
    };

    fetchCountries();
  }, []);


  useEffect(() => {
    const fetchData = async() =>{
    const publicIP = await axios.get('https://api.ipify.org?format=json');
    const response = await axios.get(`/ipInfo/${publicIP.data.ip}`)
    if(country != null){
      const c = String(country).trim();
      const defaultCountry = countries.find(country => country.value == c);   
      setSelectedCountry(defaultCountry);
      onCountryChange(defaultCountry);
    }else{
       const defaultCountry = countries.find(country => country.value === response.data.ipInfo.countryCode);   
       setSelectedCountry(defaultCountry);
       onCountryChange(defaultCountry);

    }
    onIpInfoFetched(response.data);
  }
  if(countries.length > 0){
    fetchData()
  }
  }, [countries]);

  const handleChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    onCountryChange(selectedOption);
  };

  return (
    <Select
      options={countries}
      value={selectedCountry}
      onChange={handleChange}
    />
  );
};

export default CountrySelector;
